import React, { useCallback, useEffect, useRef, useState } from "react";
import "./EasyOnboarding.css";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { useDispatch } from "react-redux";
import { signUpAsync } from "../../features/auth/authSlice";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import cyclecircleLogo from "../../img/parts/Cycle-Circle-Logo-White-1.png";
import cyclegif from "../../img/signup/cyclegif.gif";
import {
  Typography,
  TextField,
  Button,
  Grid,
  FormControl,
  FormControlLabel,
  RadioGroup,
  Radio,
  Checkbox,
  Select,
  MenuItem,
  InputLabel,
  styled,
  Box,
  FormLabel,
} from "@mui/material";

import { getStateId, getStateName } from "../../utils/utils";
import { randomFemaleLogo } from "../../data";
import { randomMaleLogo } from "../../data";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import inspectUploadedImage from "../../img/gg_search.svg";
import deleteUploadedImage from "../../img/mdi-light_delete.svg";
import upload_logo from "../../img/upload_layer.svg";
import { API_BASE_URL } from "../../config";
import { useLocation, useNavigate } from "react-router";
import ccBlue from "../../img/cc-blue.png";
import toast from "react-hot-toast";
import { activateCreditAsync } from "../../features/credit/creditSlice";
import {
  handleBeforeUnloads,
  handlePageUnload,
  handleTextFieldBlur,
  handleTextFieldFocus,
  initFieldTracking,
  logEvent,
  logKYCVerification,
  logKYCVerificationFailed,
  logOnboardingFailed,
  logOnboardingSuccess,
  logPageView,
} from "../../analytics";

function EasyOnboarding() {
  const location = useLocation();
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [originalSelectedImage, setOriginalSelectedImage] = useState(null);
  const [selectedImage, setSelectedImage] = useState(null);
  const [createdUser, setCreatedUser] = useState(null);
  const [errors, setErrors] = useState({});
  const [inspect, setInspect] = useState(false);
  const [panExists, setPanExists] = useState(false);
  const [phoneExists, setPhoneExists] = useState(false);
  const [emailExists, setEmailExists] = useState(false);
  const [verify, setVerify] = useState(false);
  const [phoneVerify, setPhoneVerify] = useState(false);
  const [gstExists, setGstExists] = useState(false);
  const [GSTVerify, setGSTVerify] = useState(false);
  const [PANVerify, setPANVerify] = useState(false);
  const [referralVerify, setReferralVerify] = useState("");
  const [aadharExists, setAadharExists] = useState(false);
  const [OTPVerified, setOTPVerified] = useState(false);
  const [otp, setOTP] = useState(["", "", "", "", "", ""]);
  const inputRefs = useRef(
    Array.from({ length: 6 }).map(() => React.createRef())
  );
  const [timer, setTimer] = useState(60);
  const [resendDisabled, setResendDisabled] = useState(false);
  const [otpVerify, setOtpVerify] = useState(false);
  const [referralCode, setReferralCode] = useState("");
  const [finalData, setFinalData] = useState({
    Email: null,
    PhoneNumber: null,
    ReceiveWhatsApp: false,
    FirstName: null,
    LastName: null,
    Gender: null,
    DOB: null,
    USER_IMAGE: null,
    pin_code: null,
    location: null,
    district: null,
    state_id: null,
    address_line_1: null,
    address_line_2: null,
    save_address: "Dealership",
    Password: "123",
    pan_card: null,
    Have_GSTIN: "yes",
    business_name: null,
    applicable: "1",
    aadhar: null,
    GST: null,
    business_document: "Tax Return",
    Document_IMAGE: null,
    GST_DATA: null,
    Aadhar_DATA: {
      ref_id: "71050",
      status: "VALID",
      message: "Aadhaar Card Exists",
      care_of: "S/O: Fakkirappa Dollin",
      address:
        "Shri Kanaka Nilaya,,Umashankar Nagar 1st Main 5th Cross,Ranebennur,Haveri-Karnataka,India",
      dob: "02-02-1995",
      email: "",
      gender: "M",
      name: "Mallesh Fakkirappa Dollin",
      split_address: {
        country: "India",
        dist: "Haveri",
        house: "Shri Kanaka Nilaya",
        landmark: "",
        pincode: "581115",
        po: "Ranebennur",
        state: "Karnataka",
        street: "Umashankar Nagar 1st Main 5th Cross",
        subdist: "Ranibennur",
        vtc: "Ranibennur",
      },
      year_of_birth: "1995",
      mobile_hash:
        "ed189eb73247cb90b769e7e8d7dfd2efa4cd6a5ec27602f5d2721c035266568c",
    },
    PAN_DATA: null,
  });

  // Google Analytics for Page View
  useEffect(() => {
    logPageView(location.pathname, null);
  }, [location.pathname]);

  // States to track focused field name (optional)
  const [focusedField, setFocusedField] = useState("FirstName");

  // Attach this to window before the user leaves
  const useTrackFormLeave = (focusedField) => {
    useEffect(() => {
      const onUnload = () => handlePageUnload(focusedField);

      window.addEventListener("beforeunload", onUnload);
      return () => window.removeEventListener("beforeunload", onUnload);
    }, [focusedField]);
  };

  useTrackFormLeave(focusedField);

  const handleFocused = (fieldName) => {
    setFocusedField(fieldName);
  };

  const handleBlur = (fieldName) => {
    return;
  };

  const inputRef = useRef(null);

  const handleClose = () => {
    setOpen(false);
  };
  const handleOpen = () => {
    setOpen(true);
  };

  const handleReferalCodeChange = (event) => {
    const input = event.target.value.toUpperCase().replace(/\s+/g, "");

    setReferralCode(input);
    setErrors((prevErrors) => ({
      ...prevErrors,
      REFERRAL: null,
    }));
  };

  const handleApplyReferralCode = () => {
    const validReferralCode = ["JOINCCNOW", "JOIN2025"];

    if (validReferralCode.includes(referralCode)) {
      // Interaction Event (Google Analytics)
      logKYCVerification("Referral Code",referralCode);

      setErrors((prevErrors) => ({
        ...prevErrors,
        REFERRAL: null,
      }));
      setReferralVerify(referralCode); // Set to the matching referral code
      updateFinalData({ applicable: referralCode }); // Update with the referral code
    } else {
      logKYCVerificationFailed("Referral Code",referralCode,finalData?.PhoneNumber);
      setErrors((prevErrors) => ({
        ...prevErrors,
        REFERRAL: "Invalid Referral Code.",
      }));
      setReferralVerify(false);
      updateFinalData({ applicable: "1" }); // Indicates no valid referral applied
    }
  };

  const handleRemoveImage = () => {
    updateFinalData({ USER_IMAGE: null });
    setSelectedImage(null);
    setOriginalSelectedImage(null);
    inputRef.current.value = null;
  };

  const handleCheckboxChange = (e) => {
    const { checked } = e.target;
    updateFinalData({ ReceiveWhatsApp: checked });
  };

  const handleImageUpload = (e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile) {
      const imageUrl = URL.createObjectURL(selectedFile);
      updateFinalData({ [e.target.name]: selectedFile });
      setSelectedImage(imageUrl);
      setOriginalSelectedImage(imageUrl);
    }
  };

  function validatePAN(pan) {
    const regex = /^[A-Z]{5}[0-9]{4}[A-Z]{1}$/;
    return regex.test(pan);
  }

  const handlePANBlur = async (e) => {
    handleBlur("pan_card");
    const pan = e.target.value;
    if (!validatePAN(pan)) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        PAN: "Please enter a valid 10-digit PAN number",
      }));
      return;
    }

    try {
      const response = await fetch(`${API_BASE_URL}singup_pan_verify/${pan}`);
      const data = await response.json();

      if (data.status === "error") {
        setPanExists(true);
        setErrors((prevErrors) => ({
          ...prevErrors,
          PAN: "PAN number already registered with Cyclecircle",
        }));
      } else {
        setPanExists(false);
        setErrors((prevErrors) => ({
          ...prevErrors,
          PAN: "",
        }));
      }
    } catch (error) {
      console.log("Error checking PAN number existence:", error);
    }
  };

  const importImageAsFile = async (importedImageUrl) => {
    try {
      const blob = await importedImageToBlob(importedImageUrl);
      const file = new File([blob], "image.png", { type: "image/png" });
      updateFinalData({ USER_IMAGE: file });
      updateFinalData({ Document_IMAGE: file });
      const imageUrl = URL.createObjectURL(file);
      setSelectedImage(imageUrl);

      setOriginalSelectedImage(imageUrl);
    } catch (error) {
      console.error("Error importing image:", error);
    }
  };

  const handleImageClick = () => {
    inputRef.current.click();
  };

  const handleInspect = () => {
    setInspect(!inspect);
  };

  const importedImageToBlob = (importedImage) => {
    return new Promise((resolve, reject) => {
      const xhr = new XMLHttpRequest();
      xhr.open("GET", importedImage, true);
      xhr.responseType = "blob";
      xhr.onload = () => {
        if (xhr.status === 200) {
          resolve(xhr.response);
        } else {
          reject(new Error("Failed to convert image to Blob."));
        }
      };
      xhr.onerror = () => {
        reject(new Error("Failed to convert image to Blob."));
      };
      xhr.send();
    });
  };

  function validateAadhaar(aadhar) {
    const regex = /^\d{12}$/;
    return regex.test(aadhar);
  }

  const handleInputChange = async (e) => {
    const { name, value } = e.target;

    // Check if the input is for 'Gender' and value is either 'Male' or 'Female'
    if (name === "Gender" && (value === "Male" || value === "Female")) {
      // Randomly select an image from userMaleLogo or userFemaleLogo based on the selected gender
      const randomIndex = Math.floor(Math.random() * 15); // Generates a random index from 0 to 54
      const randomImage =
        value === "Male"
          ? randomMaleLogo[randomIndex]
          : randomFemaleLogo[randomIndex];

      if (finalData.USER_IMAGE === null) {
        importImageAsFile(randomImage);
      }
    }
    if (name === "PhoneNumber" && value?.length === 10) {
      handlePhoneNumberExist(value);
    } else if (name === "PhoneNumber" && value?.length < 10) {
      setPhoneVerify(false);
    }
    if (name === "pan_card" && value.length === 10) {
      handlePANBlur(e);
    } else if (name === "pan_card" && value.length < 10) {
      setPANVerify(false);
      setErrors((prevErrors) => ({
        ...prevErrors,
        PAN: "",
      }));
    }
    if (name === "pin_code" && value.length === 6) {
      // Fetch data based on pin code
      try {
        handleOpen();
        const response = await fetch(
          `https://api.postalpincode.in/pincode/${value}`
        );
        const data = await response.json();
        if (response.ok && data.length > 0) {
          // Update state, district, location based on API response
          const { State, District, Division } = data[0].PostOffice[0];
          updateFinalData({
            state_id: getStateId(State),
            district: District,
            location: Division,
          });
          handleClose();
        } else {
          // Show error message if pin code is invalid
          handleClose();
          toast.error("Invalid Pin Code");
        }
      } catch (error) {
        handleClose();
        console.error("Error fetching data:", error);
        // Show error message if API call fails
        toast.error("Please try again later");
      }
    }

    if (name === "aadhar" && value.length === 12) {
      handleAadharBlur(e);
    } else if (name === "aadhar" && value.length < 12) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        Aadhar: "",
      }));
    }
    if (name === "GST" && value.length < 15) {
      setGSTVerify(false);
    }

    updateFinalData({ [name]: value });
  };

  const updateFinalData = (dataToUpdate) => {
    setFinalData((prevData) => ({
      ...prevData,
      ...dataToUpdate,
    }));
  };

  const handleEnterKey = (e, nextInputId) => {
    if (e.key === "Enter") {
      e.preventDefault();
      const nextInput = document.getElementById(nextInputId);
      if (nextInput) {
        nextInput.focus();
      }
    }
  };
  const handleEmailBlur = async (e) => {
    handleBlur("Email");
    const email = e.target.value;
    // if (!isValidEmail(email)) {
    //   setErrors((prevErrors) => ({
    //     ...prevErrors,
    //     Email: "Please enter a valid email address",
    //   }));
    //   return;
    // }

    try {
      handleOpen();
      const response = await fetch(
        `${API_BASE_URL}singup_email_verify/${email}`
      );
      const data = await response.json();

      if (data.status === "error") {
        setEmailExists(true);
        setErrors((prevErrors) => ({
          ...prevErrors,
          Email: "Email already registered with Cyclecircle",
        }));
        handleClose();
      } else {
        setEmailExists(false);
        setErrors((prevErrors) => ({
          ...prevErrors,
          Email: "",
        }));
        handleClose();
      }
    } catch (error) {
      console.error("Error checking email existence:", error);
      handleClose();
    }
  };

  const activateLOC = async (User_ID) => {
    let creditData = {
      UserID: User_ID,
      creditLimitCategory_Name: "Diamond",
      maxLimit: 100000,
    };
    await dispatch(activateCreditAsync(creditData));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    // if (e.key === "Enter") {
    //   return;
    // }
    if (!phoneVerify) {
      toast.error("Verify Phone Number");
      return;
    }
    if (!PANVerify) {
      toast.error("Verify PAN Number");
      return;
    }
    if (referralVerify === "JOINCCNOW") {
      updateFinalData({ applicable: referralVerify });
    }
    try {
      handleOpen();
      const response = await dispatch(signUpAsync(finalData));
      if (response?.payload) {
        logOnboardingSuccess(finalData?.business_name);
        setCreatedUser(finalData);
        if (finalData?.applicable === "JOINCCNOW") {
          activateLOC(response?.payload?.data?.id);
        }
        handleClose();
        return;
      } else {
        handleClose();
        toast.error("Signup failed");
      }
    } catch (error) {
      logOnboardingFailed(finalData?.PhoneNumber);
      handleClose();
      console.error("Error signing up:", error);

      // Show an error message using SweetAlert if the sign-up fails
      toast.error("Signup failed");
    }
  };

  const handleVerifyPAN = async () => {
    handleOpen();
    if (!finalData.pan_card || finalData.pan_card.length < 10) {
      handleClose();
      toast.error("Enter PAN correctly");
    } else {
      try {
        const response = await fetch(
          `https://api.cyclecircle.one/api/v1/pan_verify/${finalData?.pan_card}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
            },
          }
        );

        const data = await response.json();
        if (data.PAN.message === "PAN verified successfully") {
          logKYCVerification("PAN",finalData?.pan_card);
          setVerify(true);
          setPANVerify(true);
          updateFinalData({ PAN_DATA: data.PAN });
          handleClose();
        } else {
          handleClose();
          logKYCVerificationFailed("PAN",finalData?.pan_card);
          toast.error("PAN Doesn't Exist");
        }
      } catch (error) {
        handleClose();
        toast.error("PAN Doesn't Exist");
        console.error(
          "An error occurred during the API request:",
          error.message
        );
      }
    }
  };

  const handleVerifyGST = async () => {
    handleOpen();
    if (!finalData.GST || finalData.GST.length < 15) {
      handleClose();
      toast.error("Enter GSTIN correctly");
    } else {
      try {
        const response = await fetch(
          `https://api.cyclecircle.one/api/v1/gst_verify/${finalData.GST}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
            },
          }
        );

        const data = await response.json();
        if (data.GST.message === "GSTIN Exists") {
          logKYCVerification("GST",finalData?.GST);
          setGSTVerify(true);
          setVerify(true);
          updateFinalData({ GST_DATA: data.GST });
          handleClose();
        } else {
          handleClose();
          logKYCVerificationFailed("GST",finalData?.GST);
          toast.error("GSTIN Doesn't Exist");
        }
      } catch (error) {
        handleClose();
        toast.error("GSTIN Doesn't Exist");
        console.error(
          "An error occurred during the API request:",
          error.message
        );
      }
    }
  };

  function validateGSTIN(gstin) {
    const regex =
      /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}[Z]{1}[0-9A-Z]{1}$/;
    return regex.test(gstin);
  }

  const handleGSTBlur = async (e) => {
    handleBlur("GST");
    const gst = e.target.value;
    if (!validateGSTIN(gst)) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        GST: "Please enter a valid 15-digit GST number",
      }));
      return;
    }

    try {
      const response = await fetch(`${API_BASE_URL}singup_gst_verify/${gst}`);
      const data = await response.json();

      if (data.status === "error") {
        setGstExists(true);
        setErrors((prevErrors) => ({
          ...prevErrors,
          GST: "GST number already registered with Cyclecircle",
        }));
      } else {
        setGstExists(false);
        setErrors((prevErrors) => ({
          ...prevErrors,
          GST: "",
        }));
      }
    } catch (error) {
      console.error("Error checking GST number existence:", error);
    }
  };

  const handleAadharInput = (e) => {
    let input = e.target.value.toUpperCase();
    if (input.length > 12) {
      input = input.slice(0, 12);
    }
    e.target.value = input;
  };

  const handleGSTInput = (e) => {
    let input = e.target.value.toUpperCase();
    if (input.length > 15) {
      input = input.slice(0, 15);
    }
    e.target.value = input;
  };

  const handlePANInput = (e) => {
    let input = e.target.value.toUpperCase();
    if (input.length > 10) {
      input = input.slice(0, 10);
    }
    e.target.value = input;
  };

  const handleAadharBlur = async (e) => {
    handleBlur("aadhar");
    const aadhar = e.target.value;
    if (!validateAadhaar(aadhar)) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        Aadhar: "Please enter a valid Aadhar number",
      }));
      return;
    }

    try {
      const response = await fetch(
        `${API_BASE_URL}singup_aadhar_verify/${aadhar}`
      );
      const data = await response.json();

      if (data.status === "error") {
        setAadharExists(true);
        setErrors((prevErrors) => ({
          ...prevErrors,
          Aadhar: "Aadhar already registered with Cyclecircle",
        }));
      } else {
        setAadharExists(false);
        setErrors((prevErrors) => ({
          ...prevErrors,
          Aadhar: "",
        }));
      }
    } catch (error) {
      console.error("Error checking Aadhar existence:", error);
    }
  };
  const startTimer = () => {
    setResendDisabled(true);
    setTimer(60);
  };

  useEffect(() => {
    let intervalId;
    if (timer > 0) {
      intervalId = setInterval(() => {
        setTimer((prevTimer) => prevTimer - 1);
      }, 1000);
    } else {
      setResendDisabled(false);
      clearInterval(intervalId);
    }
    // Clean up the interval on unmount
    return () => clearInterval(intervalId);
  }, [timer]);

  const handleSendOTP = async () => {
    if (finalData?.PhoneNumber?.length === 10) {
      try {
        handleOpen();
        const response = await fetch(
          `${API_BASE_URL}phone_auth/${finalData?.PhoneNumber}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
            },
          }
        );

        const data = await response.json();
        if (response.ok) {
          setOtpVerify(true);
          handleClose();
        } else {
          console.error("API request failed:", response.statusText);
          toast.error("OTP not Sent");
          handleClose();
        }
      } catch (error) {
        console.error(
          "An error occurred during the API request:",
          error.message
        );
        toast.error("Please try again later");
      }
    }
  };
  const handlePhoneVerificationAgain = async () => {
    if (finalData?.PhoneNumber?.length === 10) {
      try {
        if (timer > 0) {
          // Timer is still running, do nothing
          return;
        }
        startTimer();
        handleOpen();
        const response = await fetch(
          `${API_BASE_URL}phone_auth/${finalData?.PhoneNumber}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
            },
          }
        );

        const data = await response.json();
        if (response.ok) {
          handleClose();
        } else {
          console.error("API request failed:", response.statusText);
          toast.success("OTP Sent again");
          handleClose();
        }
      } catch (error) {
        console.error(
          "An error occurred during the API request:",
          error.message
        );
        toast.error("Please try again later");
      }
    }
  };
  const handlePhoneNumberExist = async (phone) => {
    try {
      handleOpen();
      const response = await fetch(
        `${API_BASE_URL}singup_phone_verify/${phone}`
      );
      const data = await response.json();

      if (data.status === "error") {
        setPhoneExists(true);
        handleClose();
      } else {
        setPhoneExists(false);
        handleClose();
      }
    } catch (error) {
      console.error("Error checking phone number existence:", error);
      handleClose();
    }
  };

  const handleOTPInputChange = useCallback(
    (index, value, e) => {
      // Accept only numeric values
      const numericValue = value.replace(/\D/g, "");

      const newOTP = [...otp];
      newOTP[index] = numericValue.slice(-1); // Only take the last digit

      setOTP(newOTP);

      // Move to the previous input when deleting a digit
      if (numericValue !== "" && index < 5 && inputRefs?.current[index + 1]) {
        inputRefs?.current[index + 1]?.current?.focus();
      }
    },
    [otp]
  );
  // HANDLE OTP PASTE
  const handleOTPPaste = (e) => {
    e.preventDefault();
    const clipboardData = e.clipboardData.getData("text/plain");
    const pastedDigits = clipboardData.replace(/\D/g, "").slice(0, 6); // Filter out non-numeric characters and take the first 6 digits
    const newOTP = [...otp];
    pastedDigits.split("").forEach((digit, index) => {
      newOTP[index] = digit;
    });
    setOTP(newOTP);
    inputRefs.current[5].current.focus();
  };

  const handleKeyDown = (index, value, e) => {
    if (e.keyCode === 8 && value === "" && index > 0) {
      inputRefs.current[index - 1].current.focus();
    } else if (e.keyCode === 8 && index > 0 && value !== "") {
      inputRefs.current[index].current.focus();
    }
  };
  const handleFocus = (index) => {
    // Move the cursor to the last digit if there's already a value entered
    if (index === 5 && otp[index] !== "") {
      inputRefs.current[index].current?.focus();
    }
  };

  const handleVerify = async () => {
    const enteredOTP = otp.join("");

    if (!enteredOTP || enteredOTP.length < 6) {
      toast.error("Enter OTP correctly");
    } else {
      try {
        const response = await fetch(
          `${API_BASE_URL}phone_auth_otp/${finalData?.PhoneNumber}/${enteredOTP}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
            },
          }
        );

        const data = await response.json();
        if (response.ok) {
          logKYCVerification("Phone Number",finalData?.PhoneNumber);
          setOtpVerify(false);
          setPhoneVerify(true);
          setOTP(["", "", "", "", "", ""]);
        } else {
          console.error("API request failed:", response.statusText);
          toast.error("Invalid OTP");
        }
      } catch (error) {
        console.error(
          "An error occurred during the API request:",
          error.message
        );
      }
    }
    handleOpen();
    setOTPVerified(true);
    handleClose();
  };

  return (
    <MainContainer className="admin-dashboard-right-pre-order-1">
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
        onClick={handleClose}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <img
        loading="lazy"
        src={cyclecircleLogo}
        alt="Cyclecircle"
        style={{
          position: "fixed",
          top: "20px",
          left: "20px",
          height: "60px",
          cursor: "pointer",
        }}
        onClick={() => navigate("/")}
      />
      <FormUpperContainer>
        <FormContainer>
          <FormHeading variant="h4" component="h1" gutterBottom>
            Onboarding Form
            <img
              loading="lazy"
              src={ccBlue}
              alt="cc-logo"
              className="h-[35px]"
            />
          </FormHeading>
          {!createdUser ? (
            <Form onSubmit={handleSubmit}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <TextField
                    label="First Name"
                    type="text"
                    variant="outlined"
                    fullWidth
                    name="FirstName"
                    value={finalData?.FirstName || ""}
                    onKeyDown={(e) => handleEnterKey(e, "secondNameInput")}
                    required
                    onChange={handleInputChange}
                    onFocus={() => handleFocused("FirstName")}
                    onBlur={() => handleBlur("FirstName")}
                    InputProps={{
                      style: {
                        height: "40px",
                        display: "flex",
                        alignItems: "center",
                        fontSize: "14px",
                        letterSpacing: "1px",
                        fontFamily: "inherit",
                        fontWeight: "600",
                      },
                    }}
                    InputLabelProps={{
                      style: {
                        fontSize: "14px",
                        lineHeight: "12px",
                        fontFamily: "inherit",
                      },
                      // shrink: true
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    label="Last Name"
                    variant="outlined"
                    fullWidth
                    type="text"
                    name="LastName"
                    value={finalData?.LastName || ""}
                    id="secondNameInput"
                    onKeyDown={(e) => handleEnterKey(e, "emailInput")}
                    onChange={handleInputChange}
                    onFocus={() => handleFocused("LastName")}
                    onBlur={() => handleBlur("LastName")}
                    InputProps={{
                      style: {
                        height: "40px",
                        display: "flex",
                        alignItems: "center",
                        fontSize: "14px",
                        letterSpacing: "1px",
                        fontFamily: "inherit",
                        fontWeight: "600",
                      },
                    }}
                    InputLabelProps={{
                      style: {
                        fontSize: "14px",
                        lineHeight: "12px",
                        fontFamily: "inherit",
                      },
                      // shrink: true
                    }}
                    required
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    label="Email"
                    variant="outlined"
                    id="emailInput"
                    fullWidth
                    name="Email"
                    type="email"
                    value={finalData?.Email || ""}
                    onKeyDown={(e) => handleEnterKey(e, "phoneInput")}
                    onBlur={handleEmailBlur}
                    onFocus={() => handleFocused("Email")}
                    required
                    onChange={handleInputChange}
                    InputProps={{
                      style: {
                        height: "40px",
                        display: "flex",
                        alignItems: "center",
                        fontSize: "14px",
                        letterSpacing: "1px",
                        fontFamily: "inherit",
                        fontWeight: "600",
                      },
                    }}
                    InputLabelProps={{
                      style: {
                        fontSize: "14px",
                        lineHeight: "12px",
                        fontFamily: "inherit",
                      },
                      // shrink: true
                    }}
                  />
                </Grid>
                {errors["Email"] && finalData?.Email && (
                  <span className="email-verification-container mobile-view-portal">
                    {errors["Email"]}
                  </span>
                )}
                <Grid item xs={12}>
                  <TextField
                    label="Phone Number"
                    variant="outlined"
                    fullWidth
                    name="PhoneNumber"
                    value={finalData?.PhoneNumber || ""}
                    onKeyDown={(e) => handleEnterKey(e, "dobInput")}
                    onFocus={() => handleFocused("PhoneNumber")}
                    onBlur={() => handleBlur("PhoneNumber")}
                    id="phoneInput"
                    required
                    inputProps={{
                      maxLength: 10, // Limit input to 10 digits
                    }}
                    onChange={(e) => {
                      // Allow only numeric values and ensure length limit
                      const { value } = e.target;
                      if (/^\d*$/.test(value) && value.length <= 10) {
                        handleInputChange(e);
                      }
                    }}
                    pattern="\d{10}" // Ensure pattern validation for 10 digits
                    onKeyPress={(e) => {
                      // Allow only numbers (0-9) and backspace (8)
                      if (!/[0-9\b]/.test(e.key)) {
                        e.preventDefault();
                      }
                    }}
                    InputProps={{
                      style: {
                        height: "40px",
                        display: "flex",
                        alignItems: "center",
                        fontSize: "14px",
                        letterSpacing: "1px",
                        fontFamily: "inherit",
                        fontWeight: "600",
                      },
                    }}
                    InputLabelProps={{
                      style: {
                        fontSize: "14px",
                        lineHeight: "12px",
                        fontFamily: "inherit",
                      },
                    }}
                  />
                </Grid>
                {phoneExists && (
                  <span className="email-verification-container mobile-view-portal">
                    Phone number already registered with Cyclecircle
                  </span>
                )}
                {!phoneVerify &&
                  !phoneExists &&
                  finalData?.PhoneNumber &&
                  finalData.PhoneNumber?.length === 10 && (
                    <div className="email-verification-container mobile-view-portal">
                      <span
                        className="email-verification-verify-btn mobile-verify-btn"
                        onClick={handleSendOTP}
                      >
                        Verify
                      </span>
                      <div className="email-verification-text-div">
                        <span className="verify-symbol mobile-verify-symbo">
                          !
                        </span>
                        <span className="email-verification-text mobile-verification-t">
                          Please verify to proceed
                        </span>
                      </div>
                    </div>
                  )}
                {phoneVerify && (
                  <div className="phone-number-verified-check mobile-view-portal">
                    <CheckIcon />
                    <span className="phone-number-verified-span mobile-tt">
                      Verified
                    </span>
                  </div>
                )}
                {otpVerify && <div className="signup-overlay"></div>}
                {otpVerify && (
                  <div className="email-verification-overlay-container mobile-otp-f">
                    <div className="email-verification-upper-heading-container mobile-verif">
                      <CheckIcon />
                      <span className="email-verification-upper-heading-span moible-mo-v">
                        Verification OTP sent
                      </span>
                    </div>
                    <span className="email-verification-details-text mobile-tt">
                      Enter the 6-digit OTP sent to your Email{" "}
                      <b>{finalData?.PhoneNumber}</b>
                    </span>
                    <div className="email-verification-otp-enter-input-container">
                      <span className="email-otp-input-legend mobile-tt">
                        Enter OTP
                      </span>
                      <div className="email-verification-otp-input-boxes mobile-inp">
                        {otp.map((digit, index) => (
                          <input
                            type="text"
                            maxLength="1"
                            oninput="this.value=this.value.replace(/[^0-9]/g,'');"
                            key={index}
                            // maxLength={index === 5 ? 1 : 0}
                            value={digit}
                            onChange={(e) =>
                              handleOTPInputChange(index, e.target.value, e)
                            }
                            onKeyDown={(e) =>
                              handleKeyDown(index, e.target.value, e)
                            }
                            ref={inputRefs.current[index]}
                            onFocus={() => handleFocus(index)}
                            className={digit ? "filled" : ""}
                            onPaste={handleOTPPaste}
                            required
                          />
                        ))}
                      </div>
                      <span
                        className="email-verification-resend-otp mobile-tt"
                        style={{ width: "auto" }}
                        onClick={handlePhoneVerificationAgain}
                      >
                        Resend OTP {resendDisabled ? `(${timer}s)` : ""}
                      </span>
                    </div>
                    <span
                      className="email-verification-cancel-btn"
                      onClick={() => {
                        setOtpVerify(false);
                        setTimer(0);
                        setOTP(["", "", "", "", "", ""]);
                      }}
                    >
                      <CloseIcon />
                    </span>
                    <span
                      className="email-verification-confirm-btn mobile-ttt"
                      onClick={handleVerify}
                    >
                      Confirm
                    </span>
                  </div>
                )}
                <Grid item xs={12}>
                  <FormControl component="fieldset" fullWidth>
                    <FormLabel
                      component="legend"
                      sx={{ fontSize: "14px", fontFamily: "inherit" }}
                    >
                      Gender
                    </FormLabel>
                    <RadioGroup
                      row
                      name="Gender"
                      value={finalData?.Gender}
                      onChange={handleInputChange}
                      required
                      sx={{
                        fontSize: "14px",
                      }}
                    >
                      <FormControlLabel
                        control={<Radio />}
                        label="Male"
                        name="Gender"
                        value="Male"
                        id="genderInput"
                        required
                        checked={finalData?.Gender === "Male"}
                        sx={{
                          "& .MuiFormControlLabel-label": {
                            fontSize: "14px",
                            fontFamily: "inherit",
                          },
                        }}
                      />
                      <FormControlLabel
                        control={<Radio />}
                        label="Female"
                        value="Female"
                        required
                        checked={finalData?.Gender === "Female"}
                        sx={{
                          "& .MuiFormControlLabel-label": {
                            fontSize: "14px",
                            fontFamily: "inherit",
                          },
                        }}
                      />
                    </RadioGroup>
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        name="receiveWhatsApp"
                        checked={finalData?.ReceiveWhatsApp}
                        onChange={handleCheckboxChange}
                        id="receiveWhatsApp"
                      />
                    }
                    sx={{
                      "& .MuiFormControlLabel-label": {
                        fontSize: "14px",
                        fontFamily: "inherit",
                      },
                    }}
                    label="Receive WhatsApp"
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    label="Date of Birth"
                    variant="outlined"
                    fullWidth
                    type="date"
                    id="dobInput"
                    value={finalData?.DOB || ""}
                    name="DOB"
                    // max={new Date().toISOString().split("T")[0]}
                    onKeyDown={(e) => handleEnterKey(e, "businessInput")}
                    onChange={handleInputChange}
                    onFocus={() => handleFocused("DOB")}
                    onBlur={() => handleBlur("DOB")}
                    InputProps={{
                      inputProps: {
                        max: new Date().toISOString().split("T")[0],
                      },
                      style: {
                        height: "40px",
                        display: "flex",
                        alignItems: "center",
                        fontSize: "14px",
                        letterSpacing: "1px",
                        fontFamily: "inherit",
                        fontWeight: "600",
                      },
                    }}
                    InputLabelProps={{
                      style: {
                        fontSize: "14px",
                        lineHeight: "25px",
                        fontFamily: "inherit",
                      },
                      shrink: true,
                    }}
                    required
                  />
                </Grid>

                <Grid item xs={12}>
                  <TextField
                    label="Business Name"
                    variant="outlined"
                    id="businessInput"
                    fullWidth
                    name="business_name"
                    value={finalData?.business_name || ""}
                    onChange={handleInputChange}
                    onKeyDown={(e) => handleEnterKey(e, "pincode")}
                    onFocus={() => handleFocused("business_name")}
                    onBlur={() => handleBlur("business_name")}
                    InputProps={{
                      style: {
                        height: "40px",
                        display: "flex",
                        alignItems: "center",
                        fontSize: "14px",
                        letterSpacing: "1px",
                        fontFamily: "inherit",
                        fontWeight: "600",
                      },
                    }}
                    InputLabelProps={{
                      style: {
                        fontSize: "14px",
                        lineHeight: "12px",
                        fontFamily: "inherit",
                      },
                      // shrink: true
                    }}
                    required
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    label="Pin Code"
                    variant="outlined"
                    fullWidth
                    type="number"
                    id="pincode"
                    onChange={handleInputChange}
                    value={finalData?.pin_code || ""}
                    onKeyDown={(e) => handleEnterKey(e, "address1Input")}
                    onFocus={() => handleFocused("pin_code")}
                    onBlur={() => handleBlur("pin_code")}
                    name="pin_code"
                    placeholder="Enter your pincode"
                    pattern="\d{6}"
                    maxLength="6"
                    onKeyPress={(e) => {
                      // Allow only numbers (0-9) and backspace (8)
                      if (!/[0-9\b]/.test(e.key)) {
                        e.preventDefault();
                      }
                    }}
                    InputProps={{
                      style: {
                        height: "40px",
                        display: "flex",
                        alignItems: "center",
                        fontSize: "14px",
                        letterSpacing: "1px",
                        fontFamily: "inherit",
                        fontWeight: "600",
                      },
                    }}
                    InputLabelProps={{
                      style: {
                        fontSize: "14px",
                        lineHeight: "12px",
                        fontFamily: "inherit",
                      },
                      // shrink: true
                    }}
                    required
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    label="Address Line 1"
                    variant="outlined"
                    fullWidth
                    name="address_line_1"
                    value={finalData?.address_line_1 || ""}
                    placeholder="Address line 1"
                    id="address1Input"
                    onKeyDown={(e) => handleEnterKey(e, "address2Input")}
                    onChange={handleInputChange}
                    onFocus={() => handleFocused("address_line_1")}
                    onBlur={() => handleBlur("address_line_1")}
                    InputProps={{
                      style: {
                        height: "40px",
                        display: "flex",
                        alignItems: "center",
                        fontSize: "14px",
                        letterSpacing: "1px",
                        fontFamily: "inherit",
                        fontWeight: "600",
                      },
                    }}
                    InputLabelProps={{
                      style: {
                        fontSize: "14px",
                        lineHeight: "12px",
                        fontFamily: "inherit",
                      },
                      // shrink: true
                    }}
                    required
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    label="Address Line 2"
                    variant="outlined"
                    fullWidth
                    name="address_line_2"
                    value={finalData?.address_line_2 || ""}
                    placeholder="Address line 2"
                    id="address2Input"
                    onKeyDown={(e) => handleEnterKey(e, "cityInput")}
                    onChange={handleInputChange}
                    onFocus={() => handleFocused("address_line_2")}
                    onBlur={() => handleBlur("address_line_2")}
                    InputProps={{
                      style: {
                        height: "40px",
                        display: "flex",
                        alignItems: "center",
                        fontSize: "14px",
                        letterSpacing: "1px",
                        fontFamily: "inherit",
                        fontWeight: "600",
                      },
                    }}
                    InputLabelProps={{
                      style: {
                        fontSize: "14px",
                        lineHeight: "12px",
                        fontFamily: "inherit",
                      },
                      // shrink: true
                    }}
                  />
                </Grid>

                <Grid item xs={12}>
                  <TextField
                    label="City"
                    variant="outlined"
                    fullWidth
                    name="location"
                    value={finalData?.location || ""}
                    placeholder="Enter your city name"
                    id="cityInput"
                    onKeyDown={(e) => handleEnterKey(e, "districtInput")}
                    onChange={handleInputChange}
                    onFocus={() => handleFocused("location")}
                    onBlur={() => handleBlur("location")}
                    InputProps={{
                      style: {
                        height: "40px",
                        display: "flex",
                        alignItems: "center",
                        fontSize: "14px",
                        letterSpacing: "1px",
                        fontFamily: "inherit",
                        fontWeight: "600",
                      },
                    }}
                    InputLabelProps={{
                      style: {
                        fontSize: "14px",
                        lineHeight: "12px",
                        fontFamily: "inherit",
                      },
                      // shrink: true
                    }}
                    required
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    label="District"
                    variant="outlined"
                    fullWidth
                    name="district"
                    value={finalData.district || ""}
                    placeholder="Enter your district name"
                    id="districtInput"
                    onKeyDown={(e) => handleEnterKey(e, "state_id")}
                    onChange={handleInputChange}
                    onFocus={() => handleFocused("district")}
                    onBlur={() => handleBlur("district")}
                    InputProps={{
                      style: {
                        height: "40px",
                        display: "flex",
                        alignItems: "center",
                        fontSize: "14px",
                        letterSpacing: "1px",
                        fontFamily: "inherit",
                        fontWeight: "600",
                      },
                    }}
                    InputLabelProps={{
                      style: {
                        fontSize: "14px",
                        lineHeight: "12px",
                        fontFamily: "inherit",
                      },
                      // shrink: true
                    }}
                    required
                  />
                </Grid>

                <Grid item xs={12}>
                  <FormControl variant="outlined" fullWidth>
                    <InputLabel
                      sx={{
                        fontSize: "14px",
                        lineHeight: "12px",
                        fontFamily: "inherit",
                      }}
                    >
                      State*
                    </InputLabel>
                    <Select
                      label="State"
                      id="state_id"
                      name="state_id"
                      onChange={handleInputChange}
                      value={finalData?.state_id || ""}
                      onKeyDown={(e) => handleEnterKey(e, "GST")}
                      onFocus={() => handleFocused("state_id")}
                      onBlur={() => handleBlur("state_id")}
                      sx={{
                        height: "40px",
                        display: "flex",
                        alignItems: "center",
                        fontSize: "14px",
                        letterSpacing: "1px",
                        fontFamily: "inherit",
                        fontWeight: "600",
                      }}
                      required
                    >
                      <MenuItem value="1">Andhra Pradesh</MenuItem>
                      <MenuItem value="2">Arunachal Pradesh</MenuItem>
                      <MenuItem value="3">Assam</MenuItem>
                      <MenuItem value="4">Bihar</MenuItem>
                      <MenuItem value="5">Chhattisgarh</MenuItem>
                      <MenuItem value="29">Chandigarh</MenuItem>
                      <MenuItem value="30">Delhi</MenuItem>
                      <MenuItem value="6">Goa</MenuItem>
                      <MenuItem value="7">Gujarat</MenuItem>
                      <MenuItem value="8">Haryana</MenuItem>
                      <MenuItem value="9">Himachal Pradesh</MenuItem>
                      <MenuItem value="32">Jammu and Kashmir</MenuItem>
                      <MenuItem value="10">Jharkhand</MenuItem>
                      <MenuItem value="11">Karnataka</MenuItem>
                      <MenuItem value="12">Kerala</MenuItem>
                      <MenuItem value="31">Ladakh</MenuItem>
                      <MenuItem value="13">Madhya Pradesh</MenuItem>
                      <MenuItem value="14">Maharashtra</MenuItem>
                      <MenuItem value="15">Manipur</MenuItem>
                      <MenuItem value="16">Meghalaya</MenuItem>
                      <MenuItem value="17">Mizoram</MenuItem>
                      <MenuItem value="18">Nagaland</MenuItem>
                      <MenuItem value="19">Odisha</MenuItem>
                      <MenuItem value="20">Punjab</MenuItem>
                      <MenuItem value="21">Rajasthan</MenuItem>
                      <MenuItem value="22">Sikkim</MenuItem>
                      <MenuItem value="23">Tamil Nadu</MenuItem>
                      <MenuItem value="24">Telangana</MenuItem>
                      <MenuItem value="25">Tripura</MenuItem>
                      <MenuItem value="26">Uttar Pradesh</MenuItem>
                      <MenuItem value="27">Uttarakhand</MenuItem>
                      <MenuItem value="28">West Bengal</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <FormControl component="fieldset" fullWidth>
                    <FormLabel
                      component="legend"
                      sx={{ fontSize: "14px", fontFamily: "inherit" }}
                    >
                      Have GST*
                    </FormLabel>

                    <RadioGroup
                      row
                      name="Have_GSTIN"
                      id="Have_GSTIN"
                      value={finalData?.Have_GSTIN}
                      onChange={handleInputChange}
                      style={{ fontSize: "14px" }}
                    >
                      <FormControlLabel
                        value="yes"
                        control={<Radio />}
                        checked={finalData?.Have_GSTIN === "yes"}
                        sx={{
                          "& .MuiFormControlLabel-label": {
                            fontSize: "14px",
                            fontFamily: "inherit",
                          },
                        }}
                        label="Yes"
                      />
                      <FormControlLabel
                        value="no"
                        control={<Radio />}
                        checked={finalData?.Have_GSTIN === "no"}
                        sx={{
                          "& .MuiFormControlLabel-label": {
                            fontSize: "14px",
                            fontFamily: "inherit",
                          },
                        }}
                        label="No"
                      />
                    </RadioGroup>
                  </FormControl>
                </Grid>
                <Typography style={{ fontWeight: "bold", paddingLeft: "16px" }}>
                  Enter Referral Code
                </Typography>
                <Grid
                  container
                  spacing={2}
                  alignItems="center"
                  paddingLeft="16px"
                  paddingTop="8px"
                >
                  <Grid item xs={9}>
                    <TextField
                      label="Referral Code"
                      variant="outlined"
                      fullWidth
                      name="referralCode"
                      value={referralCode || ""}
                      placeholder="Enter Referral Code"
                      id="referralCodeInput"
                      onChange={handleReferalCodeChange}
                      onFocus={() => handleFocused("referralCode")}
                      onBlur={() => handleBlur("referralCode")}
                      InputProps={{
                        maxLength: 9,
                        style: {
                          height: "40px",
                          display: "flex",
                          alignItems: "center",
                          fontSize: "14px",
                          letterSpacing: "1px",
                          fontFamily: "inherit",
                          fontWeight: "600",
                        },
                      }}
                      InputLabelProps={{
                        style: {
                          fontSize: "14px",
                          lineHeight: "12px",
                          fontFamily: "inherit",
                        },
                        // shrink: true
                      }}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <Button
                      variant="contained"
                      fullWidth
                      color="primary"
                      onClick={handleApplyReferralCode}
                      sx={{
                        height: "40px",
                        fontSize: "14px",
                        letterSpacing: "1px",
                        fontFamily: "inherit",
                        textTransform: "none",
                        fontWeight: "600",
                        backgroundColor: "var(--color-blue)",
                        "&:hover": {
                          backgroundColor: "var(--color-blue-hover)",
                          fontSize: "16px",
                        },
                      }}
                    >
                      Apply
                    </Button>
                  </Grid>
                  {errors["REFERRAL"] && referralCode && (
                    <span
                      className="email-verification-container mobile-view-portal"
                      style={{
                        fontSize: "12px",
                        color: "var(--color-peach)",
                        marginLeft: "20px",
                        paddingTop: "10px",
                      }}
                    >
                      {errors["REFERRAL"]}
                    </span>
                  )}
                  {referralVerify && (
                    <div className="phone-number-verified-check mobile-view-portal !justify-start">
                      <span className="phone-number-verified-span mobile-tt">
                        Referral code applied successfully
                      </span>
                    </div>
                  )}
                </Grid>

                <Grid item xs={12}>
                  <TextField
                    label="GST"
                    variant="outlined"
                    fullWidth
                    type="text"
                    name="GST"
                    id="GST"
                    onInput={handleGSTInput}
                    onBlur={handleGSTBlur}
                    onFocus={() => handleFocused("GST")}
                    value={finalData.GST || ""}
                    placeholder="Ex:55XE15VD9823EF5"
                    onChange={handleInputChange}
                    onKeyDown={(e) => handleEnterKey(e, "adharInput")}
                    required
                    InputProps={{
                      style: {
                        height: "40px",
                        display: "flex",
                        alignItems: "center",
                        fontSize: "14px",
                        letterSpacing: "1px",
                        fontFamily: "inherit",
                        fontWeight: "600",
                      },
                    }}
                    InputLabelProps={{
                      style: {
                        fontSize: "14px",
                        lineHeight: "12px",
                        fontFamily: "inherit",
                      },
                      // shrink: true
                    }}
                    maxLength="15"
                  />
                </Grid>
                {errors["GST"] && finalData?.GST && (
                  <span
                    className="email-verification-container mobile-view-portal"
                    style={{
                      fontSize: "12px",
                      color: "var(--color-peach)",
                      marginLeft: "20px",
                      paddingTop: "10px",
                    }}
                  >
                    {errors["GST"]}
                  </span>
                )}
                {!GSTVerify &&
                  !errors["GST"] &&
                  finalData?.GST &&
                  finalData?.GST?.length === 15 && (
                    <div
                      className="email-verification-container mobile-view-portal"
                      style={{
                        width: "100%",
                        paddingTop: "10px",
                        marginLeft: "20px",
                      }}
                    >
                      <span
                        className="email-verification-verify-btn mobile-verify-btn"
                        onClick={handleVerifyGST}
                      >
                        Verify
                      </span>
                      <div className="email-verification-text-div">
                        <span className="verify-symbol mobile-verify-symbo">
                          !
                        </span>
                        <span className="email-verification-text mobile-verification-t">
                          Please verify to proceed
                        </span>
                      </div>
                    </div>
                  )}
                {GSTVerify && (
                  <div className="phone-number-verified-check mobile-view-portal">
                    <CheckIcon />
                    <span className="phone-number-verified-span mobile-tt">
                      Verified
                    </span>
                  </div>
                )}
                <Grid item xs={12}>
                  <TextField
                    label="Aadhar"
                    variant="outlined"
                    fullWidth
                    onKeyPress={(e) => {
                      // Allow only numbers (0-9) and backspace (8)
                      if (!/[0-9\b]/.test(e.key)) {
                        e.preventDefault();
                      }
                    }}
                    onInput={handleAadharInput}
                    name="aadhar"
                    value={finalData?.aadhar || ""}
                    placeholder="Ex:234538653926"
                    onChange={handleInputChange}
                    onBlur={handleAadharBlur}
                    onFocus={() => handleFocused("aadhar")}
                    id="adharInput"
                    onKeyDown={(e) => handleEnterKey(e, "PANInput")}
                    InputProps={{
                      style: {
                        height: "40px",
                        display: "flex",
                        alignItems: "center",
                        fontSize: "14px",
                        letterSpacing: "1px",
                        fontFamily: "inherit",
                        fontWeight: "600",
                      },
                    }}
                    InputLabelProps={{
                      style: {
                        fontSize: "14px",
                        lineHeight: "12px",
                        fontFamily: "inherit",
                      },
                      // shrink: true
                    }}
                    required
                    pattern="\d{12}"
                  />
                </Grid>
                {errors["Aadhar"] && finalData?.aadhar && (
                  <span
                    className="email-verification-container mobile-view-portal"
                    style={{
                      fontSize: "12px",
                      color: "var(--color-peach)",
                      marginLeft: "20px",
                      paddingTop: "10px",
                    }}
                  >
                    {errors["Aadhar"]}
                  </span>
                )}
                <Grid item xs={12}>
                  <TextField
                    label="Pan Card"
                    variant="outlined"
                    fullWidth
                    name="pan_card"
                    value={finalData?.pan_card || ""}
                    placeholder="Ex:APMPH5429D"
                    onInput={handlePANInput}
                    id="PANInput"
                    onChange={handleInputChange}
                    onKeyDown={(e) => handleEnterKey(e, "registerButton")}
                    onBlur={handlePANBlur}
                    onFocus={() => handleFocused("pan_card")}
                    InputProps={{
                      style: {
                        height: "40px",
                        display: "flex",
                        alignItems: "center",
                        fontSize: "14px",
                        letterSpacing: "1px",
                        fontFamily: "inherit",
                        fontWeight: "600",
                      },
                    }}
                    InputLabelProps={{
                      style: {
                        fontSize: "14px",
                        lineHeight: "12px",
                        fontFamily: "inherit",
                      },
                      // shrink: true
                    }}
                    required
                    maxLength="10"
                  />
                </Grid>
                {errors["PAN"] && finalData?.pan_card && (
                  <span
                    className="email-verification-container mobile-view-portal"
                    style={{
                      fontSize: "12px",
                      color: "var(--color-peach)",
                      marginLeft: "20px",
                      paddingTop: "10px",
                    }}
                  >
                    {errors["PAN"]}
                  </span>
                )}
                {!PANVerify &&
                  !errors["PAN"] &&
                  finalData?.pan_card &&
                  finalData?.pan_card?.length === 10 && (
                    <div className="email-verification-container mobile-view-portal">
                      <span
                        className="email-verification-verify-btn mobile-verify-btn"
                        onClick={handleVerifyPAN}
                      >
                        Verify
                      </span>
                      <div className="email-verification-text-div">
                        <span className="verify-symbol mobile-verify-symbo">
                          !
                        </span>
                        <span className="email-verification-text mobile-verification-t">
                          Please verify to proceed
                        </span>
                      </div>
                    </div>
                  )}
                {PANVerify && (
                  <div className="phone-number-verified-check mobile-view-portal">
                    <CheckIcon />
                    <span className="phone-number-verified-span mobile-tt">
                      Verified
                    </span>
                  </div>
                )}

                <div className="basic-detail-photo-upload-container">
                  {finalData &&
                  finalData?.USER_IMAGE &&
                  finalData.USER_IMAGE.type.startsWith("image/") ? (
                    <>
                      <LazyLoadImage
                        src={
                          finalData &&
                          finalData?.USER_IMAGE &&
                          finalData.USER_IMAGE.type.startsWith("image/") &&
                          URL.createObjectURL(finalData?.USER_IMAGE)
                        }
                        alt="selected"
                        className="uploaded-image-fix"
                        effect="blur"
                        wrapperProps={{
                          // If you need to, you can tweak the effect transition using the wrapper style.
                          style: { transitionDelay: "1s" },
                        }}
                      />
                      <div className="uploaded-image-inspect-remove-container">
                        <div className="uploaded-image-inspect-div-container">
                          <LazyLoadImage
                            src={inspectUploadedImage}
                            alt=""
                            onClick={handleInspect}
                            effect="blur"
                            wrapperProps={{
                              // If you need to, you can tweak the effect transition using the wrapper style.
                              style: { transitionDelay: "1s" },
                            }}
                          />
                          <span onClick={handleInspect}>Inspect</span>
                        </div>
                        <div className="uploaded-image-remove-div-container">
                          <LazyLoadImage
                            src={deleteUploadedImage}
                            alt=""
                            onClick={handleRemoveImage}
                            effect="blur"
                            wrapperProps={{
                              // If you need to, you can tweak the effect transition using the wrapper style.
                              style: { transitionDelay: "1s" },
                            }}
                          />
                          <span
                            onClick={() => {
                              handleRemoveImage();
                              setInspect(false);
                            }}
                          >
                            Remove
                          </span>
                        </div>
                      </div>
                    </>
                  ) : (
                    <div
                      // className="basic-detail-photo-upload-bordered-container"
                      style={{ cursor: "pointer" }}
                      onClick={handleImageClick}
                    >
                      <LazyLoadImage
                        src={upload_logo}
                        alt="upload_logo"
                        effect="blur"
                        wrapperProps={{
                          // If you need to, you can tweak the effect transition using the wrapper style.
                          style: { transitionDelay: "1s" },
                        }}
                      />
                      <label>
                        <p>Upload profile photo</p>
                      </label>
                      <span>Drag & Drop</span>
                      <span className="upload-browse">Browse</span>
                    </div>
                  )}
                  <Grid item xs={12}>
                    <input
                      accept="image/*"
                      type="file"
                      id="userImage"
                      name="USER_IMAGE"
                      ref={inputRef}
                      style={{ display: "none" }}
                      onChange={(e) => {
                        handleImageUpload(e);
                      }}
                    />
                  </Grid>
                  {/* <input
                  type="file"
                  id="userImage"
                  name="USER_IMAGE"
                  ref={inputRef}
                  style={{ display: "none" }}
                  onChange={(e) => {
                    handleImageUpload(e);
                  }}
                /> */}
                </div>
                <Grid
                  item
                  xs={12}
                  className="button-grid-v3"
                  // sx={{
                  //   position: "sticky",
                  //   zIndex: "999",
                  //   bottom: "0",
                  //   left: "20px",
                  // }}
                >
                  <Button
                    type="submit"
                    variant="contained"
                    fullWidth
                    id="registerButton"
                    sx={{
                      height: "40px",
                      fontSize: "14px",
                      letterSpacing: "1px",
                      fontFamily: "inherit",
                      fontWeight: "600",
                      backgroundColor: "var(--color-green)",
                      "&:hover": {
                        backgroundColor: "var(--color-green-hover)",
                        fontSize: "16px",
                      },
                    }}
                  >
                    Register
                  </Button>
                </Grid>
              </Grid>
            </Form>
          ) : (
            <div className="submitted-container">
              <img
                loading="lazy"
                src={cyclegif}
                alt="cycle-gif"
                className="submitted-gif"
              />
              <span className="submitted-text">Registration Successful!</span>
            </div>
          )}
        </FormContainer>
        <RightDetail>
          <div className="typewrite-container">
            <p className="Typewriter">Welcome to the Cyclecircle!</p>
            <p className="Typewriter-1">
              India's Premier B2B website only for Bicycle Dealers.
            </p>
            <div className="typewriter-li-container">
              <p className="typewriter-each-li-1">No MOQ</p>
              <p className="typewriter-each-li-2">No SHIPPING COST</p>
              <p className="typewriter-each-li-3">FAST DELIVERIES</p>
              <p className="typewriter-each-li-4">SPECIAL BULK DEALS</p>
            </div>
          </div>
          {createdUser && <div className="mobil-overlay"></div>}
          {createdUser ? (
            <div className="created-user-detail-container">
              <img
                loading="lazy"
                src={URL.createObjectURL(createdUser?.USER_IMAGE)}
                alt="profile_img"
                className="created-user-profile-img"
              />
              <div className="created-user-main-details">
                <div className="created-user-left-detail">
                  <span className="created-user-each-left-detail">
                    Full Name:{" "}
                    <b>
                      {createdUser?.FirstName} {createdUser?.LastName}
                    </b>
                  </span>
                  <span className="created-user-each-left-detail">
                    Email: <b>{createdUser?.Email}</b>{" "}
                  </span>
                  <span className="created-user-each-left-detail">
                    Phone No: <b>{createdUser?.PhoneNumber}</b>{" "}
                  </span>
                  <span className="created-user-each-left-detail">
                    DOB: <b>{createdUser?.DOB}</b>
                  </span>
                  <span className="created-user-each-left-detail">
                    Gender: <b>{createdUser?.Gender}</b>
                  </span>
                  <span className="created-user-each-left-detail">
                    Receive Whatsapp:{" "}
                    <b>
                      {createdUser?.ReceiveWhatsApp === true ? "Yes" : "No"}
                    </b>
                  </span>
                </div>
                <div className="created-user-right-detail">
                  <span className="created-user-each-right-detail">
                    Business Name: <b>{createdUser?.business_name}</b>
                  </span>
                  <span className="created-user-each-right-detail">
                    Adhar No: <b>{createdUser?.aadhar}</b>
                  </span>
                  <span className="created-user-each-right-detail">
                    PAN No: <b>{createdUser?.pan_card}</b>
                  </span>
                  <span className="created-user-each-right-detail">
                    GST No: <b>{createdUser?.GST}</b>
                  </span>
                  <span
                    className="created-user-each-right-detail"
                    style={{ whiteSpace: "none" }}
                  >
                    Address:{" "}
                    <b>
                      {createdUser?.address_line_1 &&
                        createdUser?.address_line_1}{" "}
                      {createdUser?.address_line_2 &&
                        createdUser?.address_line_2}{" "}
                      {createdUser?.location && createdUser?.location}{" "}
                      {getStateName(
                        createdUser?.state_id && createdUser?.state_id
                      )}{" "}
                      {createdUser?.pin_code && createdUser?.pin_code}
                    </b>
                  </span>
                </div>
              </div>
            </div>
          ) : (
            <>
              {PANVerify && (
                <div
                  className="show-aadhar-details-after-verify mobile-ver"
                  style={{
                    boxShadow: "0 2px 4px 0 rgb(0 0 0/ 20%)",
                    background: "#fff",
                  }}
                >
                  {/* <DoneIcon /> */}
                  <h3 className="show-aadhar-details-after-verify-heading">
                    PAN is Valid
                  </h3>
                  <div className="show-aadhar-details-after-verify-main-container">
                    <div className="show-aadhar-details-after-verify-each-attribute">
                      <span className="show-aadhar-details-after-verify-each-attribute-key">
                        PAN No.
                      </span>
                      <span className="show-aadhar-details-after-verify-each-attribute-value">
                        {finalData?.PAN_DATA?.pan}
                      </span>
                    </div>
                    <div className="show-aadhar-details-after-verify-each-attribute">
                      <span className="show-aadhar-details-after-verify-each-attribute-key">
                        Name
                      </span>
                      <span className="show-aadhar-details-after-verify-each-attribute-value">
                        {finalData?.PAN_DATA?.registered_name}
                      </span>
                    </div>
                    <div className="show-aadhar-details-after-verify-each-attribute">
                      <span className="show-aadhar-details-after-verify-each-attribute-key">
                        Reference ID
                      </span>
                      <span className="show-aadhar-details-after-verify-each-attribute-value">
                        {finalData?.PAN_DATA?.reference_id}
                      </span>
                    </div>
                    <div className="show-aadhar-details-after-verify-each-attribute">
                      <span className="show-aadhar-details-after-verify-each-attribute-key">
                        Valid
                      </span>
                      <span className="show-aadhar-details-after-verify-each-attribute-value">
                        {"True"}
                      </span>
                    </div>
                    <div className="show-aadhar-details-after-verify-each-attribute">
                      <span className="show-aadhar-details-after-verify-each-attribute-key">
                        Type
                      </span>
                      <span className="show-aadhar-details-after-verify-each-attribute-value">
                        {finalData?.PAN_DATA?.type}
                      </span>
                    </div>
                  </div>
                  {/* <span
              className="show-aadhar-details-after-verify-close-btn"
              onClick={handleVerifyPANClose}
            >
              Close
            </span> */}
                </div>
              )}
              {GSTVerify && (
                <div
                  className="show-GST-details-after-verify mobile-ver"
                  style={{
                    position: "static",
                    margin: "20px 0 0px 0",
                    boxShadow: "0 2px 4px 0 rgb(0 0 0/ 20%)",
                    background: "#fff",
                  }}
                >
                  {/* <DoneIcon /> */}
                  <h3 className="show-GST-details-after-verify-heading">
                    GSTIN is Valid
                  </h3>
                  <div className="show-GST-details-after-verify-main-container">
                    <div className="show-GST-details-after-verify-each-attribute">
                      <span className="show-GST-details-after-verify-each-attribute-key">
                        GSTIN
                      </span>
                      <span className="show-GST-details-after-verify-each-attribute-value">
                        {finalData?.GST_DATA?.GSTIN}
                      </span>
                    </div>
                    <div className="show-GST-details-after-verify-each-attribute">
                      <span className="show-GST-details-after-verify-each-attribute-key">
                        Legal Name of Business
                      </span>
                      <span className="show-GST-details-after-verify-each-attribute-value">
                        {finalData?.GST_DATA?.legal_name_of_business}
                      </span>
                    </div>
                    <div className="show-GST-details-after-verify-each-attribute">
                      <span className="show-GST-details-after-verify-each-attribute-key">
                        GST.Ref.ID
                      </span>
                      <span className="show-GST-details-after-verify-each-attribute-value">
                        {finalData?.GST_DATA?.reference_id}
                      </span>
                    </div>
                    <div className="show-GST-details-after-verify-each-attribute">
                      <span className="show-GST-details-after-verify-each-attribute-key">
                        Trade Name of Business
                      </span>
                      <span className="show-GST-details-after-verify-each-attribute-value">
                        {finalData?.GST_DATA?.trade_name_of_business}
                      </span>
                    </div>
                    <div className="show-GST-details-after-verify-each-attribute">
                      <span className="show-GST-details-after-verify-each-attribute-key">
                        Tax payment type
                      </span>
                      <span className="show-GST-details-after-verify-each-attribute-value">
                        {finalData?.GST_DATA?.taxpayer_type}
                      </span>
                    </div>
                    <div className="show-GST-details-after-verify-each-attribute">
                      <span className="show-GST-details-after-verify-each-attribute-key">
                        Date of Registration
                      </span>
                      <span className="show-GST-details-after-verify-each-attribute-value">
                        {finalData?.GST_DATA?.date_of_registration}
                      </span>
                    </div>
                    <div className="show-GST-details-after-verify-each-attribute">
                      <span className="show-GST-details-after-verify-each-attribute-key">
                        GST Status
                      </span>
                      <span className="show-GST-details-after-verify-each-attribute-value">
                        {finalData?.GST_DATA?.gst_in_status}
                      </span>
                    </div>
                    <div className="show-GST-details-after-verify-each-attribute">
                      <span className="show-GST-details-after-verify-each-attribute-key">
                        Place Address
                      </span>
                      <span className="show-GST-details-after-verify-each-attribute-value">
                        {finalData?.GST_DATA?.principal_place_address}
                      </span>
                    </div>
                  </div>
                  {/* <span
              className="show-GST-details-after-verify-close-btn"
              onClick={handleVerifyGSTClose}
            >
              Close
            </span> */}
                </div>
              )}
            </>
          )}
        </RightDetail>
      </FormUpperContainer>
    </MainContainer>
  );
}

export default EasyOnboarding;

const MainContainer = styled(Box)`
  width: 100vw;
  height: 100vh;
  background: url(https://images.unsplash.com/photo-1534787238916-9ba6764efd4f?fm=jpg&q=60&w=3000&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8NHx8Y3ljbGluZ3xlbnwwfHwwfHx8MA%3D%3D)
    no-repeat center center fixed;
  background-size: cover;
  @media (max-width: 500px) {
  }
`;
const FormUpperContainer = styled(Box)`
  width: 100%;
  height: 100vh;
  padding: 20px;
  padding-top: 100px;
  display: flex;
  justify-content: space-between;
  @media (max-width: 500px) {
    padding-top: 0;
    padding: 0;
  }
`;
const FormContainer = styled(Box)`
  width: 35%;
  overflow-y: scroll;
  background: #fff;
  //   padding: 15px;
  padding-top: 0;
  margin-left: 50px;
  border-radius: 5px;
  color: rgba(0, 0, 0, 0.8);
  box-shadow: 0 2px 4px 0 rgb(0 0 0/ 20%);
  padding-bottom: 20px;
  @media (max-width: 500px) {
    width: 100%;
    height: 100vh;
    margin-left: 0;
  }
`;

const FormHeading = styled(Typography)`
  font-size: 18px;
  color: rgba(0, 0, 0, 0.8);
  font-weight: 600;
  font-family: inherit;
  margin-bottom: 15px;
  padding: 10px 20px;
  //   border-bottom:1px solid rgba(0,0,0,0.1);
  box-shadow: 0 2px 1px 0 rgba(0, 0, 0, 0.05);
  position: sticky;
  top: 0;
  left: 0;
  z-index: 10;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  @media (max-width: 500px) {
    width: 100%;
    position: fixed;
  }
`;

const Form = styled("form")`
  padding: 20px;
  padding-top: 0;
  padding-bottom: 0;
  @media (max-width: 500px) {
    padding-bottom: 60px;
    padding-top: 75px;
  }
`;

const RightDetail = styled(Box)`
  width: 50%;
  // position:fixed;
  // top:50%;
  // transform:translateY(-50%);
  // right:0;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  padding-left: 20px;
  @media (max-width: 500px) {
    width: 0;
    padding-left: 0;
  }
`;
