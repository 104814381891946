import React from "react";
import "./MobilePrice.css";
import { connect, useSelector } from "react-redux";
import bracketLogo from "../../img/navigation/Bracket.svg";
import discountLogo from "../../img/navigation/Discount.svg";
import { selectUser } from "../../features/auth/authSlice";
import InfoIcon from "@mui/icons-material/Info";
import {
  selectIsOverlayVisible,
  toggleOverlay,
} from "../../store/slices/headerSlice";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
import {
  calculateGST,
  calculateUpdatedDiscountPercentage,
  formatWithCommasWithDecimal,
} from "../../utils/utils";
import { Tooltip } from "@mui/material";

function MobilePrice({ selectProduct, isOverlayVisible, toggleOverlay }) {
  const selectedVariant = useSelector(
    (state) => state.productDetail.selectedVariant
  );
  const userDetail = useSelector(selectUser);

  return (
    <div className="w-full flex flex-col px-3 py-0">
      <div className="mobile-productPrice-container">
        <div className="mobile-productPrice-left-container">
          <div className="mobile-productPrice-mrp-price-container">
            <span className="mobile-productPrice-mrp-price-heading">
              Dealer Price
            </span>
            <div className="mobile-productPrice-mrp-price-main-container">
              <div className="mobile-productPrice-ndp-offer-price-container">
                {Object.keys(userDetail).length > 0 ? (
                  <span
                    className="mobile-productPrice-mrp-price-span"
                    style={{
                      fontSize: selectedVariant?.Offer_price !== null && "3vw",
                      textDecoration:
                        selectedVariant?.Offer_price !== null && "line-through",
                      fontWeight:
                        selectedVariant?.Offer_price !== null && "500",
                    }}
                  >
                    ₹
                    {selectedVariant &&
                      selectedVariant?.NDP_Price &&
                      formatWithCommasWithDecimal(
                        parseInt(selectedVariant?.NDP_Price)
                      )}
                  </span>
                ) : (
                  <span
                    className="mobile-product-price-login-to-see-price"
                    onClick={toggleOverlay}
                  >
                    Login to see Price
                  </span>
                )}

                {Object.keys(userDetail).length > 0 &&
                  selectedVariant &&
                  selectedVariant?.Offer_price !== null && (
                    <span className="mobile-productPrice-offer-price-span">
                      ₹
                      {formatWithCommasWithDecimal(
                        parseInt(selectedVariant?.Offer_price)
                      )}
                    </span>
                  )}
              </div>
              <span className="mobile-productPrice-mrp-price-inclusive">
                (+ ₹
                {selectedVariant && selectProduct &&
                  calculateGST(
                    selectedVariant?.Offer_price !== null
                      ? parseInt(selectedVariant?.Offer_price)
                      : parseInt(selectedVariant?.NDP_Price),
                    parseInt(selectProduct && selectProduct[0]?.GST)
                  )}{" "}
                GST)
              </span>
            </div>
          </div>
          <div className="mobile-productPrice-ndp-price-container">
            <span className="mobile-productPrice-ndp-price-heading">MRP</span>
            <span className="mobile-productPrice-ndp-price-span">
              ₹
              {formatWithCommasWithDecimal(
                parseInt(selectedVariant && selectedVariant?.MRP_price)
              )}{" "}
            </span>
          </div>
        </div>
        <div className="mobile-productPrice-divider-container">
          <img
            loading="lazy"
            src={bracketLogo}
            alt=""
            className="mobile-productPrice-divider-img"
          />
        </div>
        {calculateUpdatedDiscountPercentage(
          selectedVariant && selectedVariant?.MRP_price,
          selectedVariant && selectedVariant?.NDP_Price,
          selectProduct && selectProduct[0]?.GST
        ) > 0 && (
          <div className="mobile-productPrice-right-container">
            <div className="mobile-productPrice-right-margin-container">
              {Object.keys(userDetail).length > 0 ? (
                <>
                  <img
                    loading="lazy"
                    src={discountLogo}
                    alt=""
                    className="mobile-productPrice-right-margin-logo"
                  />
                  <span
                    className="mobile-productPrice-right-margin-span"
                    style={{
                      fontSize:
                        selectedVariant?.Offer_price !== null ? "2.5vw" : "",
                      textDecoration:
                        selectedVariant?.Offer_price !== null
                          ? "line-through"
                          : "",
                      fontWeight:
                        selectedVariant?.Offer_price !== null ? "500" : "",
                    }}
                  >
                    {calculateUpdatedDiscountPercentage(
                      selectedVariant?.MRP_price,
                      parseInt(selectedVariant?.NDP_Price),
                      selectProduct && selectProduct[0]?.GST
                    )}
                  </span>
                  {selectedVariant.Offer_price !== null && (
                    <span className="mobile-productPrice-right-offer-span">
                      {calculateUpdatedDiscountPercentage(
                        selectedVariant?.MRP_price,
                        parseInt(selectedVariant?.Offer_price),
                        selectProduct && selectProduct[0]?.GST
                      )}
                    </span>
                  )}
                </>
              ) : (
                <div className="mobile-product-price-not-visible-container">
                  <VisibilityOffOutlinedIcon onClick={toggleOverlay} />
                </div>
              )}
              % Net Dealer Margin
            </div>
          </div>
        )}
      </div>
      {selectProduct && selectProduct[0]?.Manufacturer === "GARRARI BIKES" && (
        <span className="text-gray-600 text-[12px] mt-3 flex items-center">
          <Tooltip
            title="Shipping charges will be collected by the delivery agent upon delivery."
            arrow
            placement="top-start"
          >
            <InfoIcon
              sx={{
                fontSize: "16px",
                color: "gray",
                marginLeft: "4px",
                cursor: "pointer",
              }}
            />
          </Tooltip>
          &nbsp;Shipping charges applicable
        </span>
      )}
    </div>
  );
}

const mapStateToProps = (state) => ({
  isOverlayVisible: selectIsOverlayVisible(state),
});
const mapDispatchToProps = {
  toggleOverlay,
};
export default connect(mapStateToProps, mapDispatchToProps)(MobilePrice);
