import React, { useEffect, useState } from "react";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import PartsIcon from "../../img/navigation/parts.png";
import accessoriesIcon from "../../img/navigation/accessories.png";
import bikesIcon from "../../assets/Bike.png";
import clothingIcon from "../../img/navigation/clothing.png";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchAllNavigationsAsync,
  selectAllNavigations,
} from "../../features/HomePage/homeSlice";
import { setSelectedSubcategory } from "../../store/slices/subCategorySlice";
import { useNavigate } from "react-router";
import { IMAGE_BASE } from "../../config";
import { SwipeableDrawer } from "@mui/material";
import { logActionClickedFromComponent } from "../../analytics";
import { selectUser } from "../../features/auth/authSlice";

function MobileBigNavigation({ setShowNavigation, showNavigation }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const verticalDetail = useSelector(selectAllNavigations);
  const [nav, setNav] = useState(4);
  const userDetail = useSelector(selectUser);

  useEffect(() => {
    dispatch(fetchAllNavigationsAsync(4));
  }, [dispatch]);

  const handleNavigations = (vertical) => {
    if (nav === vertical) {
      setNav(null);
    } else {
      dispatch(fetchAllNavigationsAsync(vertical));
      setNav(vertical);
    }
  };
  const handleBrandNavigate = (value) => {
    logActionClickedFromComponent(
      `Brand:${value}`,
      "Big Navigation",
      userDetail?.UserID
    );
    const lowercaseValue = value.toLowerCase();
    navigate(`/listing-page/${lowercaseValue}`);
    setShowNavigation(false);
  };

  const handelCategoryNavigate = (catID, catName) => {
    logActionClickedFromComponent(
      `Category:${catName}`,
      "Big Navigation",
      userDetail?.UserID
    );
    navigate(`/listing-page/${catID}/${catName}`);
    dispatch(setSelectedSubcategory(null));
    setShowNavigation(false);
  };

  const toggleDrawer = (open) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setShowNavigation(open);
  };

  return (
    <SwipeableDrawer
      anchor="left"
      open={showNavigation}
      onClose={() => toggleDrawer(false)}
      onOpen={() => toggleDrawer(true)}
      PaperProps={{
        sx: {
          width: "100vw",
        },
      }}
    >
      <div className="w-full relative flex items-center justify-between py-4 px-4">
        <ChevronLeftIcon
          style={{ fontSize: "28px" }}
          className="text-black-1/80"
          onClick={() => setShowNavigation(false)}
        />
        <span className="absolute left-1/2 transform -translate-x-[50%] text-16 text-black-1/80 font-bold">
          All Categories
        </span>
      </div>
      <div className="w-full h-screen bg-white-1 flex">
        {/* Left Navigation */}
        <div className="w-[30%] bg-[#f3f3f3] flex flex-col items-center">
          <div
            className={`w-full flex flex-col items-center justify-start px-4 pb-1 py-4 border-b-[2px] border-gray-200 relative ${
              nav === 4 && "bg-white-1"
            }`}
            onClick={() => handleNavigations(4)}
          >
            <img
              loading="lazy"
              src={bikesIcon}
              alt="bikes-icon"
              className="h-[35px] rounded-full bg-white-1 p-[6px]"
            />
            <span className="text-14 text-black-1/80 my-1">Bikes</span>
          </div>
          <div
            className={`w-full flex flex-col items-center justify-start px-4 pb-1 py-4 border-b-[2px] border-gray-200 relative ${
              nav === 1 && "bg-white-1"
            }`}
            onClick={() => handleNavigations(1)}
          >
            <img
              loading="lazy"
              src={PartsIcon}
              alt="part-icon"
              className="h-[35px] rounded-full bg-white-1 p-[6px]"
            />
            <span className="text-14 text-black-1/80 my-1">Parts</span>
          </div>
          <div
            className={`w-full flex flex-col items-center justify-start px-4 py-4 pb-1 border-b-[2px] border-gray-200 relative ${
              nav === 2 && "bg-white-1"
            }`}
            onClick={() => handleNavigations(2)}
          >
            <img
              loading="lazy"
              src={accessoriesIcon}
              alt="accessories-icon"
              className="h-[35px] rounded-full bg-white-1 p-[6px]"
            />
            <span className="text-14 text-black-1/80 my-1">Accessories</span>
          </div>
          <div
            className={`w-full flex flex-col items-center justify-start px-4 py-4 pb-1 border-b-[2px] border-gray-200 relative ${
              nav === 3 && "bg-white-1"
            }`}
            onClick={() => handleNavigations(3)}
          >
            <img
              loading="lazy"
              src={clothingIcon}
              alt="cloth-icon"
              className="h-[35px] rounded-full bg-white-1 p-[6px]"
            />
            <span className="text-14 text-black-1/80 my-1">Maintenance</span>
          </div>
        </div>

        {/* Right Content */}
        <div className="w-[70%] flex flex-col bg-white px-2">
          <div className="w-full flex flex-wrap py-2 border-b-2 border-gray-200">
            {verticalDetail?.categories &&
              verticalDetail.categories.map((category, index) => (
                <div
                  className="w-[50%] flex flex-col items-center justify-start p-2"
                  key={index}
                  onClick={() =>
                    handelCategoryNavigate(
                      category?.CategoryID,
                      category?.categoryName
                    )
                  }
                >
                  <img
                    loading="lazy"
                    src={`https://cyclecircle.blr1.cdn.digitaloceanspaces.com/${IMAGE_BASE}_CATEGORY_IMAGE/${
                      category?.image && category?.image
                    }`}
                    alt="cat-logo"
                    className="w-[55px] h-[55px] rounded-full"
                  />
                  <span className="text-[14px] font-bold text-black-1/80 mt-1 text-center">
                    {category.categoryName && category?.categoryName}
                  </span>
                </div>
              ))}
          </div>

          <div className="w-full flex flex-col py-2">
            <span className="text-16 text-black-1/80 font-bold ml-2">
              Top Brands
            </span>
            <div className="w-full flex flex-wrap">
              {verticalDetail?.manufacturers &&
                verticalDetail.manufacturers.map((brand, index) => (
                  <div
                    className="w-[50%] flex items-center justify-center p-2"
                    key={index}
                  >
                    <img
                      loading="lazy"
                      src={`https://cyclecircle.blr1.cdn.digitaloceanspaces.com/${IMAGE_BASE}_MANUFACTURE_IMAGE/${
                        brand?.manufacture_images && brand?.manufacture_images
                      }`}
                      alt=""
                      className="w-[80px] h-[35px] object-contain"
                      onClick={() =>
                        handleBrandNavigate(
                          brand?.manufacture_name && brand?.manufacture_name
                        )
                      }
                    />
                  </div>
                ))}
            </div>
          </div>
        </div>
      </div>
    </SwipeableDrawer>
  );
}

export default MobileBigNavigation;
