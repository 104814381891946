// config.js
export const API_BASE_URL = "https://test.cyclecircle.one/api/v1/";
export const API_BASE_PAYMENT_URL = "https://beta.cyclecircle.one/api/v1/cashfree_payment/";
export const IMAGE_BASE = "DEVELOPMENT";
export const TRACKING_ORDER = "https://uatreham.holisollogistics.com/track_order?awb=";
export const ADMIN_USER = [1, 2, 10];
export const CASHFREE_MODE = "sandbox";
export const GOOGLE_ANALYTICS_MEASUREMENT_ID = "G-4ZPXWBGE1Z";
export const REACT_APP_GTM_ID = "GTM-K754H7QR"

// export const API_BASE_URL = 'https://api.cyclecircle.one/api/v1/';
// export const IMAGE_BASE = 'PRODUCTION';
// export const API_BASE_PAYMENT_URL = 'https://cyclecircle.one/api/v1/cashfree_payment/';
// export const TRACKING_ORDER = 'https://wms.holisolscm.com/track_order?awb='
// export const ADMIN_USER = [8,10,19];
// export const CASHFREE_MODE = "production";
// export const GOOGLE_ANALYTICS_MEASUREMENT_ID = "G-BYZ5C9C3XM";
 //export const REACT_APP_GTM_ID = "GTM-K754H7QR"
