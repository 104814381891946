import React, { useEffect, useState } from "react";
import "./MobileFavShare.css";
import { selectUser } from "../../features/auth/authSlice";
import { connect, useDispatch, useSelector } from "react-redux";
import {
  addToWishlistsAsync,
  fetchAllWishlistsAsync,
  removeFromWishlistAsync,
  selectAllWishlists,
} from "../../features/wishlist/wishlistSlice";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import favPurpleOutline from "../../img/parts/bookmark-purple-outline.png";
import favPurpleFilled from "../../img/parts/bookmark-purple-filled.png";
import ShortcutOutlinedIcon from "@mui/icons-material/ShortcutOutlined";
import AuthenticationPage from "../../components/Authentication/AuthenticationPage";
import MobileSharePopUp from "./MobileSharePopUp";
import {
  toggleOverlay,
  selectIsOverlayVisible,
} from "../../store/slices/headerSlice";
import { logActionClickedFromComponent } from "../../analytics";

function MobileFavShare({ selectedProduct, isOverlayVisible, toggleOverlay }) {
  const [showSharePopup, setShowSharePopup] = useState(false);
  const userDetail = useSelector(selectUser); // Get user detail
  const wishlist = useSelector(selectAllWishlists); // Get all wishlist products
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const handleClose = () => {
    setOpen(false);
  };
  const handleOpen = () => {
    setOpen(true);
  };

  useEffect(() => {
    dispatch(fetchAllWishlistsAsync(userDetail.UserID));
  }, [dispatch, userDetail.UserID, selectedProduct]);

  const toggleSharePopup = () => {
    setShowSharePopup(!showSharePopup);
  };

  const closeSharePopup = () => {
    setShowSharePopup(false);
  };

  const handleLoginOverlay = () => {
    toggleOverlay();
  };

  // TO ADD PRODUCT INTO WISHLIST
  const handleAddToWishlist = (selectedProduct, event) => {
    handleOpen();
    let wishlistData = {
      UserID: userDetail?.UserID,
      prodID: selectedProduct.prod_ID,
    };
    logActionClickedFromComponent(
      "Add to Wishlist",
      "Product Page",
      userDetail?.UserID
    );
    dispatch(addToWishlistsAsync(wishlistData)).then(() =>
      dispatch(fetchAllWishlistsAsync(userDetail.UserID)).then(() => {
        handleClose();
      })
    ); // Fetch updated wishlist
    event.preventDefault();
  };

  const handleRemoveFromWishlist = async (data, e) => {
    handleOpen();
    let wishlistData = {
      UserID: userDetail?.UserID,
      prodID: data?.prod_ID,
    };
    dispatch(removeFromWishlistAsync(wishlistData)).then(() => {
      dispatch(fetchAllWishlistsAsync(userDetail.UserID)).then(() => {
        handleClose();
      });
    });
    e.preventDefault();
  };

  // Function to check if a product is in the wishlist
  const isInWishlist = (productId) => {
    return (
      Array.isArray(wishlist && wishlist?.product) &&
      wishlist?.product?.some((item) => item.prod_ID === productId)
    );
  };
  return (
    <div className="mobile-fav-share-container">
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open} // Show backdrop when either open or filtering state is true
        onClick={handleClose}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <div className="mobile-favourite">
        {isInWishlist(selectedProduct?.prod_ID) ? (
          <>
            <img
              loading="lazy"
              src={favPurpleFilled}
              alt=""
              onClick={(event) => {
                handleRemoveFromWishlist(selectedProduct, event);
                logActionClickedFromComponent(
                  "Remove From Wishlist",
                  "Product Page",
                  userDetail?.UserID
                );
              }}
            />
          </>
        ) : (
          <>
            <img
              loading="lazy"
              src={favPurpleOutline}
              alt=""
              onClick={(event) => {
                userDetail && userDetail?.UserID
                  ? handleAddToWishlist(selectedProduct, event)
                  : handleLoginOverlay();
                logActionClickedFromComponent(
                  "Join Us",
                  "Product Card",
                  userDetail?.UserID
                );
              }}
            />
          </>
        )}
      </div>
      <span className="mobile-share" onClick={toggleSharePopup}>
        <ShortcutOutlinedIcon
          style={{
            fontSize: "8vw",
            marginRight: "0",
          }}
        />
      </span>
      {showSharePopup && <MobileSharePopUp onClose={closeSharePopup} />}
      {isOverlayVisible && (
        <div className="overlay-container">
          <AuthenticationPage />
        </div>
      )}
    </div>
  );
}

const mapStateToProps = (state) => ({
  isOverlayVisible: selectIsOverlayVisible(state),
});
const mapDispatchToProps = {
  toggleOverlay,
};
export default connect(mapStateToProps, mapDispatchToProps)(MobileFavShare);
