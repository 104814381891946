import { logActionClickedFromComponent } from "../analytics";
import { API_BASE_PAYMENT_URL, API_BASE_URL } from "../config";
import { fetchUserRoleById } from "../features/cartPage/cartPageAPI";
import {
  createDealerOrderAsync,
  createOrderAsync,
  freezeQuantityAsync,
  updatePaymentStatusAsync,
} from "../features/cartPage/OrderSlice";
import {
  applyCouponAsync,
  debitWalletAsync,
} from "../features/cartPage/walletSlice";
import { payOrderUsingCreditAsync } from "../features/credit/creditSlice";
import cashfree from "../services/Cashfree";
import {
  calculateAmountToPay,
  calculateDealerPrice,
  calculateOfferPrice,
  calculateShippingCost,
  getGarrariBikeQuantity,
} from "./utils";

// To Generate Payment URL
export const handleGeneratePaymentURL = async (
  userDetail,
  setPaymentSessionId,
  setTransactionId,
  amountToPay
) => {
  try {
    const response = await fetch(
      `${API_BASE_PAYMENT_URL}${userDetail?.UserID}/${
        amountToPay > 0 ? amountToPay : 1
      }`
    );

    const data = await response.json();
    setPaymentSessionId(data?.Payment_Session_ID);
    setTransactionId(data?.LinkID);
  } catch (error) {
    console.error("An error occurred during the API request:", error.message);
  }
};

export const handleOrderCreate = (
  cart,
  dispatch,
  transactionId,
  userDetail,
  selectedAddress,
  useWalletAmount,
  useCreditAmount,
  selectedCoupon,
  setOrderNo,
  isDoorToDoorChecked
) => {
  if (!cart || cart.length === 0) {
    console.error("Cart is empty");
    return;
  }
  // Calculate door-to-door shipping cost if applicable
  let doorToDoorShippingCost = 0;
  if (isDoorToDoorChecked) {
    const garrariBikeQuantity = getGarrariBikeQuantity(cart);
    doorToDoorShippingCost = garrariBikeQuantity * 550;
  }

  // Separate cart items into dealer and main products
  const dealerProducts = cart.filter((product) =>
    product.skuDetails?.SKU_ID.startsWith("D")
  );
  const mainProducts = cart.filter(
    (product) => !product.skuDetails?.SKU_ID.startsWith("D")
  );

  // Construct items arrays for dealer and main products
  const mapCartToItems = (products) =>
    products.map((product) => ({
      item_no: product?.skuDetails?.SKU_ID,
      quantity: product?.buy_quantity,
      mrp: product?.skuDetails?.MRP_price,
      sales_amount:
        calculateDealerPrice(
          parseInt(product?.skuDetails?.MRP_price),
          parseInt(product?.skuDetails?.NDP_Price),
          product?.skuDetails?.GST
        ) * product?.buy_quantity,
      discount_amount:
        calculateOfferPrice(
          parseInt(product?.skuDetails?.MRP_price),
          parseInt(product?.skuDetails?.Offer_price),
          product?.skuDetails?.GST
        ) * product?.buy_quantity || "",
    }));

  const dealerItems = mapCartToItems(dealerProducts);
  const mainItems = mapCartToItems(mainProducts);

  // Helper function to process shared logic
  const processSharedLogic = (response) => {
    // console.log(response);
    logActionClickedFromComponent("Placed Order", "Carts", userDetail?.UserID);
    const orderNumber = response?.payload?.OrderNumber;
    setOrderNo(orderNumber);

    if (parseInt(useCreditAmount) > 0) {
      const creditData = {
        UserID: userDetail?.UserID,
        order_no: orderNumber,
        amount: parseInt(useCreditAmount),
      };
      dispatch(payOrderUsingCreditAsync(creditData));
    }

    if (selectedCoupon?.couponCode?.length > 0) {
      applyCoupon(orderNumber, userDetail, dispatch, selectedCoupon);
    }

    if (useWalletAmount && useWalletAmount > 0) {
      debitFromWallet(orderNumber, dispatch, userDetail, useWalletAmount);
    }
  };

  // Create order for dealer products
  const createDealerOrder = () => {
    const dealerOrderData = {
      LinkID: transactionId,
      shippingID: selectedAddress,
      Discount_Amount: parseInt(
        parseInt(useWalletAmount) +
          parseInt(selectedCoupon?.couponDiscountTaken)
      ),
      Credit: useCreditAmount === 0 ? null : parseInt(useCreditAmount),
      Shipping_Amount:
        calculateShippingCost(mainProducts) + doorToDoorShippingCost,
      items: dealerItems,
    };

    return dispatch(createDealerOrderAsync(dealerOrderData));
  };

  // Create order for main products
  const createMainOrder = () => {
    const mainOrderData = {
      merchantTransactionId: transactionId,
      shippingID: selectedAddress,
      Discount_Amount: parseInt(
        parseInt(useWalletAmount) +
          parseInt(selectedCoupon?.couponDiscountTaken)
      ),
      Credit: useCreditAmount === 0 ? null : parseInt(useCreditAmount),
      Shipping_Amount:
        calculateShippingCost(mainProducts) + doorToDoorShippingCost,
      items: mainItems,
    };

    return dispatch(createOrderAsync(mainOrderData));
  };

  // Handle both orders
  const handleBothOrders = async () => {
    try {
      if (dealerItems.length > 0 && mainItems.length > 0) {
        // Call both APIs and process shared logic after main order
        await createDealerOrder();
        const mainResponse = await createMainOrder();
        processSharedLogic(mainResponse);
      } else if (dealerItems.length > 0) {
        // Only dealer products
        const dealerResponse = await createDealerOrder();
        // console.log(dealerResponse)
        processSharedLogic(dealerResponse);
      } else if (mainItems.length > 0) {
        // Only main products
        const mainResponse = await createMainOrder();
        processSharedLogic(mainResponse);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  handleBothOrders();
};

//   Debit from wallet
export const debitFromWallet = (
  orderNumber,
  dispatch,
  userDetail,
  useWalletAmount
) => {
  let walletData = {
    UserID: userDetail?.UserID,
    amount: useWalletAmount,
    orderNo: orderNumber,
  };
  dispatch(debitWalletAsync(walletData));
};

// Apply Coupon
export const applyCoupon = (orderID, userDetail, dispatch, selectedCoupon) => {
  let couponData = {
    userID: userDetail?.UserID,
    couponCode: selectedCoupon?.couponCode,
    discountAmount: parseInt(selectedCoupon?.couponDiscountTaken),
    orderNo: orderID,
  };
  // console.log(couponData)
  dispatch(applyCouponAsync(couponData));
};

// Freeze Product
export const handleFreezeQuantity = (
  cart,
  userDetail,
  dispatch,
  transactionId
) => {
  if (!cart || cart.length === 0) {
    console.error("Cart is empty");
    return;
  }
  // Construct the items array from the cart
  const items = cart
    // .filter((product) => !product.skuDetails?.SKU_ID.startsWith("D"))
    .map((product) => ({
      item_no: product?.skuDetails?.SKU_ID,
      quantity: product?.buy_quantity,
      mrp: product?.skuDetails?.MRP_price,
      sales_amount:
        calculateDealerPrice(
          parseInt(product?.skuDetails?.MRP_price),
          parseInt(product?.skuDetails?.NDP_Price),
          product?.skuDetails?.GST
        ) * product?.buy_quantity,
      discount_amount:
        calculateOfferPrice(
          parseInt(product?.skuDetails?.MRP_price),
          parseInt(product?.skuDetails?.Offer_price),
          product?.skuDetails?.GST
        ) * product?.buy_quantity || "",
    }));

  let quantityData = {
    LinkID: transactionId,
    UserID: userDetail?.UserID,
    items: items,
  };

  dispatch(freezeQuantityAsync(quantityData));
};

// Fetch User Role
export const fetchUserRole = async (userDetail) => {
  try {
    const response = await fetchUserRoleById(userDetail?.UserID);
    if (response && response?.data) {
      return response?.data?.UserType;
    }
    throw new Error("UserType not found in response");
  } catch (error) {
    console.error("Error fetching user role:", error);
    return null;
  }
};

// order without payment
export const handleOrder = async (
  cart,
  dispatch,
  transactionId,
  userDetail,
  selectedAddress,
  useWalletAmount,
  useCreditAmount,
  selectedCoupon,
  setOrderNo,
  setShowPaymentGateway,
  setShowOrderConfirmation,
  placed,
  isDoorToDoorChecked
) => {
  if (placed) {
    try {
      await handleOrderCreate(
        cart,
        dispatch,
        transactionId,
        userDetail,
        selectedAddress,
        useWalletAmount,
        useCreditAmount,
        selectedCoupon,
        setOrderNo,
        isDoorToDoorChecked
      );
      setShowPaymentGateway(false);
      setShowOrderConfirmation(true);
    } catch (error) {
      console.error("Error while creating order:", error);
    }
  }
};

// Cashfree Payment set
export const initiatePayment = async (
  userDetail,
  dispatch,
  transactionId,
  setOpenPlaced,
  setShowPaymentGateway,
  paymentSessionId,
  cart,
  selectedAddress,
  useWalletAmount,
  useCreditAmount,
  selectedCoupon,
  setOrderNo,
  setShowOrderConfirmation,
  setShowFailedPayment,
  setShowPendingPayment,
  setUseWalletAmount,
  setUseCreditAmount,
  isDoorToDoorChecked
) => {
  const userRole = await fetchUserRole(userDetail);
  await new Promise((resolve) => setTimeout(resolve, 4000));
  const amountTOPay = parseInt(
    calculateAmountToPay(
      cart,
      selectedCoupon?.couponDiscountTaken,
      useWalletAmount,
      useCreditAmount,
      setUseWalletAmount,
      setUseCreditAmount,
      isDoorToDoorChecked
    )
  );
  if (userRole === "ADMIN" || amountTOPay === 0) {
    dispatch(updatePaymentStatusAsync(transactionId)).then(async () => {
      setOpenPlaced(true);
    });
  } else {
    setShowPaymentGateway(false);

    let isMobile = window.innerWidth <= 768; // Adjust this threshold based on your needs

    let checkoutOptions = {
      paymentSessionId,
      redirectTarget: document.getElementById("cashfree-payment"),
      appearance: {
        width: isMobile ? "100vw" : "900px", // Full width on mobile, default on desktop
        height: isMobile ? "100vh" : "600px", // Full height on mobile, default on desktop
      },
    };

    cashfree.checkout(checkoutOptions).then(async (result) => {
      if (result.error) {
        console.error("Payment error, check payment status:", result.error);
      }
      if (result.redirect) {
        console.log("Payment will be redirected");
      }
      if (result.paymentDetails) {
        try {
          const response = await fetch(
            `${API_BASE_URL}cashfree_status/${transactionId}`
          );
          if (!response.ok) {
            throw new Error("Network response was not ok");
          }
          const data = await response.json();
          if (
            data &&
            data?.paymentStatuses &&
            data?.paymentStatuses[0] === "SUCCESS"
          ) {
            await handleOrderCreate(
              cart,
              dispatch,
              transactionId,
              userDetail,
              selectedAddress,
              useWalletAmount,
              useCreditAmount,
              selectedCoupon,
              setOrderNo,
              isDoorToDoorChecked
            );

            setShowPaymentGateway(false);
            setShowOrderConfirmation(true);
          } else if (data && data?.paymentStatuses[0] === "FAILED") {
            setShowPaymentGateway(false);
            setShowOrderConfirmation(false);
            setShowFailedPayment(true);
          } else if (data && data?.paymentStatuses[0] === "PENDING") {
            setShowPaymentGateway(false);
            setShowOrderConfirmation(false);
            setShowPendingPayment(true);
          } else if (data && data?.paymentStatuses[0] === "NOT_ATTEMPTED") {
            setShowPaymentGateway(false);
            setShowOrderConfirmation(false);
          } else if (data && data?.paymentStatuses[0] === "USER_DROPPED") {
            setShowPaymentGateway(false);
            setShowOrderConfirmation(false);
          }
        } catch (error) {
          console.error("Error checking payment status:", error);
        }
      }
    });
  }
};

// Full Payment for Credit Due

export const generateFullPaymentUrl = async (
  userDetail,
  orderNo,
  setPaymentSessionId,
  setTransactionId,
  setOpenPaymentModal
) => {
  try {
    const response = await fetch(
      `${API_BASE_URL}credit/payment_order/${userDetail?.UserID}/${orderNo}`
    );

    const data = await response.json();
    setOpenPaymentModal(true);
    setPaymentSessionId(data?.Payment_Session_ID);
    setTransactionId(data?.LinkID);
  } catch (error) {
    console.error("An error occurred during the API request:", error.message);
  }
};

// Initialize credit cashfree Payment
export const initiateCashfreePayment = (
  userDetail,
  transactionId,
  setOpenWaitingGateway,
  paymentSessionId,
  orderNumber,
  setOpenPaymentConfirmation
) => {
  setOpenWaitingGateway(false);

  let isMobile = window.innerWidth <= 768; // Adjust this threshold based on your needs

  let checkoutOptions = {
    paymentSessionId,
    redirectTarget: document.getElementById("cashfree-payment"),
    appearance: {
      width: isMobile ? "100vw" : "900px", // Full width on mobile, default on desktop
      height: isMobile ? "100vh" : "600px", // Full height on mobile, default on desktop
    },
  };

  cashfree.checkout(checkoutOptions).then(async (result) => {
    if (result.error) {
      console.error("Payment error, check payment status:", result.error);
    }
    if (result.redirect) {
      console.log("Payment will be redirected");
    }
    if (result.paymentDetails) {
      try {
        const response = await fetch(
          `${API_BASE_URL}/credit/order_payment_status/${transactionId}`
        );
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const data = await response.json();
        if (
          data &&
          data?.paymentStatuses &&
          data?.paymentStatuses[0] === "SUCCESS"
        ) {
          await fetch(
            `${API_BASE_URL}/credit/spend_amount_pay/${userDetail?.UserID}/${orderNumber}`
          );
          setOpenPaymentConfirmation(true);
        } else if (data && data?.paymentStatuses[0] === "FAILED") {
          setOpenPaymentConfirmation(true);
        } else if (data && data?.paymentStatuses[0] === "PENDING") {
          setOpenPaymentConfirmation(true);
        } else if (data && data?.paymentStatuses[0] === "NOT_ATTEMPTED") {
          setOpenPaymentConfirmation(true);
        } else if (data && data?.paymentStatuses[0] === "USER_DROPPED") {
          setOpenPaymentConfirmation(true);
        }
      } catch (error) {
        console.error("Error checking payment status:", error);
      }
    }
  });
};

// Generate Partial payment Link
export const generatePartialPaymentUrl = async (
  amount,
  userDetail,
  setPaymentSessionId,
  setTransactionId
) => {
  try {
    const response = await fetch(
      `${API_BASE_URL}credit/payment_partail/${userDetail?.UserID}/${amount}`
    );

    const data = await response.json();
    setPaymentSessionId(data?.Payment_Session_ID);
    setTransactionId(data?.LinkID);
  } catch (error) {
    console.error("An error occurred during the API request:", error.message);
  }
};

// Initialize credit cashfree Partial Payment
export const initiateCashfreePartialPayment = (
  userDetail,
  transactionId,
  setOpenWaitingGateway,
  paymentSessionId,
  amount,
  setOpenPaymentConfirmation
) => {
  setOpenWaitingGateway(false);
  let isMobile = window.innerWidth <= 768; // Adjust this threshold based on your needs

  let checkoutOptions = {
    paymentSessionId,
    redirectTarget: document.getElementById("cashfree-payment"),
    appearance: {
      width: isMobile ? "100vw" : "900px", // Full width on mobile, default on desktop
      height: isMobile ? "100vh" : "600px", // Full height on mobile, default on desktop
    },
  };

  cashfree.checkout(checkoutOptions).then(async (result) => {
    if (result.error) {
      console.error("Payment error, check payment status:", result.error);
    }
    if (result.redirect) {
      console.log("Payment will be redirected");
    }
    if (result.paymentDetails) {
      try {
        const response = await fetch(
          `${API_BASE_URL}/credit/partail_payment_status/${transactionId}`
        );
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const data = await response.json();
        if (
          data &&
          data?.paymentStatuses &&
          data?.paymentStatuses[0] === "SUCCESS"
        ) {
          await fetch(
            `${API_BASE_URL}/credit/partial_amount_pay/${userDetail?.UserID}/${amount}`
          );
          setOpenPaymentConfirmation(true);
        } else if (data && data?.paymentStatuses[0] === "FAILED") {
          setOpenPaymentConfirmation(true);
        } else if (data && data?.paymentStatuses[0] === "PENDING") {
          setOpenPaymentConfirmation(true);
        } else if (data && data?.paymentStatuses[0] === "NOT_ATTEMPTED") {
          setOpenPaymentConfirmation(true);
        } else if (data && data?.paymentStatuses[0] === "USER_DROPPED") {
          setOpenPaymentConfirmation(true);
        }
      } catch (error) {
        console.error("Error checking payment status:", error);
      }
    }
  });
};
