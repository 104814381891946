import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchThroughBrandAsync } from "../../features/listingPage/listingPageSlice";
import { useNavigate } from "react-router";
import { setSubCategories } from "../../features/listingPage/listingPageSlice";
import { setSelectedSubcategory } from "../../store/slices/subCategorySlice";
import { selectUser } from "../../features/auth/authSlice";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
import bikeProLogo from "../../icons/parts/bike-pro-temp-logo.png";
import sovereignLogo from "../../img/banners/sovereign-logo.png";
import masterLogo from "../../img/banners/proride-logo-1.png";
import prorideLogo from "../../img/banners/proride-logo-2.png";
import { setBreadcrumb } from "../../store/slices/breadCrumbsSlice";
import { Button } from "@mui/material";
import Collections from "../../dComponent/Collections/Collections";
import ExclusiveBrand from "../../dComponent/Collections/ExclusiveBrand";
import {
  logActionClickedFromComponent,
} from "../../analytics";

function OurBrands() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userDetail = useSelector(selectUser);

  const handleFetchThroughBrands = (brand) => {
    dispatch(fetchThroughBrandAsync(brand)).then(() => {
      dispatch(setSubCategories([]));
      dispatch(setSelectedSubcategory(null));
      dispatch(
        setBreadcrumb({
          categoryID: null,
          categoryName: brand,
          subCategoryID: null,
          subCategoryName: "",
          productName: "",
          ProductID: null,
        })
      );
      navigate(`/listing-page/${brand}`);
    });
  };

  return (
    <div className="w-full flex items-center flex-col bg-white-1 border-black-1/10 border-2 py-5">
      <ExclusiveBrand />
      <div className="w-full bg-[#f3f3f3]">
        <Collections />
      </div>
      <div className="w-full flex flex-col items-center justify-start py-4 px-8">
        <div className="w-auto h-auto my-2">
          <span className="text-2xl text-blue font-bold tracking-wide capitalize">
            Vocal4Local : Discover New Indian Brands
          </span>
        </div>
        <div className="w-full h-auto flex items-center justify-between pt-8 overflow-x-auto brand-scroll-v1">
          <div
            className="w-[240px] h-[380px] rounded-md relative mx-5 cursor-pointer flex-shrink-0"
            onClick={() => {
              handleFetchThroughBrands("proride");
              logActionClickedFromComponent(
                "Proride",
                "Vocal4Local",
                userDetail?.UserID
              );
            }}
          >
            <img
              loading="lazy"
              src="https://cdn.mos.cms.futurecdn.net/pUyW3aVBo9VDsVP5ycsDSj.jpg"
              alt="brand-img"
              className="w-full h-full object-cover bg-center bg-cover rounded-md"
            />

            <div className="w-[94%] h-[45%] rounded-md absolute bottom-[2.2%] left-[3%] bg-white-1 flex-center flex-col opacity-90 hover:opacity-100">
              <div className="w-full h-[30px] mb-2 flex-center">
                <img
                  loading="lazy"
                  src={prorideLogo}
                  alt="brand-logo"
                  className="w-auto h-[25px] contain"
                />
              </div>
              <span className="text-[12px] font-bold text-center text-black-1/90 h-[25px]">
                Grips,Fenders & Bottles
              </span>
              <div className="w-full h-[25px] flex-center">
                <span className="text-[12px] text-black-1/90 font-bold capitalize tracking-wide flex items-center">
                  {Object.keys(userDetail).length > 0 ? (
                    "50-85"
                  ) : (
                    <VisibilityOffOutlinedIcon
                      style={{ margin: "0 4px", fontSize: "15px" }}
                    />
                  )}
                  % Dealer Margin
                </span>
              </div>
              <Button
                variant="contained"
                sx={{
                  height: "25px",
                  padding: "0 10px",
                  borderRadius: "30px",
                  background: "#283248",
                  color: "white",
                  fontSize: "12px",
                  fontWeight: "500",
                  textTransform: "none",
                  letterSpacing: "0.5px",
                  marginTop: "8px",
                  "&:hover": {
                    background: "#ba4747",
                  },
                }}
                onClick={() => handleFetchThroughBrands("proride")}
              >
                Shop Now
              </Button>
            </div>
          </div>
          <div
            className="w-[240px] h-[380px] rounded-md relative mx-5 cursor-pointer flex-shrink-0"
            onClick={() => {
              handleFetchThroughBrands("sovereign");
              logActionClickedFromComponent(
                "Sovereign",
                "Vocal4Local",
                userDetail?.UserID
              );
            }}
          >
            <img
              loading="lazy"
              src="https://w0.peakpx.com/wallpaper/926/811/HD-wallpaper-bike-cafe-cycle-downhill-hornet-motor-motors-mountain-racer.jpg"
              alt=""
              className="w-full h-full object-cover bg-center bg-cover rounded-md"
            />

            <div className="w-[94%] h-[45%] rounded-md absolute bottom-[2.2%] left-[3%] bg-white-1 flex-center flex-col opacity-90 hover:opacity-100">
              <div className="w-full h-[30px] mb-2 flex-center">
                <img
                  loading="lazy"
                  src={sovereignLogo}
                  alt=""
                  className="w-auto h-[25px] contain"
                />
              </div>
              <span className="text-[12px] font-bold text-center text-black-1/90 h-[25px]">
                Premium Saddles
              </span>
              <div className="w-full h-[25px] flex-center">
                <span className="text-[12px] text-black-1/90 font-bold capitalize tracking-wide flex items-center">
                  {Object.keys(userDetail).length > 0 ? (
                    "50-85"
                  ) : (
                    <VisibilityOffOutlinedIcon
                      style={{ margin: "0 4px", fontSize: "15px" }}
                    />
                  )}
                  % Dealer Margin
                </span>
              </div>
              <Button
                variant="contained"
                sx={{
                  height: "25px",
                  padding: "0 10px",
                  borderRadius: "30px",
                  background: "#283248",
                  color: "white",
                  fontSize: "12px",
                  fontWeight: "500",
                  textTransform: "none",
                  letterSpacing: "0.5px",
                  marginTop: "8px",
                  "&:hover": {
                    background: "#ba4747",
                  },
                }}
                onClick={() => handleFetchThroughBrands("sovereign")}
              >
                Shop Now
              </Button>
            </div>
          </div>
          <div
            className="w-[240px] h-[380px] rounded-md relative mx-5 cursor-pointer flex-shrink-0"
            onClick={() => {
              handleFetchThroughBrands("bikepro");
              logActionClickedFromComponent(
                "BikePro",
                "Vocal4Local",
                userDetail?.UserID
              );
            }}
          >
            <img
              loading="lazy"
              src="https://c0.wallpaperflare.com/preview/500/768/1015/blue-bike-in-selective-focus-photography.jpg"
              alt=""
              className="w-full h-full object-cover bg-center bg-cover rounded-md"
            />

            <div className="w-[94%] h-[45%] rounded-md absolute bottom-[2.2%] left-[3%] bg-white-1 flex-center flex-col opacity-90 hover:opacity-100">
              <div className="w-full h-[30px] mb-2 flex-center">
                <img
                  loading="lazy"
                  src={bikeProLogo}
                  alt=""
                  className="w-auto h-[25px] contain"
                />
              </div>
              <span className="text-[12px] font-bold text-center text-black-1/90 h-[25px]">
                Bottom Brackets & Alloy Hubs
              </span>
              <div className="w-full h-[25px] flex-center">
                <span className="text-[12px] text-black-1/90 font-bold capitalize tracking-wide flex items-center">
                  {Object.keys(userDetail).length > 0 ? (
                    "50-85"
                  ) : (
                    <VisibilityOffOutlinedIcon
                      style={{ margin: "0 4px", fontSize: "15px" }}
                    />
                  )}
                  % Dealer Margin
                </span>
              </div>
              <Button
                variant="contained"
                sx={{
                  height: "25px",
                  padding: "0 10px",
                  borderRadius: "30px",
                  background: "#283248",
                  color: "white",
                  fontSize: "12px",
                  fontWeight: "500",
                  textTransform: "none",
                  letterSpacing: "0.5px",
                  marginTop: "8px",
                  "&:hover": {
                    background: "#ba4747",
                  },
                }}
                onClick={() => handleFetchThroughBrands("bikepro")}
              >
                Shop Now
              </Button>
            </div>
          </div>
          <div
            className="w-[240px] h-[380px] rounded-md relative mx-5 cursor-pointer flex-shrink-0"
            onClick={() => {
              handleFetchThroughBrands("master");
              logActionClickedFromComponent(
                "Master",
                "Vocal4Local",
                userDetail?.UserID
              );
            }}
          >
            <img
              loading="lazy"
              src="https://preview.redd.it/nbd-commencal-meta-tr-v0-9d13nkt74aqb1.jpg?width=1080&crop=smart&auto=webp&s=831be7a350083dfee92feedc470a43892382867d"
              alt=""
              className="w-full h-full object-cover bg-center bg-cover rounded-md"
            />

            <div className="w-[94%] h-[45%] rounded-md absolute bottom-[2.2%] left-[3%] bg-white-1 flex-center flex-col opacity-90 hover:opacity-100">
              <div className="w-full h-[30px] mb-2 flex-center">
                <img
                  loading="lazy"
                  src={masterLogo}
                  alt=""
                  className="w-auto h-[25px] contain"
                />
              </div>
              <span className="text-[12px] font-bold text-center text-black-1/90 h-[25px]">
                Bushless Chains
              </span>
              <div className="w-full h-[25px] flex-center">
                <span className="text-[12px] text-black-1/90 font-bold capitalize tracking-wide flex items-center">
                  {Object.keys(userDetail).length > 0 ? (
                    "50-85"
                  ) : (
                    <VisibilityOffOutlinedIcon
                      style={{ margin: "0 4px", fontSize: "15px" }}
                    />
                  )}
                  % Dealer Margin
                </span>
              </div>
              <Button
                variant="contained"
                sx={{
                  height: "25px",
                  padding: "0 10px",
                  borderRadius: "30px",
                  background: "#283248",
                  color: "white",
                  fontSize: "12px",
                  fontWeight: "500",
                  textTransform: "none",
                  letterSpacing: "0.5px",
                  marginTop: "8px",
                  "&:hover": {
                    background: "#ba4747",
                  },
                }}
                onClick={() => handleFetchThroughBrands("master")}
              >
                Shop Now
              </Button>
            </div>
          </div>
          <div
            className="w-[240px] h-[380px] rounded-md relative mx-5 cursor-pointer flex-shrink-0"
            onClick={() => {
              handleFetchThroughBrands("dass");
              logActionClickedFromComponent(
                "Dass",
                "Vocal4Local",
                userDetail?.UserID
              );
            }}
          >
            <img
              loading="lazy"
              src="https://www.johnsonbecker.com/wp-content/uploads/Trek-Bicycle-Disc-Brakes-Recall-Lawsuit.jpg"
              alt=""
              className="w-full h-full object-cover bg-center bg-cover rounded-md"
            />

            <div className="w-[94%] h-[45%] rounded-md absolute bottom-[2.2%] left-[3%] bg-white-1 flex-center flex-col opacity-90 hover:opacity-100">
              <div className="w-full h-[30px] mb-2 flex-center">
                <img
                  loading="lazy"
                  src="https://dassbike.com/assets/img/logo/logo.png"
                  alt=""
                  className="w-auto h-[25px] contain"
                />
              </div>
              <span className="text-[12px] font-bold text-center text-black-1/90 h-[25px]">
                Brake Parts & Hubs
              </span>
              <div className="w-full h-[25px] flex-center">
                <span className="text-[12px] text-black-1/90 font-bold capitalize tracking-wide flex items-center">
                  {Object.keys(userDetail).length > 0 ? (
                    "50-85"
                  ) : (
                    <VisibilityOffOutlinedIcon
                      style={{ margin: "0 4px", fontSize: "15px" }}
                    />
                  )}
                  % Dealer Margin
                </span>
              </div>
              <Button
                variant="contained"
                sx={{
                  height: "25px",
                  padding: "0 10px",
                  borderRadius: "30px",
                  background: "#283248",
                  color: "white",
                  fontSize: "12px",
                  fontWeight: "500",
                  textTransform: "none",
                  letterSpacing: "0.5px",
                  marginTop: "8px",
                  "&:hover": {
                    background: "#ba4747",
                  },
                }}
                onClick={() => handleFetchThroughBrands("dass")}
              >
                Shop Now
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default OurBrands;
