import React from "react";
import { connect, useSelector } from "react-redux";
import { selectUser, setUser } from "../../features/auth/authSlice";
import { useDispatch } from "react-redux";

import { Button, Dialog } from "@mui/material";
import { useNavigate } from "react-router";
import toast from "react-hot-toast";
import { logUserLogout } from "../../analytics";

function LogoutOverlay({ openLogout, handleOpenLogout, setUser }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userDetail = useSelector(selectUser);
  const handleLogout = async () => {
    logUserLogout(userDetail?.UserID);
    try {
      // Remove token and user_id from cookies
      document.cookie = "token=;";
      document.cookie = "UserID=;";

      // Show SweetAlert for successful logout
      toast.success('Logged Out Successfully');

      dispatch(setUser({}));
      handleOpenLogout()
      navigate("/");
      window.location.reload();

      // Perform any additional actions after successful logout, such as redirecting to the login page
    } catch (error) {
      // Handle error if logout fails
      console.error("Logout Error:", error);
      // Show SweetAlert for error during logout
      toast.error('Logout Failed');
    }
  };

  return (
    <Dialog
      open={openLogout}
      onClose={handleOpenLogout}
      fullWidth={true}
      disableRestoreFocus
      sx={{
        "& .MuiDialog-paper": {
          width: "300px",
          height: "200px",
        },
      }}
    >
      <div className="w-[300px] h-[200px] flex flex-col items-center justify-center rounded-md bg-white-1 p-[30px]">
        <h3 className="text-[16px] text-black-1/90 my-3">Log out?</h3>
        <span className="text-[14px] text-black-1/70 text-center my-2">
          Are you sure want to logout?
        </span>
        <div className="w-full flex items-center justify-center my-4">
          <Button
            variant="contained"
            sx={{
              height: "32px",
              padding: "0 8px",
              borderRadius: "30px",
              background: "rgba(0,0,0,0.1)",
              fontSize: "14px",
              fontWeight: "500",
              textTransform: "none",
              marginRight: "10px",
              color: "black",
              letterSpacing: "0.8px",
              "&:hover": {
                background: "rgba(0,0,0,0.1)",
              },
            }}
            onClick={handleOpenLogout}
          >
            No
          </Button>
          <Button
            variant="contained"
            sx={{
              height: "32px",
              padding: "0 8px",
              borderRadius: "30px",
              background: "#ba3737",
              fontSize: "14px",
              marginLeft: "10px",
              fontWeight: "500",
              textTransform: "none",
              color: "white",
              letterSpacing: "0.8px",
              "&:hover": {
                background: "#ba3737",
              },
            }}
            onClick={handleLogout}
          >
            Yes
          </Button>
        </div>
      </div>
    </Dialog>
  );
}

const mapStateToProps = (state) => ({
  user: state.user,
});
const mapDispatchToProps = {
  setUser,
};
export default connect(mapStateToProps, mapDispatchToProps)(LogoutOverlay);
