import { Button, Tooltip, useMediaQuery, useTheme } from "@mui/material";
import React from "react";
import { FormattedNumber } from "../../utils/numberAnimation";
import EastIcon from "@mui/icons-material/East";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

const BulkOrderPriceSummary = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  return (
    <div className="w-full flex flex-col bg-white-1">
      <h3 className="text-[16px] text-black-1 font-bold pb-4 tracking-wide border-b-[1px] border-black-1/10">
        Payment Summary
      </h3>
      <div className="w-full flex-col pt-4 items-center">
        <div className="w-full flex items-center justify-between relative mb-4 sm:mb-3">
          <p className="text-[14px] text-black-1/80 font-semibold tracking-wide">
            Order Quantity
          </p>
          <hr className="absolute left-1/2 -translate-x-1/2 w-[6px] border-[1px] border-black-1 " />
          <span className="text-[14px] text-black-1 font-semibold tracking-wide">
            <FormattedNumber value={200} />
          </span>
        </div>
        <div className="w-full flex flex-col items-start relative mb-4 sm:mb-3">
          <div className="w-full flex items-center justify-between">
            <p className="text-[14px] text-black-1/80 font-semibold tracking-wide flex items-center">
              Order Value
              
            </p>
            <hr className="absolute left-1/2 -translate-x-1/2 w-[6px] border-[1px] border-black-1 " />
            <span className="flex items-center text-[14px] text-black-1 font-semibold tracking-wide">
              <FormattedNumber value={93000} />
            </span>
          </div>
        </div>

        <div className="w-full flex items-center justify-between relative mb-4 sm:mb-3">
          <p className="text-[14px] text-black-1/80 font-semibold tracking-wide">
            Taxes
          </p>
          <hr className="absolute left-1/2 -translate-x-1/2 w-[6px] border-[1px] border-black-1 " />
          <span className="text-[14px] text-black-1 font-semibold tracking-wide">
            +<FormattedNumber value={11110} />
          </span>
        </div>
        <div className="w-full flex items-center justify-between relative mb-4 sm:mb-3">
          <p className="text-[14px] text-black-1/80 font-semibold tracking-wide">
            Discount
          </p>
          <hr className="absolute left-1/2 -translate-x-1/2 w-[6px] border-[1px] border-black-1 " />
          <span className="text-[14px] text-black-1 font-semibold tracking-wide">
            -
            <FormattedNumber value={parseInt(2688)} />
          </span>
        </div>
        <div className="w-full flex items-center justify-between relative mb-4 sm:mb-3">
          <p className="text-[14px] text-black-1/80 font-semibold tracking-wide">
            Coupon
          </p>
          <hr className="absolute left-1/2 -translate-x-1/2 w-[6px] border-[1px] border-black-1 " />
          <span className="text-[14px] text-black-1 font-semibold tracking-wide">
            -
          </span>
        </div>
        <div className="w-full flex items-center justify-between relative mb-4 sm:mb-3">
          <p className="text-[14px] text-black-1/80 font-semibold tracking-wide">
            Cyclecircle Commision
          </p>
          <hr className="absolute left-1/2 -translate-x-1/2 w-[6px] border-[1px] border-black-1 " />
          <span className="text-[14px] text-black-1 font-semibold tracking-wide">
            15%
          </span>
        </div>
        <div className={`w-full flex flex-col items-center max-sm:pb-[70px]`}>
          <div className="w-full flex items-center justify-between relative pt-4 border-t-[1px] border-black-1/10">
            <p className="text-[16px] text-peach font-semibold tracking-wide">
              Total Payable
            </p>
            <hr className="absolute left-1/2 -translate-x-1/2 w-[6px] border-[1px] border-peach " />
            <span className="text-[16px] text-peach font-semibold tracking-wide">
              <FormattedNumber value={104832} />
            </span>
          </div>
        </div>

        {isMobile ? (
          <div
            className="w-full h-[70px] flex items-center justify-between fixed left-0 bottom-0 right-0 bg-white-1 z-10 px-3"
            style={{ boxShadow: "0 -3px 5px 0 rgba(0,0,0,0.1)" }}
          >
            <div className="w-[28%] h-full flex flex-col justify-center">
              <span className="text-black-1/80 font-semibold text-12">
                Total Payable-
              </span>
              <span className="text-18 text-black-1 font-bold">
                <FormattedNumber
                  value={104832}
                />
              </span>
            </div>
            <Button
              variant="contained"
              sx={{
                width: "60%",
                height: "40px",
                background: "#28a447",
                outline: "none",
                borderRadius: "4px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                color: "white",
                fontWeight: "700",
                fontSize: "15px",
                whiteSpace: "nowrap",
                "& svg": {
                  fontSize: "20px",
                  marginLeft: "8px",
                },
                "&:hover": {
                  background: "#28a447",
                },
              }}
             
            >
              Proceed to Checkout
              <EastIcon
                className="text-white-1 ml-3 sm:ml-2"
                style={{ fontSize: "16px" }}
              />
            </Button>
          </div>
        ) : (
          <div className="w-full flex flex-col items-center justify-between relative pt-4">
            <Button
              variant="contained"
              sx={{
                width: "100%",
                height: isMobile ? "40px" : "35px",
                borderRadius: isMobile ? "4px" : "4px",
                padding: "5px 8px",
                background: "var(--color-blue)",
                fontSize: isMobile ? "16px" : "15px",
                fontWeight: "600",
                color: "white",
                textTransform: "none",
                fontFamily:"inherit",
                letterSpacing: "1px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                "&:hover": {
                  background: "var(--color-blue-hover)",
                },
              }}
              className="breadcrum-next-step"
            >
              Proceed to Checkout
              <EastIcon
                className="text-white-1 ml-3 sm:ml-2"
                style={{ fontSize: "16px" }}
              />
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};

export default BulkOrderPriceSummary;
