import React from "react";
import AddSharpIcon from "@mui/icons-material/AddSharp";
import RemoveSharpIcon from "@mui/icons-material/RemoveSharp";
import { IMAGE_BASE } from "../../config";
import { useDispatch } from "react-redux";
import LocalOfferOutlinedIcon from "@mui/icons-material/LocalOfferOutlined";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import InfoIcon from "@mui/icons-material/Info";
import {
  calculateGST,
  calculateUpdatedDiscountPercentage,
} from "../../utils/utils";
import { useNavigate } from "react-router";
import {
  handleAddToWishlist,
  handleChangeQtyOfProductCarts,
  handleRemoveFromCarts,
} from "../../utils/dispatch";
import { AnimatedQuantity, FormattedNumber } from "../../utils/numberAnimation";
import { Tooltip } from "@mui/material";
import { logActionClickedFromComponent } from "../../analytics";

function CartsAllProduct({
  cart,
  userDetail,
  handleOpen,
  handleClose,
  setUseCreditAmount,
  setUseWalletAmount,
  setSelectedCoupon,
}) {
  const dispatch = useDispatch();
  return (
    <div className="w-full flex flex-col bg-white-1 shadow-md rounded-md">
      {cart &&
        cart.length > 0 &&
        cart?.map((data) => (
          <ProductCartCard
            data={data}
            userDetail={userDetail}
            dispatch={dispatch}
            handleOpen={handleOpen}
            handleClose={handleClose}
            setUseCreditAmount={setUseCreditAmount}
            setUseWalletAmount={setUseWalletAmount}
            setSelectedCoupon={setSelectedCoupon}
          />
        ))}
    </div>
  );
}

export default CartsAllProduct;

export const ProductCartCard = ({
  data,
  userDetail,
  dispatch,
  handleOpen,
  handleClose,
  setUseCreditAmount,
  setUseWalletAmount,
  setSelectedCoupon,
}) => {
  const navigate = useNavigate();

  return (
    <div
      key={data?.skuDetails?.prod_ID}
      className="w-full flex h-[190px] border-b-[1px] border-black-1/10 py-4 pl-5 relative"
    >
      <p className="absolute top-[6px] right-2 text-gray-500 text-[11px] tracking-wide">
        Est. Delivery:{" "}
        {new Date(
          new Date().getTime() + 5 * 24 * 60 * 60 * 1000
        ).toLocaleDateString("en-US", {
          day: "numeric",
          month: "short",
          year: "numeric",
        })}
      </p>
      <p className="absolute bottom-[6px] right-2 text-gray-500 text-[12px] tracking-wide">
        Total Amount:&nbsp;
        <b className="text-[15px] text-black-1/90 font-bold ml-[2px]">
          <FormattedNumber
            value={
              (data?.skuDetails?.Offer_price
                ? parseInt(data.skuDetails?.Offer_price)
                : parseInt(data.skuDetails?.NDP_Price)) *
              (data?.buy_quantity || 1)
            }
          />
        </b>{" "}
        +{" "}
        <FormattedNumber
          value={
            (data?.skuDetails?.Offer_price
              ? calculateGST(
                  parseInt(data.skuDetails?.Offer_price),
                  parseInt(data.skuDetails?.GST)
                )
              : calculateGST(
                  parseInt(data.skuDetails?.NDP_Price),
                  parseInt(data.skuDetails?.GST)
                )) * (data?.buy_quantity || 1)
          }
        />{" "}
        GST
      </p>
      <div className="min-w-[120px] w-[120px] h-full flex flex-col items-center justify-between">
        <div
          className="w-full h-[80%]  flex-center cursor-pointer"
          onClick={() =>
            navigate(
              `/product/${
                data?.skuDetails?.prod_ID && data?.skuDetails?.prod_ID
              }`
            )
          }
        >
          <LazyLoadImage
            src={`https://cyclecircle.blr1.cdn.digitaloceanspaces.com/${IMAGE_BASE}_PRODUCTS_IMAGE/${
              data?.images && data.images
            }`}
            alt="product-img"
            effect="blur"
            wrapperProps={{
              style: {
                transitionDelay: "1s",
              },
            }}
            className="object-contain px-2"
          />
        </div>
        <div className="w-full h-[20%] pt-1 flex items-center justify-between  ">
          <RemoveSharpIcon
            onClick={() => {
              const bundleSize = parseInt(data?.skuDetails?.Bundle);
              const currentQuantity = parseInt(data?.buy_quantity);

              if (currentQuantity === bundleSize) {
                return;
              } else {
                const newQty = Math.max(currentQuantity - bundleSize, 0); // Ensure the quantity doesn't go below 0
                handleChangeQtyOfProductCarts(
                  data,
                  userDetail,
                  newQty,
                  dispatch,
                  handleOpen,
                  handleClose
                );
                logActionClickedFromComponent(
                  `Decrease Qty Product:${data?.skuDetails?.prod_ID}`,
                  "Carts",
                  userDetail?.UserID
                );
                setUseWalletAmount(0);
                setUseCreditAmount(0);
                setSelectedCoupon({
                  couponCode: "",
                  couponDiscountPercentage: 0,
                  couponDiscountTaken: 0,
                });
              }
            }}
            className={`text-white-1 bg-blue rounded-sm p-1 cursor-pointer ${
              data?.buy_quantity === 1 && "pointer-events-none opacity-50"
            }`}
            style={{ fontSize: "25px" }}
          />
          <span className="text-black-1/80 text-[15px] border-[1px] font-sans border-l-black-1/10 w-[40px] h-[25px] text-center ">
            {/* {data?.buy_quantity} */}
            <AnimatedQuantity value={data?.buy_quantity} />
          </span>
          <AddSharpIcon
            onClick={() => {
              const bundleSize = parseInt(data?.skuDetails?.Bundle);
              const currentQuantity = parseInt(data?.buy_quantity);

              const newQty = currentQuantity + bundleSize;

              handleChangeQtyOfProductCarts(
                data,
                userDetail,
                newQty,
                dispatch,
                handleOpen,
                handleClose
              );
              logActionClickedFromComponent(
                `Increase Qty Product:${data?.skuDetails?.prod_ID}`,
                "Carts",
                userDetail?.UserID
              );
              setUseWalletAmount(0);
              setUseCreditAmount(0);
              setSelectedCoupon({
                couponCode: "",
                couponDiscountPercentage: 0,
                couponDiscountTaken: 0,
              });
            }}
            className={`text-white-1 bg-blue rounded-sm p-1 cursor-pointer ${
              data?.buy_quantity === data?.skuDetails?.qty &&
              "opacity-50 pointer-events-none"
            }`}
            style={{ fontSize: "25px" }}
          />
        </div>
      </div>
      <div className="w-[calc(100% - 120px)] h-full flex flex-col items-start ml-4">
        <div className="w-full h-[80%]  flex items-start flex-col ">
          <div className="w-full">
            {data && data?.skuDetails?.manufacture_images ? (
              <LazyLoadImage
                className="h-[25px] object-contain"
                src={`https://cyclecircle.blr1.cdn.digitaloceanspaces.com/${IMAGE_BASE}_MANUFACTURE_IMAGE/${
                  data && data?.skuDetails?.manufacture_images
                }`}
                alt="brand-logo"
                effect="blur"
                wrapperProps={{
                  // If you need to, you can tweak the effect transition using the wrapper style.
                  style: { transitionDelay: "1s" },
                }}
              />
            ) : (
              <h3 className="text-[11px] italic font-extrabold tracking-wider text-peach">
                {data?.skuDetails?.manufacture_name &&
                  data?.skuDetails?.manufacture_name}
              </h3>
            )}
          </div>
          <p
            className="text-[15px] text-blue font-bold tracking-wide mt-1 cursor-pointer"
            onClick={() => {
              navigate(
                `/product/${
                  data?.skuDetails?.prod_ID && data?.skuDetails?.prod_ID
                }`
              );
              logActionClickedFromComponent(
                `Visit Product: ${data?.skuDetails?.prod_ID}`,
                "Carts",
                userDetail?.UserID
              );
            }}
          >
            {(data?.skuDetails?.prod_name).slice(0, 65)}
          </p>
          <p className=" mt-1 flex items-center">
            {data?.skuDetails?.NDP_Price && (
              <span className="text-[15px] flex items-center text-blue font-bold tracking-wide">
                Dealer Price-&nbsp;
                <b
                  style={{
                    textDecoration:
                      data?.skuDetails?.Offer_price !== null
                        ? "line-through"
                        : "none",
                    fontSize:
                      data?.skuDetails?.Offer_price !== null
                        ? "12.5px"
                        : "15px",
                  }}
                >
                  {" "}
                  <FormattedNumber
                    value={parseInt(data?.skuDetails?.NDP_Price)}
                  />
                </b>
              </span>
            )}
            {data?.skuDetails?.Offer_price !== null && (
              <span className="text-[16px] text-green font-bold tracking-wide ml-1">
                <FormattedNumber
                  value={parseInt(data?.skuDetails?.Offer_price)}
                />
              </span>
            )}{" "}
            {data?.skuDetails?.manufacture_name === "GARRARI BIKES" && (
              <span className="text-gray-600 text-[12px] ml-1 flex items-center">
                <span className="block lg:hidden xl:block">
                  + Shipping charges applicable
                </span>

                <Tooltip
                  title="Shipping charges will be collected by the delivery agent upon delivery."
                  arrow
                  placement="top"
                >
                  <InfoIcon
                    sx={{
                      fontSize: "16px",
                      color: "gray",
                      marginLeft: "4px",
                      cursor: "pointer",
                    }}
                  />
                </Tooltip>
              </span>
            )}
          </p>
          <p className="text-[11px] flex items-center text-gray-500 font-semibold tracking-wide mt-0">
            Product MRP-&nbsp;
            <b className="text-[13px] text-gray-800">
              <FormattedNumber value={parseInt(data?.skuDetails?.MRP_price)} />
            </b>
          </p>
          {calculateUpdatedDiscountPercentage(
            data?.skuDetails?.MRP_price,
            data?.skuDetails?.NDP_Price,
            data?.GST
          ) > 0 && (
            <div className="text-[9px] flex items-center text-white-1 bg-peach py-[3px] px-[6px] font-sans rounded-[30px] shadow-sm mt-[6px] tracking-wide">
              <LocalOfferOutlinedIcon
                className="text-white-1 mt-[1px]"
                style={{ fontSize: "10px" }}
              />
              <span
                style={{
                  textDecoration:
                    data?.skuDetails?.Offer_price !== null && "line-through",
                  fontSize: data?.skuDetails?.Offer_price !== null && "9px",
                }}
                className="text-white-1 px-1"
              >
                {calculateUpdatedDiscountPercentage(
                  data?.skuDetails?.MRP_price,
                  parseInt(data?.skuDetails?.NDP_Price),
                  data?.GST
                )}
                %
              </span>
              {data?.skuDetails?.Offer_price !== null && (
                <span className="text-[10px] text-white-1 pr-1">
                  {calculateUpdatedDiscountPercentage(
                    data?.skuDetails?.MRP_price,
                    parseInt(data?.skuDetails?.Offer_price),
                    data?.GST
                  )}
                  %
                </span>
              )}{" "}
              Net Dealer Margin
            </div>
          )}
        </div>
        <div className="w-full h-[20%] flex items-center pt-2 ">
          <span
            className="text-[12px] text-blue font-bold cursor-pointer"
            onClick={(e) => {
              handleAddToWishlist(
                data,
                userDetail,
                dispatch,
                handleOpen,
                handleClose,
                e
              );
              handleRemoveFromCarts(
                data,
                userDetail,
                dispatch,
                handleOpen,
                handleClose
              );
              logActionClickedFromComponent(
                `Add to Wishlist Product:${data?.skuDetails?.prod_ID}`,
                "Carts",
                userDetail?.UserID
              );
              setUseWalletAmount(0);
              setUseCreditAmount(0);
              setSelectedCoupon({
                couponCode: "",
                couponDiscountPercentage: 0,
                couponDiscountTaken: 0,
              });
            }}
          >
            MOVE TO WISHLIST
          </span>
          <span
            className="text-[12px] text-blue font-bold ml-6 cursor-pointer"
            onClick={() => {
              handleRemoveFromCarts(
                data,
                userDetail,
                dispatch,
                handleOpen,
                handleClose
              );
              logActionClickedFromComponent(
                `Remove from Cart Product:${data?.skuDetails?.prod_ID}`,
                "Carts",
                userDetail?.UserID
              );
              setUseWalletAmount(0);
              setUseCreditAmount(0);
              setSelectedCoupon({
                couponCode: "",
                couponDiscountPercentage: 0,
                couponDiscountTaken: 0,
              });
            }}
          >
            REMOVE
          </span>
        </div>
      </div>
    </div>
  );
};
