import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { getHomePageCollectionAsync } from "../../features/listingPage/listingPageSlice";
import { IMAGE_BASE } from "../../config";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useSelector } from "react-redux";
import { selectUser } from "../../features/auth/authSlice";
import { logActionClickedFromComponent } from "../../analytics";

const ThreeCollection = ({ title, collectionId, dispatch, collections }) => {
  const [productsCollection1, setProductsCollection1] = useState([]); // Products for the first collection
  const [productsCollection2, setProductsCollection2] = useState([]); // Products for the second collection
  const [productsCollection3, setProductsCollection3] = useState([]); // Products for the second collection
  const [mainSlider, setMainSlider] = useState(null);
  const [thumbSlider, setThumbSlider] = useState(null);
  const navigate = useNavigate();
  const userDetail = useSelector(selectUser);

  useEffect(() => {
    // Fetch products for a given collection
    const handleFetchProducts = async (collectionId, setProducts) => {
      try {
        const fetchedProducts = await dispatch(
          getHomePageCollectionAsync(collectionId)
        );
        setProducts(fetchedProducts?.payload?.Data?.product?.slice(0, 4)); // Set products for the respective collection
      } catch (error) {
        console.error("Failed to fetch products", error);
      }
    };
    if (collections && collections[0]) {
      handleFetchProducts(
        collections[0]?.collections_Id,
        setProductsCollection1
      );
    }
    if (collections && collections[1]) {
      handleFetchProducts(
        collections[1]?.collections_Id,
        setProductsCollection2
      );
    }
    if (collections && collections[2]) {
      handleFetchProducts(
        collections[2]?.collections_Id,
        setProductsCollection3
      );
    }
  }, [dispatch, collections]);

  const handleNavigate = (prod_id) => {
    navigate(`/product/${prod_id}`);
  };

  const handleFetchCollectionProduct = (coll_id, coll_name) => {
    navigate(`/listing-page/${"coll" + coll_id}/${coll_name}`);
  };

  const mainSliderSettings = {
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    autoplay: true,
    autoplaySpeed: 2000,
  };

  const thumbSliderSettings = {
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    arrows: false,
    focusOnSelect: true,
    autoplay: true,
    autoplaySpeed: 2000,
    asNavFor: mainSlider,
    dots: true,
    customPaging: function (i) {
      return (
        <div
          className={`w-3 h-3 rounded-full ${
            i === thumbSlider?.innerSlider?.state?.currentSlide
              ? "bg-black-1"
              : "bg-gray-400"
          }`}
        />
      );
    },
  };

  return (
    <div className="bg-[#f3f3f3] px-4">
      <h2 className="text-2xl text-gray-800 font-semibold mb-6">
        Discover our best collection
      </h2>

      <div className="grid grid-cols-1 lg:grid-cols-3 gap-3">
        {/* Most Popular Products Section */}
        {collections && collections[0] && (
          <div className="bg-white-1 rounded-lg p-3">
            <div className="flex justify-between items-start mb-4">
              <div className="flex flex-col items-start justify-start">
                <h3 className="text-black-1/80 font-medium text-base mb-[2px]">
                  {collections[0]?.collection_name}
                </h3>
                <span className="text-gray-600 text-[12px] font-light">
                  {productsCollection1 &&
                  productsCollection1[0]?.manufacture_name
                    ? productsCollection1[0]?.manufacture_name
                        .toLowerCase()
                        .replace(/\b\w/g, (char) => char.toUpperCase())
                    : ""}
                  &nbsp;
                  {productsCollection1 && productsCollection1[0]?.categoryName
                    ? productsCollection1[0]?.categoryName
                        .toLowerCase()
                        .replace(/\b\w/g, (char) => char.toUpperCase())
                    : ""}
                </span>
              </div>

              <button
                className="text-green-600 text-sm font-semibold"
                onClick={() => {
                  handleFetchCollectionProduct(
                    collections[0]?.collections_Id,
                    collections[0]?.collection_name
                  );
                  logActionClickedFromComponent(
                    collections[0]?.collection_name,
                    "Collection",
                    userDetail?.UserID
                  );
                }}
              >
                View All&nbsp; <ArrowForwardIcon style={{ fontSize: "15px" }} />
              </button>
            </div>
            <div className="flex flex-col items-center">
              {/* Main Slider */}
              <Slider
                {...mainSliderSettings}
                ref={(slider) => setMainSlider(slider)}
                className="w-full"
              >
                {productsCollection1 &&
                  productsCollection1[0]?.images &&
                  productsCollection1[0]?.images.map((img, index) => (
                    <div
                      key={index}
                      className="relative w-full h-72 bg-[#f3f3f3] p-2 rounded-md"
                    >
                      <img
                        src={`https://cyclecircle.blr1.cdn.digitaloceanspaces.com/${IMAGE_BASE}_PRODUCTS_IMAGE/${img}`}
                        alt={`product-img-${index}`}
                        className="w-full h-full object-contain mix-blend-multiply cursor-pointer"
                        onClick={() => {
                          handleFetchCollectionProduct(
                            collections[0]?.collections_Id,
                            collections[0]?.collection_name
                          );
                          logActionClickedFromComponent(
                            collections[0]?.collection_name,
                            "Collection",
                            userDetail?.UserID
                          );
                        }}
                      />
                    </div>
                  ))}
              </Slider>

              {/* Thumbnails Slider */}
              <Slider
                {...thumbSliderSettings}
                ref={(slider) => setThumbSlider(slider)}
                className="w-full mt-2 mb-4"
              >
                {productsCollection1 &&
                  productsCollection1[0]?.images &&
                  productsCollection1[0]?.images.map((img, index) => (
                    <div key={index} className="cursor-pointer">
                      <div className="w-fit h-24 bg-white-1 rounded-md p-2 border border-gray-50">
                        <img
                          src={`https://cyclecircle.blr1.cdn.digitaloceanspaces.com/${IMAGE_BASE}_PRODUCTS_IMAGE/${img}`}
                          alt=""
                          className="w-full h-full object-contain mix-blend-multiply"
                        />
                      </div>
                    </div>
                  ))}
              </Slider>
            </div>
          </div>
        )}

        {/* Top Global Brands Section */}
        {collections && collections[1] && (
          <div className="bg-white-1 rounded-lg p-3">
            <div className="flex justify-between items-center">
              <h3 className="text-black-1/80 font-medium text-base mb-[2px]">
                {collections[1]?.collection_name}
              </h3>
              <button
                className="text-green-600 text-sm font-semibold flex items-center"
                onClick={() => {
                  handleFetchCollectionProduct(
                    collections[1]?.collections_Id,
                    collections[1]?.collection_name
                  );
                  logActionClickedFromComponent(
                    collections[1]?.collection_name,
                    "Collection",
                    userDetail?.UserID
                  );
                }}
              >
                View All&nbsp; <ArrowForwardIcon style={{ fontSize: "15px" }} />
              </button>
            </div>
            <div className="grid grid-cols-2 gap-2 gap-y-0">
              {productsCollection2.map((product, index) => (
                <div
                  key={index}
                  className="p-1 rounded-md cursor-pointer"
                  onClick={() => handleNavigate(product?.prod_ID)}
                >
                  <div className="relative w-full h-48 bg-[#f3f3f3] rounded-md p-2">
                    <img
                      src={`https://cyclecircle.blr1.cdn.digitaloceanspaces.com/${IMAGE_BASE}_PRODUCTS_IMAGE/${
                        product?.images && product?.images[0]
                      }`}
                      alt={product?.prod_name}
                      className="w-full h-full object-contain mix-blend-multiply"
                    />
                  </div>

                  <span className="text-green-600 font-semibold text-sm text-start">
                    {product.discount
                      ? `Min ${product.discount}% Discount`
                      : "Special Offer"}
                  </span>
                </div>
              ))}
            </div>
          </div>
        )}
        {collections && collections[2] && (
          <div className="bg-white-1 rounded-lg p-3">
            <div className="flex justify-between items-center">
              <h3 className="text-black-1/80 font-medium text-base mb-[2px]">
                {collections[2]?.collection_name}
              </h3>
              <button
                className="text-green-600 text-sm font-semibold flex items-center"
                onClick={() => {
                  handleFetchCollectionProduct(
                    collections[2]?.collections_Id,
                    collections[2]?.collection_name
                  );
                  logActionClickedFromComponent(
                    collections[2]?.collection_name,
                    "Collection",
                    userDetail?.UserID
                  );
                }}
              >
                View All&nbsp; <ArrowForwardIcon style={{ fontSize: "15px" }} />
              </button>
            </div>
            <div className="grid grid-cols-2 gap-2 gap-y-0">
              {productsCollection3.map((product, index) => (
                <div
                  key={index}
                  className="p-1 rounded-md cursor-pointer"
                  onClick={() => handleNavigate(product?.prod_ID)}
                >
                  <div className="relative w-full h-48 bg-[#f3f3f3] rounded-md p-2">
                    <img
                      src={`https://cyclecircle.blr1.cdn.digitaloceanspaces.com/${IMAGE_BASE}_PRODUCTS_IMAGE/${
                        product?.images && product?.images[0]
                      }`}
                      alt={product?.prod_name}
                      className="w-full h-full object-contain mix-blend-multiply"
                    />
                  </div>

                  <span className="text-green-600 font-semibold text-sm text-start">
                    {product.discount
                      ? `Min ${product.discount}% Discount`
                      : "Special Offer"}
                  </span>
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ThreeCollection;
