import React, { useState } from "react";
import "./MobileProductTabs.css";
import vector_black from "../../icons/Vector_black.svg";
import vector_white from "../../icons/Vector_white.svg";
import data_black from "../../icons/data_black.svg";
import data_white from "../../icons/data_white.svg";
import review_black from "../../icons/review_black.svg";
import review_white from "../../icons/review_white.svg";
import play_black from "../../icons/play_black.svg";
import insight_white from "../../icons/Insights-outline.svg";
import insight_black from "../../icons/Insights-filled.svg";
import play_white from "../../icons/play_white.svg";
import informationMaganta from "../../icons/information-maganta.png";
import informationBlack from "../../icons/information-black.png";
import MobileInsight from "./ProductAllInfo/MobileInsight";
import MobileAboutBrands from "./MobileAboutBrands";
import compare_cyan from "../../assets/compare-cyan.svg";
import MobileReview from "./ProductAllInfo/MobileReview";
import MobileTechData from "./ProductAllInfo/MobileTechData";
import MobileprodDesc from "./ProductAllInfo/MobileprodDesc";
import KeyboardArrowDownSharpIcon from "@mui/icons-material/KeyboardArrowDownSharp";
import KeyboardArrowUpSharpIcon from "@mui/icons-material/KeyboardArrowUpSharp";
import GarrariBrandDesc from "../../components/ProductDescription/BrandsDetails/GarrariBrandDesc";
import GarrariTechnicalData from "../../components/ProductDescription/BrandsDetails/GarrariTechnicalData";
import Compare from "../../components/ProductDescription/Compare";
import whatsInBoxImage from "../../assets/Whats-in-the-box-Mobile.webp";
import specificationImage from "../../assets/Specification-Mobile.webp";
import styled from "styled-components";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import outlineArrow from "../../icons/Chevron-Outline-1.svg";
import GarrariBrandDescMobile from "../../dComponent/BrandDesc/GarrariBrandDescMobile";
import { logActionClickedFromComponent } from "../../analytics";
import { useSelector } from "react-redux";
import { selectUser } from "../../features/auth/authSlice";

function MobileProductTabs({ productDesc }) {
  const [infoTitle, setInfoTitle] = useState("");
  const userDetail = useSelector(selectUser)

  const banners = [whatsInBoxImage, specificationImage];

  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
  };
  return (
    <>
      {productDesc && productDesc[0]?.Manufacturer === "GARRARI BIKES" && (
        <div className="w-full py-4 bg-white-1 px-2">
          <div className="brand-banner-container !rounded-none">
            <Slider {...settings} className="brand-banner-slider !rounded-none">
              {banners &&
                banners?.map((banner, index) => (
                  <LazyLoadImage
                    key={index}
                    src={banner}
                    alt=""
                    className="w-full h-full !rounded-none"
                    effect="blur"
                    wrapperProps={{
                      // If you need to, you can tweak the effect transition using the wrapper style.
                      style: { transitionDelay: "1s" },
                    }}
                  />
                ))}
            </Slider>
          </div>
        </div>
      )}
      <div className="mobile-product-all-info">
        <div className="mobile-product-description-tab-container">
          <div
            className="mobile-product-description-header-div"
            onClick={() =>
              "Compatibility" !== infoTitle
                ? (setInfoTitle("Compatibility"),logActionClickedFromComponent(
                  "Product Description",
                  "pdp page",
                  userDetail?.UserID
                ))
                : setInfoTitle("")
            }
          >
            <div className="mobile-product-description-heading">
              <img loading="lazy" src={vector_black} alt="" />
              Product Description
            </div>
            {"Compatibility" !== infoTitle && <KeyboardArrowDownSharpIcon />}
            {"Compatibility" === infoTitle && <KeyboardArrowUpSharpIcon />}
          </div>
          <div className="mobile-product-description-details-container">
            {infoTitle === "Compatibility" && (
              <>
                {productDesc &&
                productDesc[0]?.Manufacturer === "GARRARI BIKES" ? (
                  <GarrariBrandDescMobile productDesc={productDesc} />
                ) : (
                  <MobileprodDesc productDesc={productDesc} />
                )}
              </>
            )}
          </div>
        </div>
        {productDesc && productDesc[0]?.Manufacturer === "GARRARI BIKES" && (
          <div className="mobile-product-description-tab-container">
            <div
              className="mobile-product-description-header-div !bg-[#3643ba]/20"
              onClick={() =>
                "Compare" !== infoTitle
                  ? (setInfoTitle("Compare"),
                  logActionClickedFromComponent(
                    "Product Compare",
                    "pdp page",
                    userDetail?.UserID
                  ))
                  : setInfoTitle("")
              }
            >
              <div className="mobile-product-description-heading">
                <img loading="lazy" src={compare_cyan} alt="compare" />
                Compare
              </div>
              {"Compare" !== infoTitle && <KeyboardArrowDownSharpIcon />}
              {"Compare" === infoTitle && <KeyboardArrowUpSharpIcon />}
            </div>
            <div className="mobile-product-description-details-container">
              {infoTitle === "Compare" && <Compare productDesc={productDesc} />}
            </div>
          </div>
        )}
        {/* <div className="mobile-product-insight-tab-container">
        <div
          className="mobile-product-insight-header-div"
          onClick={() =>
            "insight" !== infoTitle
              ? setInfoTitle("insight")
              : setInfoTitle("")
          }
        >
          <div className="mobile-product-insight-heading">
            <img loading="lazy" src={insight_white} alt="" />
            Insights
          </div>
          {"insight" !== infoTitle && <KeyboardArrowDownSharpIcon />}
          {"insight" === infoTitle && <KeyboardArrowUpSharpIcon />}
        </div>
        <div className="mobile-product-insight-details-container">
          {infoTitle === "insight" && (
            <MobileInsight productDesc={productDesc} />
          )}
        </div>
      </div> */}
        <div className="mobile-technical-data-tab-container">
          <div
            className="mobile-technical-data-header-div"
            onClick={() =>
              "Technical Data" !== infoTitle
                ? (setInfoTitle("Technical Data"),
                logActionClickedFromComponent(
                  "Product Technical Data",
                  "pdp page",
                  userDetail?.UserID
                ))
                : setInfoTitle("")
            }
          >
            <div className="mobile-technical-data-heading">
              <img loading="lazy" src={data_black} alt="" />
              Technical Data
            </div>
            {"Technical Data" !== infoTitle && <KeyboardArrowDownSharpIcon />}
            {"Technical Data" === infoTitle && <KeyboardArrowUpSharpIcon />}
          </div>
          <div className="mobile-technical-data-details-container">
            {infoTitle === "Technical Data" && (
              <>
                {productDesc &&
                productDesc[0]?.Manufacturer === "GARRARI BIKES" ? (
                  <GarrariTechnicalData productDesc={productDesc} />
                ) : (
                  <MobileTechData productDesc={productDesc} />
                )}
              </>
            )}
          </div>
        </div>
        <div className="mobile-review-tab-container">
          <div
            className="mobile-review-header-div"
            onClick={() =>
              "Reviews" !== infoTitle
                ? (setInfoTitle("Reviews"),
                logActionClickedFromComponent(
                  "Product Reviews",
                  "pdp page",
                  userDetail?.UserID
                ))
                : setInfoTitle("")
            }
          >
            <div className="mobile-review-heading">
              <img loading="lazy" src={review_black} alt="" />
              Reviews
            </div>
            {"Reviews" !== infoTitle && <KeyboardArrowDownSharpIcon />}
            {"Reviews" === infoTitle && <KeyboardArrowUpSharpIcon />}
          </div>
          <div className="mobile-review-details-container">
            {infoTitle === "Reviews" && <MobileReview />}
          </div>
        </div>
        <div className="mobile-about-brand-tab-container">
          <div
            className="mobile-about-brand-header-div"
            onClick={() =>
              "About Brands" !== infoTitle
                ? (setInfoTitle("About Brands"),
                logActionClickedFromComponent(
                  "Product About Brands",
                  "pdp page",
                  userDetail?.UserID
                ))
                : setInfoTitle("")
            }
          >
            <div className="mobile-about-brand-heading">
              <img loading="lazy" src={informationBlack} alt="" />
              About Brand
            </div>
            {"About Brands" !== infoTitle && <KeyboardArrowDownSharpIcon />}
            {"About Brands" === infoTitle && <KeyboardArrowUpSharpIcon />}
          </div>
          <div className="mobile-about-brand-details-container">
            {infoTitle === "About Brands" && <MobileAboutBrands />}
          </div>
        </div>
      </div>
    </>
  );
}

export default MobileProductTabs;

const PrevArrow = styled.img`
  content: url(${outlineArrow});
  transform: rotate(-180deg);
  position: absolute;
  top: 45%;
  left: 10%;
  width: 2.8vw;
  height: 2.8vw;
  z-index: 10;
  cursor: pointer;
  color: white;
  outline: black;
  transition: font-size 0.5s ease;

  @media (max-width: 500px) {
    display: none; // Hide on mobile
  }
`;

const NextArrow = styled.img`
  content: url(${outlineArrow});
  transform: rotate(0deg);
  position: absolute;
  top: 45%;
  right: 10%;
  width: 2.8vw;
  height: 2.8vw;
  z-index: 10;
  cursor: pointer;
  color: white;
  transition: font-size 0.5s ease;

  @media (max-width: 500px) {
    display: none; // Hide on mobile
  }
`;
