import React, { useState } from "react";
import BulkOrderBreadcrum from "./BulkOrderBreadcrum";
import BulkOrderPriceSummary from "./BulkOrderPriceSummary";
import BrandCart from "./BrandCart";

const BulkOrderCart = ({ step, setStep }) => {
  const [currentStep, setCurrentStep] = useState(1);

  const brandsData = [
    {
      logo: "https://cyclecircle.blr1.cdn.digitaloceanspaces.com/DEVELOPMENT_MANUFACTURE_IMAGE/BIKEPRO1.png",
      name: "BikePro",
      discountMessage: "Add items worth ₹X to get 10% discount",
      orderQuantity: 200,
      orderValue: 19400,
      taxes: 2328,
      totalOrderValue: 21728,
      quantity:50,
      Available_quantity:500,
      items: [
        {
          name: "Full Cartridge Cotterless BB, MTB, Alloy Shaft, 122.5MM",
          image: "https://cyclecircle.blr1.cdn.digitaloceanspaces.com/DEVELOPMENT_PRODUCTS_IMAGE/1718356541224-10.jpg",
        },
        {
          name: "Full Cartridge Cotterless BB, MTB, Alloy Shaft, 122.5MM",
          image: "https://cyclecircle.blr1.cdn.digitaloceanspaces.com/DEVELOPMENT_PRODUCTS_IMAGE/1718356541224-10.jpg",
        },
        {
          name: "Full Cartridge Cotterless BB, MTB, Alloy Shaft, 122.5MM",
          image: "https://cyclecircle.blr1.cdn.digitaloceanspaces.com/DEVELOPMENT_PRODUCTS_IMAGE/1718356541224-10.jpg",
        },
        {
          name: "Full Cartridge Cotterless BB, MTB, Alloy Shaft, 122.5MM",
          image: "https://cyclecircle.blr1.cdn.digitaloceanspaces.com/DEVELOPMENT_PRODUCTS_IMAGE/1718356541224-10.jpg",
        },
        {
          name: "Full Cartridge Cotterless BB, MTB, Alloy Shaft, 122.5MM",
          image: "https://cyclecircle.blr1.cdn.digitaloceanspaces.com/DEVELOPMENT_PRODUCTS_IMAGE/1718356541224-10.jpg",
        },
      ],
    },
    {
      logo: "https://cyclecircle.blr1.cdn.digitaloceanspaces.com/DEVELOPMENT_MANUFACTURE_IMAGE/FUNN.png",
      name: "Flinn",
      discountMessage: "Congratulations! You saved ₹2,000 in your cart value",
      orderQuantity: 200,
      orderValue: 19400,
      taxes: 2328,
      totalOrderValue: 21728,
      quantity:50,
      Available_quantity:500,
      items: [
        {
          name: "Full Cartridge Cotterless BB, MTB, Alloy Shaft, 122.5MM",
          image: "https://cyclecircle.blr1.cdn.digitaloceanspaces.com/DEVELOPMENT_PRODUCTS_IMAGE/1710227938728-1.DSC08936.jpg",
        },
        {
          name: "Full Cartridge Cotterless BB, MTB, Alloy Shaft, 122.5MM",
          image: "https://cyclecircle.blr1.cdn.digitaloceanspaces.com/DEVELOPMENT_PRODUCTS_IMAGE/1710227938728-1.DSC08936.jpg",
        },
        {
          name: "Full Cartridge Cotterless BB, MTB, Alloy Shaft, 122.5MM",
          image: "https://cyclecircle.blr1.cdn.digitaloceanspaces.com/DEVELOPMENT_PRODUCTS_IMAGE/1710227938728-1.DSC08936.jpg",
        },
        {
          name: "Full Cartridge Cotterless BB, MTB, Alloy Shaft, 122.5MM",
          image: "https://cyclecircle.blr1.cdn.digitaloceanspaces.com/DEVELOPMENT_PRODUCTS_IMAGE/1710227938728-1.DSC08936.jpg",
        },
        {
          name: "Full Cartridge Cotterless BB, MTB, Alloy Shaft, 122.5MM",
          image: "https://cyclecircle.blr1.cdn.digitaloceanspaces.com/DEVELOPMENT_PRODUCTS_IMAGE/1710227938728-1.DSC08936.jpg",
        },
      ],
    },
    {
      logo: "https://cyclecircle.blr1.cdn.digitaloceanspaces.com/DEVELOPMENT_MANUFACTURE_IMAGE/FUNN.png",
      name: "Flinn",
      discountMessage: "Congratulations! You saved ₹2,000 in your cart value",
      orderQuantity: 200,
      orderValue: 19400,
      taxes: 2328,
      totalOrderValue: 21728,
      quantity:50,
      Available_quantity:500,
      items: [
        {
          name: "Full Cartridge Cotterless BB, MTB, Alloy Shaft, 122.5MM",
          image: "https://cyclecircle.blr1.cdn.digitaloceanspaces.com/DEVELOPMENT_PRODUCTS_IMAGE/1710227938728-1.DSC08936.jpg",
        },
        {
          name: "Full Cartridge Cotterless BB, MTB, Alloy Shaft, 122.5MM",
          image: "https://cyclecircle.blr1.cdn.digitaloceanspaces.com/DEVELOPMENT_PRODUCTS_IMAGE/1710227938728-1.DSC08936.jpg",
        },
        {
          name: "Full Cartridge Cotterless BB, MTB, Alloy Shaft, 122.5MM",
          image: "https://cyclecircle.blr1.cdn.digitaloceanspaces.com/DEVELOPMENT_PRODUCTS_IMAGE/1710227938728-1.DSC08936.jpg",
        },
        {
          name: "Full Cartridge Cotterless BB, MTB, Alloy Shaft, 122.5MM",
          image: "https://cyclecircle.blr1.cdn.digitaloceanspaces.com/DEVELOPMENT_PRODUCTS_IMAGE/1710227938728-1.DSC08936.jpg",
        },
        {
          name: "Full Cartridge Cotterless BB, MTB, Alloy Shaft, 122.5MM",
          image: "https://cyclecircle.blr1.cdn.digitaloceanspaces.com/DEVELOPMENT_PRODUCTS_IMAGE/1710227938728-1.DSC08936.jpg",
        },
      ],
    },
    {
      logo: "https://cyclecircle.blr1.cdn.digitaloceanspaces.com/DEVELOPMENT_MANUFACTURE_IMAGE/FUNN.png",
      name: "Flinn",
      discountMessage: "Congratulations! You saved ₹2,000 in your cart value",
      orderQuantity: 200,
      orderValue: 19400,
      taxes: 2328,
      totalOrderValue: 21728,
      quantity:50,
      Available_quantity:500,
      items: [
        {
          name: "Full Cartridge Cotterless BB, MTB, Alloy Shaft, 122.5MM",
          image: "https://cyclecircle.blr1.cdn.digitaloceanspaces.com/DEVELOPMENT_PRODUCTS_IMAGE/1710227938728-1.DSC08936.jpg",
        },
        {
          name: "Full Cartridge Cotterless BB, MTB, Alloy Shaft, 122.5MM",
          image: "https://cyclecircle.blr1.cdn.digitaloceanspaces.com/DEVELOPMENT_PRODUCTS_IMAGE/1710227938728-1.DSC08936.jpg",
        },
        {
          name: "Full Cartridge Cotterless BB, MTB, Alloy Shaft, 122.5MM",
          image: "https://cyclecircle.blr1.cdn.digitaloceanspaces.com/DEVELOPMENT_PRODUCTS_IMAGE/1710227938728-1.DSC08936.jpg",
        },
        {
          name: "Full Cartridge Cotterless BB, MTB, Alloy Shaft, 122.5MM",
          image: "https://cyclecircle.blr1.cdn.digitaloceanspaces.com/DEVELOPMENT_PRODUCTS_IMAGE/1710227938728-1.DSC08936.jpg",
        },
        {
          name: "Full Cartridge Cotterless BB, MTB, Alloy Shaft, 122.5MM",
          image: "https://cyclecircle.blr1.cdn.digitaloceanspaces.com/DEVELOPMENT_PRODUCTS_IMAGE/1710227938728-1.DSC08936.jpg",
        },
      ],
    },
    {
      logo: "https://cyclecircle.blr1.cdn.digitaloceanspaces.com/DEVELOPMENT_MANUFACTURE_IMAGE/FUNN.png",
      name: "Flinn",
      discountMessage: "Congratulations! You saved ₹2,000 in your cart value",
      orderQuantity: 200,
      orderValue: 19400,
      taxes: 2328,
      totalOrderValue: 21728,
      quantity:50,
      Available_quantity:500,
      items: [
        {
          name: "Full Cartridge Cotterless BB, MTB, Alloy Shaft, 122.5MM",
          image: "https://cyclecircle.blr1.cdn.digitaloceanspaces.com/DEVELOPMENT_PRODUCTS_IMAGE/1710227938728-1.DSC08936.jpg",
        },
        {
          name: "Full Cartridge Cotterless BB, MTB, Alloy Shaft, 122.5MM",
          image: "https://cyclecircle.blr1.cdn.digitaloceanspaces.com/DEVELOPMENT_PRODUCTS_IMAGE/1710227938728-1.DSC08936.jpg",
        },
        {
          name: "Full Cartridge Cotterless BB, MTB, Alloy Shaft, 122.5MM",
          image: "https://cyclecircle.blr1.cdn.digitaloceanspaces.com/DEVELOPMENT_PRODUCTS_IMAGE/1710227938728-1.DSC08936.jpg",
        },
        {
          name: "Full Cartridge Cotterless BB, MTB, Alloy Shaft, 122.5MM",
          image: "https://cyclecircle.blr1.cdn.digitaloceanspaces.com/DEVELOPMENT_PRODUCTS_IMAGE/1710227938728-1.DSC08936.jpg",
        },
        {
          name: "Full Cartridge Cotterless BB, MTB, Alloy Shaft, 122.5MM",
          image: "https://cyclecircle.blr1.cdn.digitaloceanspaces.com/DEVELOPMENT_PRODUCTS_IMAGE/1710227938728-1.DSC08936.jpg",
        },
      ],
    },
  ];

  return (
    <div className="w-full">
      <div className="w-full">
        <BulkOrderBreadcrum
          currentStep={currentStep}
          setCurrentStep={setCurrentStep}
          setStep={setStep}
          step={step}
        />
        {/* Main Content */}
        <div className="w-full flex flex-col md:flex-row gap-4 p-4 mt-[90px]">
          {/* Cart Product Section */}
          <div className="w-[72%]">
          <BrandCart brands={brandsData} />
          </div>

          {/* Payment Summary Section */}
          <div className="w-[28%] h-fit bg-white-1 p-4 rounded-lg shadow sticky top-[106px]">
            <BulkOrderPriceSummary />
          </div>
        </div>
      </div>
    </div>
  );
};

export default BulkOrderCart;
