import React, { useState, useEffect, useRef } from "react";
import StarRoundedIcon from "@mui/icons-material/StarRounded";
import ChevronLeftSharpIcon from "@mui/icons-material/ChevronLeftSharp";
import PriceRangeSlider from "../components/ListingPage/Filter/PriceRangeSlider";
import BrandSelect from "../components/ListingPage/Filter/FilterHeader/BrandSelect";
import {
  setShippingMode,
  setPriceRange,
  setRatings,
  clearAllFilters,
  setDealerMargins,
  setShopByOffers,
} from "../store/slices/listingPageFilterSlice";
import { useDispatch } from "react-redux";
import {
  setRating,
  setMinPrice,
  setMaxPrice,
  setShipping,
  setDealerMargin,
  setShopByOffer,
} from "../store/slices/allFilterSlice";
import { connect } from "react-redux";
import { Button, SwipeableDrawer } from "@mui/material";

const FilterComponent = ({
  shippingMode,
  priceRange,
  ratings,
  setShippingMode,
  setPriceRange,
  setRatings,
  dealerMargins,
  setDealerMargins,
  shopByOffers,
  setShopByOffers,
  clearAllFilters,
  setFilterOpen,
  onSelect,
  closeBrandSelect,
  previouslySelectedBrands,
  closeMaterialSelect,
  previouslySelectedMaterials,
  handleClearAll,
  filterOpen,
}) => {
  const dispatch = useDispatch();
  // const [filterDatas, setFilterDatas] = useState(filterData.brands);
  const [selectedCategory, setSelectedCategory] = useState("priceRange");
  const [selectedSubCtg, setSelectedSubCtg] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  // const [filteredSubCtg, setFilteredSubCtg] = useState(filterData.brands);

  useEffect(() => {
    if (ratings) {
      dispatch(setRating(parseInt(ratings)));
    }
    if (dealerMargins) {
      dispatch(setDealerMargin(parseInt(dealerMargins)));
    }
    if (shopByOffers) {
      dispatch(setShopByOffer(shopByOffers));
    }
    if (priceRange) {
      const prices = priceRange
        .replace(/₹/g, "") // Remove "₹" from the string
        .split(" - ");

      // Convert the values to numbers
      const minPrice = parseInt(prices[0], 10);
      const maxPrice = parseInt(prices[1], 10);
      dispatch(setMinPrice(minPrice));
      dispatch(setMaxPrice(maxPrice));
    }
    if (shippingMode) {
      dispatch(setShipping(shippingMode));
    }
  }, [
    dispatch,
    ratings,
    dealerMargins,
    shopByOffers,
    priceRange,
    shippingMode,
  ]);

  const handleRatingChange = (e) => {
    setRatings(e.target.value);
  };

  const handleDealerMarginChange = (e) => {
    if (e.target.value === dealerMargins) {
      setDealerMargins(null);
      dispatch(setDealerMargin(0));
    } else {
      setDealerMargins(e.target.value);
    }
  };
  const handleShopByOffers = (e) => {
    if (e.target.value === shopByOffers) {
      setShopByOffers(null);
      dispatch(setShopByOffer(""));
    } else {
      setShopByOffers(e.target.value);
    }
  };

  const selectCategories = (ctg) => {
    setSelectedCategory(ctg);

    setSelectedSubCtg([]);
    setSearchQuery("");
  };
  const filterSelectRef = useRef(null);

  const toggleDrawer = (open) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setFilterOpen(open);
  };

  return (
    <SwipeableDrawer
      anchor="left"
      open={filterOpen}
      onClose={()=>toggleDrawer(false)}
      onOpen={()=>toggleDrawer(true)}
      PaperProps={{
        sx: {
          width: "100vw",
        },
      }}
    >
      <div
        ref={filterSelectRef}
        className="w-full h-full bg-white-1 text-black-1 border-[1.5px] border-gray-400 flex flex-col items-center"
      >
        <div className="w-full flex items-center justify-between p-2">
          <div className="flex items-center">
            <ChevronLeftSharpIcon
              onClick={() => setFilterOpen(false)}
              style={{ fontSize: "24px" }}
            />
            <span className="text-18 font-bold text-blue">Filters</span>
          </div>

          <span
            className="text-peach mr-3 text-14 font-semibold"
            onClick={() => {
              handleClearAll();
              setFilterOpen(false);
            }}
          >
            Clear All
          </span>
        </div>
        <div className="w-full h-[350px] flex py-3 px-0">
          <div className="w-[35%] flex flex-col justify-start">
            <span
              onClick={() => selectCategories("priceRange")}
              className={`w-full py-5 px-3 text-14 whitespace-nowrap ${
                selectedCategory === "priceRange"
                  ? "bg-white-1"
                  : "bg-[#f3eeee]"
              }`}
            >
              Price Range
            </span>
            <span
              onClick={() => selectCategories("customerRating")}
              className={`w-full py-5 px-3 text-14 whitespace-nowrap ${
                selectedCategory === "customerRating"
                  ? "bg-white-1"
                  : "bg-[#f3eeee]"
              }`}
            >
              Customer Rating
            </span>
            <span
              onClick={() => selectCategories("dealerMargins")}
              className={`w-full py-5 px-3 text-14 whitespace-nowrap ${
                selectedCategory === "dealerMargins"
                  ? "bg-white-1"
                  : "bg-[#f3eeee]"
              }`}
            >
              Net Dealer Margin
            </span>
            <span
              onClick={() => selectCategories("showByOffers")}
              className={`w-full py-5 px-3 text-14 whitespace-nowrap ${
                selectedCategory === "showByOffers"
                  ? "bg-white-1"
                  : "bg-[#f3eeee]"
              }`}
            >
              Shop By Offer
            </span>
            <span
              onClick={() => selectCategories("brands")}
              className={`w-full py-5 px-3 text-14 whitespace-nowrap ${
                selectedCategory === "brands" ? "bg-white-1" : "bg-[#f3eeee]"
              }`}
            >
              Brands
            </span>
          </div>
          <div className="w-[65%] flex items-start justify-center mt-3 h-[70vh] overflow-y-scroll">
            {selectedCategory === "priceRange" && (
              <div className="w-full flex flex-col mt-[60px]">
                <PriceRangeSlider />
              </div>
            )}
            {selectedCategory === "customerRating" && (
              <div className="w-full flex flex-col justify-between pl-5 mt-5">
                {[4, 3, 2, 1].map((value) => (
                  <div
                    key={value}
                    className="w-full flex items-center justify-start my-3"
                  >
                    <input
                      type="radio"
                      name="ratings"
                      value={value}
                      checked={parseInt(ratings) === value}
                      onChange={handleRatingChange}
                      className="input-radio"
                    />
                    <div className="flex items-center rounded-sm text-14 font-semibold px-2 text-white-1 bg-[purple] mr-2">
                      {value}
                      <StarRoundedIcon
                        className="text-[goldenrod]"
                        style={{ fontSize: "14px" }}
                      />
                    </div>
                    <span className="text-14 font-semibold text-black-1/70 tracking-wide">
                      {" "}
                      & Above
                    </span>
                  </div>
                ))}
              </div>
            )}
            {selectedCategory === "dealerMargins" && (
              <div className="w-full flex flex-col justify-between pl-5 mt-5">
                {[50, 40, 30, 20, 10].map((value) => (
                  <div
                    key={value}
                    className="w-full flex items-center justify-start my-3"
                  >
                    <input
                      type="radio"
                      name="dealerMargins"
                      value={value}
                      checked={parseInt(dealerMargins) === value}
                      onClick={handleDealerMarginChange}
                      className="input-radio"
                    />
                    <span className="text-14 font-semibold text-black-1/70 tracking-wide">
                      {" "}
                      {value}% &nbsp; & Above{" "}
                    </span>
                  </div>
                ))}
              </div>
            )}

            {selectedCategory === "brands" && (
              <div className="w-full mt-5">
                <BrandSelect
                  onSelect={onSelect}
                  closeBrandSelect={closeBrandSelect}
                  previouslySelectedBrands={previouslySelectedBrands}
                />
              </div>
            )}

            {selectedCategory === "showByOffers" && (
              <div className="w-full flex flex-col justify-between pl-5 mt-5">
                <div className="w-full flex items-center justify-start my-3">
                  <input
                    type="radio"
                    name="showByOffers"
                    value="Exclusive"
                    checked={shopByOffers === "Exclusive"}
                    onClick={handleShopByOffers}
                    className="input-radio"
                  />
                  <span className="text-14 font-semibold text-black-1/70 tracking-wide">
                    {" "}
                    Exclusive
                  </span>
                </div>
                <div className="w-full flex items-center justify-start my-3">
                  <input
                    type="radio"
                    name="showByOffers"
                    value="New Launch"
                    checked={shopByOffers === "New Launch"}
                    onClick={handleShopByOffers}
                    className="input-radio"
                  />
                  <span className="text-14 font-semibold text-black-1/70 tracking-wide">
                    {" "}
                    New Launch
                  </span>
                </div>
                <div className="w-full flex items-center justify-start my-3">
                  <input
                    type="radio"
                    name="showByOffers"
                    value="Price Drop"
                    checked={shopByOffers === "Price Drop"}
                    onClick={handleShopByOffers}
                    className="input-radio"
                  />
                  <span className="text-14 font-semibold text-black-1/70 tracking-wide">
                    {" "}
                    Price Drop
                  </span>
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="w-full flex-center px-3 absolute bottom-5">
          <Button
            variant="contained"
            sx={{
              width: "100%",
              height: "45px",
              background: "#283248",
              fontSize: "16px",
              "&:hover": {
                background: "#283248",
              },
            }}
            onClick={() => setFilterOpen(false)}
          >
            Apply
          </Button>
        </div>
      </div>
    </SwipeableDrawer>
  );
};

const mapStateToProps = (state) => ({
  shippingMode: state.listingPageFilter.shippingMode,
  priceRange: state.listingPageFilter.priceRange,
  ratings: state.listingPageFilter.ratings,
  dealerMargins: state.listingPageFilter.dealerMargins,
  shopByOffers: state.listingPageFilter.shopByOffers,
});

const mapDispatchToProps = {
  setShippingMode,
  setPriceRange,
  setRatings,
  setDealerMargins,
  setShopByOffers,
  clearAllFilters,
};

export default connect(mapStateToProps, mapDispatchToProps)(FilterComponent);
