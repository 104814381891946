import React, { useState } from "react";
import "./ProductAllInfo.css";
import Description from "./Description";
import RatingsReviews from "./Ratings_Reviews";
import TechnicalData from "./TechnicalData";
import vector_black from "../../icons/Vector_black.svg";
import vector_white from "../../icons/Vector_white.svg";
import data_black from "../../icons/data_black.svg";
import data_white from "../../icons/data_white.svg";
import review_black from "../../icons/review_black.svg";
import review_white from "../../icons/review_white.svg";
import insight_white from "../../icons/Insights-outline.svg";
import insight_black from "../../icons/Insights-filled.svg";
import compare_black from "../../assets/compare-black.svg";
import compare_cyan from "../../assets/compare-cyan.svg";
import styled from "styled-components";
import informationMaganta from "../../icons/information-maganta.png";
import informationBlack from "../../icons/information-black.png";
import AboutBrands from "./AboutBrands";
import whatsInBoxImage from "../../assets/Whats-in-the-box.webp";
import specificationImage from "../../assets/Specification.avif";
import outlineArrow from "../../icons/Chevron-Outline-1.svg";
/**Mobile Import */
import Insights from "./Insights";
import Compare from "./Compare";
import GarrariBrandDesc from "./BrandsDetails/GarrariBrandDesc";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import GarrariTechnicalData from "./BrandsDetails/GarrariTechnicalData";
import GarrariTechData from "./BrandsDetails/GarrariTechData";
import { logActionClickedFromComponent } from "../../analytics";
import { useSelector } from "react-redux";
import { selectUser } from "../../features/auth/authSlice";

function ProductAllInfo({ productDesc }) {
  // console.log(productDesc);
  const [infoTitle, setInfoTitle] = useState("Compatibility");
  const userDetail = useSelector(selectUser);

  const banners = [whatsInBoxImage, specificationImage];

  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
  };

  return (
    <>
      {productDesc && productDesc[0]?.Manufacturer === "GARRARI BIKES" && (
        <div className="w-full py-4 bg-white-1 px-5">
          <div className="brand-banner-container !rounded-none">
            <Slider {...settings} className="brand-banner-slider !rounded-none">
              {banners &&
                banners?.map((banner, index) => (
                  <LazyLoadImage
                    key={index}
                    src={banner}
                    alt=""
                    className="w-full h-full !rounded-none"
                    effect="blur"
                    wrapperProps={{
                      // If you need to, you can tweak the effect transition using the wrapper style.
                      style: { transitionDelay: "1s" },
                    }}
                  />
                ))}
            </Slider>
          </div>
        </div>
      )}
      <div className="product-all-info">
        <ul className="product-info-menu">
          <li
            onClick={() => {
              setInfoTitle("Compatibility");
              logActionClickedFromComponent(
                "Product Description",
                "pdp page",
                userDetail?.UserID
              );
            }}
            className={`p-info-list ${
              "Compatibility" === infoTitle ? "active-tabs-compatility" : ""
            }`}
            style={{
              color: `${
                "Compatibility" === infoTitle ? "var(--color-green)" : ""
              }`,
              borderColor: "var(--color-blue)",
              // fontSize: "14px",
            }}
          >
            <img
              loading="lazy"
              className="info-img-compatibility"
              src={`${
                "Compatibility" === infoTitle ? vector_white : vector_black
              }`}
              alt=""
            />
            Product Description
          </li>
          {productDesc && productDesc[0]?.Manufacturer === "GARRARI BIKES" && (
            <li
              onClick={() => {
                setInfoTitle("Compare");
                logActionClickedFromComponent(
                  "Product Compare",
                  "pdp page",
                  userDetail?.UserID
                );
              }}
              className={`p-info-list ${
                "Compare" === infoTitle ? "active-tabs-compare" : ""
              }`}
              style={{
                color: `${"Compare" === infoTitle ? "#3643ba" : ""}`,
                borderColor: "var(--color-blue)",
                // fontSize: "14px",
              }}
            >
              <img
                loading="lazy"
                className="info-img"
                src={`${
                  "Compare" === infoTitle ? compare_black : compare_cyan
                }`}
                alt=""
                style={{ borderRadius: "0", padding: "7px" }}
              />
              Compare
            </li>
          )}
          {/* <li
            onClick={() => {setInfoTitle("Insights");
            logActionClickedFromComponent("Product Insights","pdp page",userDetail?.UserID)}}
            className={`p-info-list ${
              "Insights" === infoTitle ? "active-tabs-insights" : ""
            }`}
            style={{
              color: `${"Insights" === infoTitle ? "var(--color-peach)" : ""}`,
              borderColor: "var(--color-blue)",
            
            }}
          >
           
            <img loading="lazy"
              className="info-img-insights"
              src={`${
                "Insights" === infoTitle ? insight_black : insight_white
              }`}
              alt=""
            />
            Insights
          </li> */}
          <li
            onClick={() => {
              setInfoTitle("Technical Data");
              logActionClickedFromComponent(
                "Product Technical Data",
                "pdp page",
                userDetail?.UserID
              );
            }}
            className={`p-info-list ${
              "Technical Data" === infoTitle ? "active-tabs-technical" : ""
            }`}
            style={{
              color: `${
                "Technical Data" === infoTitle ? "var(--color-blue)" : ""
              }`,
              borderColor: "var(--color-blue)",
              // fontSize: "14px",
            }}
          >
            <img
              loading="lazy"
              className="info-img"
              src={`${
                "Technical Data" === infoTitle ? data_white : data_black
              }`}
              alt=""
            />
            Technical Data
          </li>
          {/* <li
            onClick={() => {setInfoTitle("How To");
            logActionClickedFromComponent("Product How To","pdp page",userDetail?.UserID)}}
            className={`p-info-list ${
              "How To" === infoTitle ? "active-tabs-how" : ""
            }`}
            style={{
              color: `${"How To" === infoTitle ? "var(--color-peach)" : ""}`,
              borderColor: "var(--color-blue)",
              fontSize: "14px",
            }}
          >
            <img loading="lazy" className="info-img"  src={`${
                "How To" === infoTitle ? play_white : play_black
              }`} alt="" />
            How To
          </li> */}
          <li
            onClick={() => {
              setInfoTitle("About Brands");
              logActionClickedFromComponent(
                "Product About Brands",
                "pdp page",
                userDetail?.UserID
              );
            }}
            className={`p-info-list ${
              "About Brands" === infoTitle ? "active-tabs-About-Brands" : ""
            }`}
            style={{
              color: `${
                "About Brands" === infoTitle ? "rgba(20, 91, 104, 1)" : ""
              }`,
              borderColor: "var(--color-blue)",
              // fontSize: "14px",
            }}
          >
            <img
              loading="lazy"
              className="info-img"
              src={`${
                "About Brands" === infoTitle
                  ? informationMaganta
                  : informationBlack
              }`}
              alt=""
              style={{ borderRadius: "0", padding: "7px" }}
            />
            About Brand
          </li>
          <li
            onClick={() => {
              setInfoTitle("Reviews");
              logActionClickedFromComponent(
                "Product Reviews",
                "pdp page",
                userDetail?.UserID
              );
            }}
            className={`p-info-list ${
              "Reviews" === infoTitle ? "active-tabs-reviews" : ""
            }`}
            style={{
              color: `${"Reviews" === infoTitle ? "var(--color-purple)" : ""}`,
              borderColor: "var(--color-blue)",
              // fontSize: "14px",
            }}
          >
            <img
              loading="lazy"
              className="info-img"
              src={`${"Reviews" === infoTitle ? review_white : review_black}`}
              alt=""
            />
            Reviews
          </li>
        </ul>
        {infoTitle === "Insights" && (
          <div
            className={`info-container ${
              infoTitle === "Insights" ? "active" : ""
            }`}
          >
            <Insights productDesc={productDesc} />
          </div>
        )}
        {infoTitle === "About Brands" && (
          <div
            className={`info-container ${
              infoTitle === "About Brands" ? "active" : ""
            }`}
          >
            <AboutBrands />
          </div>
        )}
        {infoTitle === "Compare" && (
          <div
            className={`info-container ${
              infoTitle === "Compare" ? "active" : ""
            }`}
          >
            <Compare productDesc={productDesc} />
          </div>
        )}
        {infoTitle === "Reviews" && (
          <div
            className={`info-container ${
              infoTitle === "Reviews" ? "active" : ""
            }`}
          >
            <RatingsReviews />
          </div>
        )}
        {infoTitle === "Technical Data" && (
          <div
            className={`info-container ${
              infoTitle === "Technical Data" ? "active" : ""
            }`}
          >
            {productDesc && productDesc[0]?.Manufacturer === "GARRARI BIKES" ? (
              <GarrariTechnicalData productDesc={productDesc} />
            ) : (
              <TechnicalData productDesc={productDesc} />
            )}
          </div>
        )}
        {/* {infoTitle === "How To" && (
          <div
            className={`info-container ${
              infoTitle === "How To" ? "active" : ""
            }`}
          >
            <Discover productDesc={productDesc}/>
          </div>
        )} */}
        {infoTitle === "Compatibility" && (
          <div
            className={`info-container ${
              infoTitle === "Compatibility" ? "active" : ""
            }`}
          >
            {productDesc && productDesc[0]?.Manufacturer === "GARRARI BIKES" ? (
              <GarrariBrandDesc productDesc={productDesc} />
            ) : (
              <Description productDesc={productDesc} />
            )}
          </div>
        )}
      </div>
    </>
  );
}

export default ProductAllInfo;

const PrevArrow = styled.img`
  content: url(${outlineArrow});
  transform: rotate(-180deg);
  position: absolute;
  top: 45%;
  left: 10%;
  width: 2.8vw;
  height: 2.8vw;
  z-index: 10;
  cursor: pointer;
  color: white;
  outline: black;
  transition: font-size 0.5s ease;

  @media (max-width: 500px) {
    display: none; // Hide on mobile
  }
`;

const NextArrow = styled.img`
  content: url(${outlineArrow});
  transform: rotate(0deg);
  position: absolute;
  top: 45%;
  right: 10%;
  width: 2.8vw;
  height: 2.8vw;
  z-index: 10;
  cursor: pointer;
  color: white;
  transition: font-size 0.5s ease;

  @media (max-width: 500px) {
    display: none; // Hide on mobile
  }
`;
