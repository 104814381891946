import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import {
  fetchAllCategoryProductsAsync,
  fetchThroughSearchAsync,
  setSubCategories,
} from "../../features/listingPage/listingPageSlice";
import { setSelectedSubcategory } from "../../store/slices/subCategorySlice";
import { setBreadcrumb } from "../../store/slices/breadCrumbsSlice";
import { logActionClickedFromComponent } from "../../analytics";
import { selectUser } from "../../features/auth/authSlice";

function MobilePopularSearch() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userDetail = useSelector(selectUser)

  const handleSearchNavigate = (value) => {
    dispatch(fetchThroughSearchAsync(value.trim()));
    dispatch(setSubCategories(null));
    dispatch(setSelectedSubcategory(null));
    dispatch(
      setBreadcrumb({
        categoryID: 0,
        categoryName: "",
        subCategoryID: 0,
        subCategoryName: "",
        ProductID: 0,
        productName: "",
      })
    );
    navigate(`/search/${value}`);
  };

  const handleCategoryNavigate = (cat_id, cat_name) => {
    logActionClickedFromComponent(cat_name,"Popular Search",userDetail?.UserID)
    navigate(`/listing-page/${cat_id}/${cat_name}`);
    dispatch(fetchAllCategoryProductsAsync(cat_id));
  };

  return (
    <div className="w-full flex flex-col items-start justify-start p-3 bg-blue-light text-white">
      <div className="w-full flex flex-col items-start">
        <h3 className="mb-1 text-24 font-extrabold tracking-wider">
          Popular Searches:
        </h3>
        <div className="w-full flex items-center justify-start flex-wrap text-18 font-light">
          <span
            onClick={() => handleSearchNavigate("parts")}
            className="mx-2 text-12 cursor-pointer font-semibold hover:underline"
          >
            Bicycle parts
          </span>{" "}
          |{" "}
          <span
            onClick={() => handleSearchNavigate("accessories")}
            className="mx-2 text-12 cursor-pointer font-semibold hover:underline whitespace-break-spaces"
          >
            Bike accessories
          </span>{" "}
          |{" "}
          <span
            onClick={() => handleSearchNavigate("maintenance")}
            className="mx-2 text-12 cursor-pointer font-semibold hover:underline"
          >
            Bike maintenance
          </span>{" "}
          |{" "}
          <span
            onClick={() => handleSearchNavigate("lights")}
            className="mx-2 text-12 cursor-pointer font-semibold hover:underline"
          >
            Bike Lights
          </span>{" "}
          |
          <span
            onClick={() => handleSearchNavigate("helmets")}
            className="mx-2 text-12 cursor-pointer font-semibold hover:underline"
          >
            Helmets
          </span>{" "}
          |
          <span
            onClick={() => handleSearchNavigate("maintenance")}
            className="mx-2 text-12 cursor-pointer font-semibold hover:underline"
          >
            Cycling Tools
          </span>{" "}
          |{" "}
          <span
            onClick={() => handleSearchNavigate("wheels")}
            className="mx-2 text-12 cursor-pointer font-semibold hover:underline"
          >
            MTB wheelset
          </span>{" "}
          |
          <span
            onClick={() => handleSearchNavigate("clothing")}
            className="mx-2 text-12 cursor-pointer font-semibold hover:underline"
          >
            Cycling Clothes
          </span>{" "}
          |
          <span
            onClick={() => handleSearchNavigate("parts")}
            className="mx-2 text-12 cursor-pointer font-semibold hover:underline"
          >
            Spare parts
          </span>{" "}
          |{" "}
          <span
            onClick={() => handleSearchNavigate("funn")}
            className="mx-2 text-12 cursor-pointer font-semibold hover:underline"
          >
            Best MTB parts
          </span>
          |{" "}
          <span
            onClick={() => handleSearchNavigate("granite")}
            className="mx-2 text-12 cursor-pointer font-semibold hover:underline"
          >
            Mini Tools
          </span>
          |{" "}
          <span
            onClick={() => handleSearchNavigate("drivetrain")}
            className="mx-2 text-12 cursor-pointer font-semibold hover:underline"
          >
            Bicycle gears
          </span>
          <span
            onClick={() => handleCategoryNavigate(16, "KIDS")}
            className="mx-2 text-12 cursor-pointer font-semibold hover:underline"
          >
            Kids Bikes
          </span>
          |{" "}
          <span
            onClick={() => handleCategoryNavigate(17, "MTB")}
            className="mx-2 text-12 cursor-pointer font-semibold hover:underline"
          >
            MTB Bikes
          </span>
        </div>
      </div>
      <div className="w-full flex flex-col items-start mt-[3vh] ml-0 text-[0.65vw]">
        <div className="flex flex-col items-start justify-between">
          <h3 className="  text-16 font-bold tracking-wide text-gold mx-0 my-0">
            Cockpit:
          </h3>
          <div className="w-full flex items-center justify-start flex-wrap text-18 my-0 font-light">
            <span
              className="mx-2 text-12 cursor-pointer font-semibold hover:underline"
              onClick={() => handleSearchNavigate("handlebar")}
            >
              Handlebar
            </span>{" "}
            |
            <span
              className="mx-2 text-12 cursor-pointer font-semibold hover:underline"
              onClick={() => handleSearchNavigate("stem")}
            >
              Stem
            </span>{" "}
            |
            <span
              className="mx-2 text-12 cursor-pointer font-semibold hover:underline"
              onClick={() => handleSearchNavigate("seatpost")}
            >
              Seatpost
            </span>{" "}
            |{" "}
            <span
              className="mx-2 text-12 cursor-pointer font-semibold hover:underline"
              onClick={() => handleSearchNavigate("grips")}
            >
              Grips
            </span>{" "}
            |
            <span
              className="mx-2 text-12 cursor-pointer font-semibold hover:underline"
              onClick={() => handleSearchNavigate("headset")}
            >
              Headset
            </span>{" "}
            |
            <span
              className="mx-2 text-12 cursor-pointer font-semibold hover:underline"
              onClick={() => handleSearchNavigate("pedal")}
            >
              Pedal
            </span>
          </div>
        </div>
        <div className="flex flex-col items-start justify-between">
          <h3 className="  text-16 font-bold tracking-wide text-gold mx-0 my-0">
            Drivetrain:
          </h3>
          <div className="w-full flex items-center justify-start flex-wrap text-18 my-0 font-light">
            <span
              className="mx-2 text-12 cursor-pointer font-semibold hover:underline"
              onClick={() => handleSearchNavigate("bottom bracket")}
            >
              Bottom Bracket
            </span>{" "}
            |{" "}
            <span
              className="mx-2 text-12 cursor-pointer font-semibold hover:underline"
              onClick={() => handleSearchNavigate("chain")}
            >
              Chain
            </span>{" "}
            |
            <span
              className="mx-2 text-12 cursor-pointer font-semibold hover:underline"
              onClick={() => handleSearchNavigate("crankset")}
            >
              Crankset
            </span>{" "}
            |{" "}
            <span
              className="mx-2 text-12 cursor-pointer font-semibold hover:underline"
              onClick={() => handleSearchNavigate("cassette")}
            >
              Cassette and freely Brake Rotor
            </span>{" "}
            |
            <span
              className="mx-2 text-12 cursor-pointer font-semibold hover:underline"
              onClick={() => handleSearchNavigate("derailluer")}
            >
              Derailluer
            </span>{" "}
            |
            <span
              className="mx-2 text-12 cursor-pointer font-semibold hover:underline"
              onClick={() => handleSearchNavigate("shifter")}
            >
              Shifter
            </span>{" "}
            |
            <span
              className="mx-2 text-12 cursor-pointer font-semibold hover:underline"
              onClick={() => handleSearchNavigate("chainring")}
            >
              Chainring
            </span>
          </div>
        </div>
        <div className="flex flex-col items-start justify-between">
          <h3 className="  text-16 font-bold tracking-wide text-gold mx-0 my-0">
            Hydration:
          </h3>
          <div className="w-full flex items-center justify-start flex-wrap text-18 my-0 font-light">
            <span
              className="mx-2 text-12 cursor-pointer font-semibold hover:underline"
              onClick={() => handleSearchNavigate("bottles")}
            >
              Bottles
            </span>{" "}
            |{" "}
            <span
              className="mx-2 text-12 cursor-pointer font-semibold hover:underline"
              onClick={() => handleSearchNavigate("bags")}
            >
              Hydration Bags
            </span>{" "}
            |{" "}
            <span
              className="mx-2 text-12 cursor-pointer font-semibold hover:underline"
              onClick={() => handleSearchNavigate("bottle holders")}
            >
              Bottle Holders
            </span>{" "}
            |{" "}
          </div>
        </div>
        <div className="flex flex-col items-start justify-between">
          <h3 className="  text-16 font-bold tracking-wide text-gold mx-0 my-0">
            Helmets:
          </h3>
          <div className="w-full flex items-center justify-start flex-wrap text-18 my-0 font-light">
            <span
              className="mx-2 text-12 cursor-pointer font-semibold hover:underline"
              onClick={() => handleSearchNavigate("mtb")}
            >
              MTB
            </span>{" "}
            |{" "}
            <span
              className="mx-2 text-12 cursor-pointer font-semibold hover:underline"
              onClick={() => handleSearchNavigate("road")}
            >
              Road
            </span>{" "}
            |{" "}
            <span
              className="mx-2 text-12 cursor-pointer font-semibold hover:underline"
              onClick={() => handleSearchNavigate("city")}
            >
              City
            </span>{" "}
            |{" "}
            <span
              className="mx-2 text-12 cursor-pointer font-semibold hover:underline"
              onClick={() => handleSearchNavigate("enduro")}
            >
              Enduro
            </span>
          </div>
        </div>
        <div className="flex flex-col items-start justify-between">
          <h3 className="  text-16 font-bold tracking-wide text-gold mx-0 my-0">
            Wheels and Tires:
          </h3>
          <div className="w-full flex items-center justify-start flex-wrap text-18 my-0 font-light">
            <span
              className="mx-2 text-12 cursor-pointer font-semibold hover:underline"
              onClick={() => handleSearchNavigate("wheel")}
            >
              wheels
            </span>{" "}
            |{" "}
            <span
              className="mx-2 text-12 cursor-pointer font-semibold hover:underline"
              onClick={() => handleSearchNavigate("rim")}
            >
              Rim
            </span>{" "}
            |{" "}
            <span
              className="mx-2 text-12 cursor-pointer font-semibold hover:underline"
              onClick={() => handleSearchNavigate("hub")}
            >
              Hub
            </span>{" "}
            |{" "}
            <span
              className="mx-2 text-12 cursor-pointer font-semibold hover:underline"
              onClick={() => handleSearchNavigate("axles")}
            >
              Axles & QR
            </span>
          </div>
        </div>
        <div className="flex flex-col items-start justify-between">
          <h3 className="  text-16 font-bold tracking-wide text-gold mx-0 my-0">
            Cable and Housing:
          </h3>
          <div className="w-full flex items-center justify-start flex-wrap text-18 my-0 font-light">
            <span
              className="mx-2 text-12 cursor-pointer font-semibold hover:underline"
              onClick={() => handleSearchNavigate("brake housing")}
            >
              Brake housing
            </span>{" "}
            |{" "}
            <span
              className="mx-2 text-12 cursor-pointer font-semibold hover:underline"
              onClick={() => handleSearchNavigate("brake wire")}
            >
              Brake wire
            </span>{" "}
            |{" "}
          </div>
        </div>
        <div className="flex flex-col items-start justify-between">
          <h3 className="  text-16 font-bold tracking-wide text-gold mx-0 my-0">
            Brakes and Brake Parts:
          </h3>
          <div className="w-full flex items-center justify-start flex-wrap text-18 my-0 font-light">
            <span
              className="mx-2 text-12 cursor-pointer font-semibold hover:underline"
              onClick={() => handleSearchNavigate("lever")}
            >
              Brake Lever
            </span>{" "}
            |{" "}
            <span
              className="mx-2 text-12 cursor-pointer font-semibold hover:underline"
              onClick={() => handleSearchNavigate("disc brake")}
            >
              Disc Brake
            </span>{" "}
            |{" "}
            <span
              className="mx-2 text-12 cursor-pointer font-semibold hover:underline"
              onClick={() => handleSearchNavigate("rotor")}
            >
              Disc Rotor
            </span>{" "}
            |{" "}
          </div>
        </div>
        <div className="flex flex-col items-start justify-between">
          <h3 className="  text-16 font-bold tracking-wide text-gold mx-0 my-0">
            Lights:
          </h3>
          <div className="w-full flex items-center justify-start flex-wrap text-18 my-0 font-light">
            <span
              className="mx-2 text-12 cursor-pointer font-semibold hover:underline"
              onClick={() => handleSearchNavigate("front light")}
            >
              Front Light
            </span>{" "}
            |{" "}
            <span
              className="mx-2 text-12 cursor-pointer font-semibold hover:underline"
              onClick={() => handleSearchNavigate("rear light")}
            >
              Rear Light
            </span>{" "}
            |{" "}
            <span
              className="mx-2 text-12 cursor-pointer font-semibold hover:underline"
              onClick={() => handleSearchNavigate("light")}
            >
              Others
            </span>{" "}
            |{" "}
          </div>
        </div>
        <div className="flex flex-col items-start justify-between">
          <h3 className="  text-16 font-bold tracking-wide text-gold mx-0 my-0">
            Small Parts:
          </h3>
          <div className="w-full flex items-center justify-start flex-wrap text-18 my-0 font-light">
            <span
              className="mx-2 text-12 cursor-pointer font-semibold hover:underline"
              onClick={() => handleSearchNavigate("chain guide")}
            >
              Chain Guide
            </span>{" "}
            |{" "}
            <span
              className="mx-2 text-12 cursor-pointer font-semibold hover:underline"
              onClick={() => handleSearchNavigate("bearing and spacers")}
            >
              Bearing & Spacers
            </span>{" "}
            |{" "}
            <span
              className="mx-2 text-12 cursor-pointer font-semibold hover:underline"
              onClick={() => handleSearchNavigate("clamps")}
            >
              Clamps
            </span>{" "}
            |{" "}
          </div>
        </div>
        <div className="flex flex-col items-start justify-between">
          <h3 className="  text-16 font-bold tracking-wide text-gold mx-0 my-0">
            Pumps & Inflation:
          </h3>
          <div className="w-full flex items-center justify-start flex-wrap text-18 my-0 font-light">
            <span
              className="mx-2 text-12 cursor-pointer font-semibold hover:underline"
              onClick={() => handleSearchNavigate("air pump")}
            >
              Air Pump
            </span>{" "}
            |{" "}
            <span
              className="mx-2 text-12 cursor-pointer font-semibold hover:underline"
              onClick={() => handleSearchNavigate("cartridges")}
            >
              Cartridges
            </span>{" "}
            |{" "}
          </div>
        </div>
        <div className="flex flex-col items-start justify-between">
          <h3 className="  text-16 font-bold tracking-wide text-gold mx-0 my-0">
            Stands & Storage:
          </h3>
          <div className="w-full flex items-center justify-start flex-wrap text-18 my-0 font-light">
            <span
              className="mx-2 text-12 cursor-pointer font-semibold hover:underline"
              onClick={() => handleSearchNavigate("stands")}
            >
              Stands
            </span>{" "}
            |{" "}
            <span
              className="mx-2 text-12 cursor-pointer font-semibold hover:underline"
              onClick={() => handleSearchNavigate("storage")}
            >
              Storage
            </span>{" "}
            |{" "}
            <span
              className="mx-2 text-12 cursor-pointer font-semibold hover:underline"
              onClick={() => handleSearchNavigate("mounts")}
            >
              Mounts
            </span>{" "}
            |{" "}
          </div>
        </div>
        <div className="flex flex-col items-start justify-between">
          <h3 className="  text-16 font-bold tracking-wide text-gold mx-0 my-0">
            Clothing:
          </h3>
          <div className="w-full flex items-center justify-start flex-wrap text-18 my-0 font-light">
            <span
              className="mx-2 text-12 cursor-pointer font-semibold hover:underline"
              onClick={() => handleSearchNavigate("cycling socks")}
            >
              Cycling Socks
            </span>{" "}
            |{" "}
            <span
              className="mx-2 text-12 cursor-pointer font-semibold hover:underline"
              onClick={() => handleSearchNavigate("tshirts")}
            >
              Tshirts
            </span>{" "}
            |{" "}
          </div>
        </div>
        <div className="flex flex-col items-start justify-between">
          <h3 className="  text-16 font-bold tracking-wide text-gold mx-0 my-0">
            Sealants & Tubeless Kits:
          </h3>
          <div className="w-full flex items-center justify-start flex-wrap text-18 my-0 font-light">
            <span
              className="mx-2 text-12 cursor-pointer font-semibold hover:underline"
              onClick={() => handleSearchNavigate("kit and valves")}
            >
              Kit & Valves
            </span>{" "}
            |{" "}
            <span
              className="mx-2 text-12 cursor-pointer font-semibold hover:underline"
              onClick={() => handleSearchNavigate("rim tapes")}
            >
              Rim Tapes
            </span>{" "}
            |{" "}
          </div>
        </div>
        <div className="flex flex-col items-start justify-between">
          <h3 className="  text-16 font-bold tracking-wide text-gold mx-0 my-0">
            Tools:
          </h3>
          <div className="w-full flex items-center justify-start flex-wrap text-18 my-0 font-light">
            <span
              className="mx-2 text-12 cursor-pointer font-semibold hover:underline"
              onClick={() => handleSearchNavigate("mini tools")}
            >
              Mini Tools
            </span>{" "}
            |{" "}
          </div>
        </div>
        <div className="flex flex-col items-start justify-between">
          <h3 className="  text-16 font-bold tracking-wide text-gold mx-0 my-0">
            Fork:
          </h3>
          <div className="w-full flex items-center justify-start flex-wrap text-18 my-0 font-light">
            <span
              className="mx-2 text-12 cursor-pointer font-semibold hover:underline"
              onClick={() => handleSearchNavigate("fork")}
            >
              Suspension Fork
            </span>{" "}
            |{" "}
          </div>
        </div>
      </div>
    </div>
  );
}

export default MobilePopularSearch;
