import ReactGA from "react-ga4";
import { GOOGLE_ANALYTICS_MEASUREMENT_ID, REACT_APP_GTM_ID } from "./config";
import TagManager from "react-gtm-module";

// Initialize Google Analytics 4 (GA4)*********************************************************************8
export const initializeGA = () => {
  ReactGA.initialize(GOOGLE_ANALYTICS_MEASUREMENT_ID, { debug: true });

};

// Initialize Google Tag Manager (GTM)*****************************************************************************
const tagManagerArgs = {
  gtmId: REACT_APP_GTM_ID,
};

export const initializeGTA = () => {
  TagManager.initialize(tagManagerArgs);
};

// Send events to dataLayer (used for GTM event tracking)************************************************************************
export const tagEvent = (eventName, btn_id, btn_text) => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: eventName,
    button_id: btn_id,
    button_text: btn_text,
  });
};

// Track button click events using ReactGA************************************************************************************
export const handleButtonClick = (buttonId, buttonText) => {
  ReactGA.event({
    category: "Button Click",
    action: "click",
    label: buttonId, // Button ID (e.g., "add-cart", "buy-now-btn")
    value: buttonText, // Button text (e.g., "Add to Cart", "Buy Now")
  });
};

// Log a page view event*********************************************************************************************************************
export const logPageView = (pagePath, userId) => {
  if (userId) {
    ReactGA.set({ userId: userId });
  }
  ReactGA.send({ hitType: "pageview", page: pagePath });
};

// Set the logged-in user for tracking purposes**************************************************************************************
export const setLoggedInUser = (userId) => {
  ReactGA.set({ userId: userId });
};

// Log custom events with category, action, label, and value***************************************************************
export const logEvent = (category, action, label, value, userId) => {
  if (userId) {
    ReactGA.set({ userId: userId });
  }
  ReactGA.event({
    category, // Category (e.g., "Button", "Form")
    action, // Action (e.g., "Click", "Submit")
    label, // Label (e.g., "Add to Cart", "Submit Form")
    value, // Value (e.g., number of items, form ID)
  });
};

// Example: Log button click*************************************************************************************
export const logButtonClick = (buttonName) => {
  logEvent("Button", "Click", buttonName, null); // Log button click event
};

// Track add-to-cart action********************************************************************************
export const logAddToCart = (productName) => {
  logEvent("Add to Cart", "Add to Cart", productName, null); // Track "Add to Cart" event
};

export const logBuyNow = (productName) => {
    logEvent("Buy Now", "Buy Now", productName, null); // Track "Add to Cart" event
  };

// Track product checkout*******************************************************************************
export const logCheckout = (cartValue, cartItemsCount) => {
  logEvent("Cart Page", "Checkout", `Cart Value: ${cartValue}`, cartItemsCount); // Track checkout event
};

// Track successful payment****************************************************************************
export const logPaymentSuccess = (paymentAmount, paymentMethod) => {
  logEvent(
    "Ecommerce",
    "Payment Success",
    `Amount: ${paymentAmount}, Method: ${paymentMethod}`,
    paymentAmount
  );
};

// Track failed payment*******************************************************************************
export const logPaymentFailure = (paymentAmount, errorMessage) => {
  logEvent(
    "Ecommerce",
    "Payment Failure",
    `Amount: ${paymentAmount}, Error: ${errorMessage}`,
    paymentAmount
  );
};

// Track user login**********************************************************************************
export const logUserLogin = (userId) => {
  logEvent("User", "Login", `User ID: ${userId}`, null); // Track user login event
};

// Track user logout*****************************************************************************************
export const logUserLogout = (userId) => {
  logEvent("User", "Logout", `User ID: ${userId}`, null); // Track user logout event
};

// Track search query *************************************************************************************
export const logSearchQuery = (searchQuery, userId) => {
  logEvent("Search", "Query", searchQuery, null, userId); // Track search query event
};

// Track product view *************************************************************************************
export const logProductView = (productId, productName, userId) => {
  logEvent(
    "Product Page Visit",
    "Product View",
    `Product ID: ${productId}, Name: ${productName}`,
    null,
    userId
  );
};

// Track when user leave at specific Field while Onboarding *******************************************************

// Track when a user leaves a specific text field in the form
export const trackFieldLeaveEvent = (fieldName) => {
  if (!fieldName) return;
  ReactGA.event({
    category: "Form Interaction",
    action: "Leave",
    label: `User left field: ${fieldName}`,
  });
};

// Call this function when a user leaves the page
export const handlePageUnload = (focusedField) => {
  trackFieldLeaveEvent(focusedField);
};

// Button clicked from component ***********************************************************************************************
export const logActionClickedFromComponent = (prop, component, userId) => {
  if (userId) {
    ReactGA.set({ userId: userId });
  }
  logComponentClicked(component);
  ReactGA.event({
    category: `${prop} Clicked`,
    action: `${prop} Clicked`,
    label: `${prop} clicked from ${component}`,
  });
};

// Log component click events using ReactGA ***********************************************************************************
export const logComponentClicked = (componentName) => {
  ReactGA.event({
    category: "Component Opened",
    action: `${componentName} Opened`,
    label: componentName,
  });
};

// Form Submittion Successfully
export const logOnboardingSuccess = (business_name) => {
  ReactGA.event({
    category: `Form Interaction`,
    action: `onboarding Success`,
    label: `${business_name} Onboarded Successfully`,
  });
};

// Form Submittion Successfully
export const logOnboardingFailed = (business_name) => {
    ReactGA.event({
      category: `Form Interaction`,
      action: `onboarding Failed`,
      label: `${business_name} Onboarded Failed`,
    });
  };

// Verify GST , PAN, Aadhar,Phone Number
export const logKYCVerification = (kyc_name, kyc_value) => {
  ReactGA.event({
    category: `Form Interaction`,
    action: `${kyc_name} Verification`,
    label: `${kyc_value} Verified Successfully`,
  });
};

// Verification Failed GST , PAN, Aadhar,Phone Number
export const logKYCVerificationFailed = (kyc_name, kyc_value, phone_number) => {
  ReactGA.event({
    category: `Form Interaction`,
    action: `${kyc_name} Verification`,
    label: `${kyc_value} verification Failed for ${phone_number}`,
  });
};
