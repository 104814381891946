import React, { useState, useRef, useEffect, useCallback } from "react";
import { Link, useNavigate } from "react-router-dom";
import { connect } from "react-redux";

import { setOTPVerified } from "../../../store/slices/userSlice";
import { API_BASE_URL } from "../../../config";
import { toggleOverlay } from "../../../store/slices/headerSlice";
import {
  fetchUserAsync,
  setToken,
  setPhoneNumbers,
  setSaveData,
} from "../../../features/auth/authSlice";
import { useDispatch } from "react-redux";
import { setLoginMode } from "../../../store/slices/userSlice";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import toast from "react-hot-toast";
import { logUserLogin } from "../../../analytics";

function AutoOTPVerification({
  user,
  phoneNumber,
  setPhoneNumber,
  setLoginMode,
  setOTPVerified,
  toggleOverlay,
  setToken,
  setPhoneNumbers,
  setSaveData,
  handleCloseTab,
}) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const { loginMode } = user;
  const handleCloses = () => {
    setOpen(false);
  };
  const handleOpens = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setLoading(false);
  };
  const handleOpen = () => {
    setLoading(true);
  };
  const [otp, setOTP] = useState(["", "", "", "", "", ""]);
  const inputRefs = useRef(
    Array.from({ length: 6 }).map(() => React.createRef())
  );

  const handleKeyDown = (index, value, e) => {
    // Check if backspace is pressed and the current input is empty
    if (e.keyCode === 8 && index > 0 && value === "") {
      inputRefs.current[index - 1].current.focus();
    } else if (e.keyCode === 8 && index > 0 && value !== "") {
      inputRefs.current[index].current.focus();
    }
  };

  const handleInputChange = useCallback(
    (index, value, e) => {
      // Accept only numeric values
      const numericValue = value?.replace(/\D/g, "");

      const newOTP = [...otp];
      newOTP[index] = numericValue?.slice(-1); // Only take the last digit

      setOTP(newOTP);
      // Move to the next input when entering a digit
      if (numericValue !== "" && index < 5 && inputRefs?.current[index + 1]) {
        inputRefs?.current[index + 1]?.current?.focus();
      }
    },
    [otp]
  );
  // HANDLE OTP PASTE
  const handleOTPPaste = (e) => {
    e.preventDefault();
    const clipboardData = e.clipboardData.getData("text/plain");
    const pastedDigits = clipboardData.replace(/\D/g, "").slice(0, 6); // Filter out non-numeric characters and take the first 6 digits
    const newOTP = [...otp];
    pastedDigits.split("").forEach((digit, index) => {
      newOTP[index] = digit;
    });
    setOTP(newOTP);
    inputRefs.current[5].current.focus();
  };

  const handleVerifyOTP = async () => {
    const enteredOTP = otp.join("");

    if (!enteredOTP || enteredOTP.length < 6) {
      toast.error('Enter OTP correctly')
    } else {
      handleOpen();
      if (loginMode === "login") {
        try {
          const response = await fetch(
            `${API_BASE_URL}login_number_otp/${phoneNumber}/${enteredOTP}`,
            {
              method: "GET",
              headers: {
                "Content-Type": "application/json",
              },
            }
          );

          const data = await response.json();
          if (response.ok) {
            handleClose();
            handleCloseTab();
            document.cookie = `UserID=${data.UserID}`;
            document.cookie = `token=${data.token}`;
            logUserLogin(data?.UserID);
            await dispatch(setToken(data.token));
            dispatch(fetchUserAsync(data.UserID));
            setPhoneNumber(null);
          } else {
            handleClose();
            console.error("API request failed:", response.statusText);
            toast.error('Invalid OTP')
          }
        } catch (error) {
          handleClose();
          console.error(
            "An error occurred during the API request:",
            error.message
          );
          toast.error('Error')
        }
      } else {
        try {
          const response = await fetch(
            `${API_BASE_URL}phone_auth_otp/${phoneNumber}/${enteredOTP}`,
            {
              method: "GET",
              headers: {
                "Content-Type": "application/json",
              },
            }
          );

          const data = await response.json();
          if (response.ok) {
            handleClose();
            setSaveData(data?.data && data?.data);
            dispatch(setPhoneNumbers(phoneNumber));
            navigate(`/sign-up`);
            setPhoneNumber(null);
            setLoginMode("login");
          } else {
            handleClose();
            console.error("API request failed:", response.statusText);
            toast.error('Invalid OTP')
          }
        } catch (error) {
          handleClose();
          console.error(
            "An error occurred during the API request:",
            error.message
          );
        }
      }
      handleOpen();
      setOTPVerified(true);
      handleClose();
    }
  };

  useEffect(() => {
    const enteredOTP = otp.join("");
    if (enteredOTP.length === 6) {
      handleVerifyOTP();
    }
  }, [otp]);

  const handleSendOTPAgain = async () => {
    handleOpens();
    try {
      const response = await fetch(
        `${API_BASE_URL}login_number/${phoneNumber}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      const data = await response.json();
      if (response.ok) {
        handleCloses();
        toast.success('OTP sent Again')
      } else {
        handleCloses();
        console.error("API request failed:", response.statusText);
        toast.error('OTP not Sent')
      }
    } catch (error) {
      handleCloses();
      console.error("An error occurred during the API request:", error.message);
      toast.error('Please try again later')
    }
  };

  const handleFocus = (index) => {
    // Move the cursor to the last digit if there's already a value entered
    if (index === 5 && otp[index] !== "") {
      inputRefs?.current[index]?.current?.focus();
    }
  };

  // AUTO OTP FILL
  useEffect(() => {
    if ("OTPCredential" in window) {
      const ac = new AbortController();
      navigator.credentials
        .get({
          otp: { transport: ["sms"] },
          signal: ac.signal,
        })
        .then((otpCredential) => {
          if (otpCredential && otpCredential.code) {
            const otpCode = otpCredential.code;
            const otpArray = otpCode.split("").slice(0, 6); // Take only the first 6 digits
            setOTP(otpArray);

            // Autofocus on the last input field
            inputRefs.current[5]?.current?.focus();
          }
        })
        .catch((err) => {
          console.error("Error in receiving OTP automatically:", err);
        });

      // Cleanup function to abort the request if the component is unmounted
      return () => {
        ac.abort();
      };
    }
  }, [inputRefs]);

  return (
    <div className="w-full h-full">
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
        onClick={handleCloses}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <p className="text-[16px] text-center mb-3 font-bold text-black-1/80">
        We sent you a code
      </p>
      <p className="text-[14px] text-black-1/80 font-semibold text-center my-2 mx-auto">
        Please enter it below to verify your phone number
      </p>
      <p className="text-16 text-[purple] my-2 mx-auto text-center">
        {phoneNumber}
      </p>
      {/* OTP input boxes */}
      <div className="flex-center my-4">
        {otp.map((digit, index) => (
          <input
            type="text"
            inputMode="numeric"
            maxlength="1"
            pattern="[0-9]*"
            oninput="this.value=this.value.replace(/[^0-9]/g,'');"
            key={index}
            value={digit}
            onChange={(e) => handleInputChange(index, e.target.value, e)}
            onKeyDown={(e) => handleKeyDown(index, e.target.value, e)}
            ref={inputRefs.current[index]}
            onFocus={() => handleFocus(index)}
            className={`w-[40px] h-[46.7px] bg-transparent rounded-[6px] border-2 text-black-1 text-center mx-[6px] text-18 outline-none input-transition ${
              digit ? " border-black-1" : "border-gray-400"
            }`}
            onPaste={handleOTPPaste}
            required
          />
        ))}
      </div>
      <div className="flex-center my-3 mb-0 ">
        <p className="text-[14px] font-bold text-black-1/80 mr-1">
          Don't receive the code yet?
        </p>
        <Link
          to="#"
          onClick={handleSendOTPAgain}
          className="text-[purple] text-[16px]"
        >
          send again
        </Link>
      </div>
      <button
        className={`w-full h-[45px] bg-[purple] text-white-1 rounded-md font-bold my-3 mx-auto border-none text-16 tracking-wider ${
          loading ? "bg-[purple]/80" : "bg-[purple]"
        }`}
        id="auto-login-btn"
        onClick={handleVerifyOTP}
      >
        Verify
      </button>
    </div>
  );
}

const mapStateToProps = (state) => ({
  user: state.user,
});

const mapDispatchToProps = {
  setLoginMode,
  setOTPVerified,
  toggleOverlay,
  setToken,
  setPhoneNumbers,
  setSaveData,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AutoOTPVerification);
