import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import cyclecircleLogo from "../../img/parts/Cycle-Circle-Logo-White-1.png";
import { connect, useDispatch, useSelector } from "react-redux";
import { selectUser } from "../../features/auth/authSlice";
import {
  fetchAllCartsAsync,
  selectAllCarts,
} from "../../features/cartPage/cartPageSlice";
import cartOutline from "../../img/navigation/header-cart-outline.png";
import SearchSharpIcon from "@mui/icons-material/SearchSharp";
import userOutline from "../../img/navigation/header-user-outline.png";
import MobileBigSearch from "./MobileBigSearch";
import MobileSideBar from "./MobileSideBar";
import {
  selectIsOverlayVisible,
  toggleOverlay,
} from "../../store/slices/headerSlice";
import { IMAGE_BASE } from "../../config";
import { logActionClickedFromComponent } from "../../analytics";

function MobileHeader({ isOverlayVisible, toggleOverlay }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userDetail = useSelector(selectUser);
  const carts = useSelector(selectAllCarts);
  const [showSearchInput, setShowSearchInput] = useState(false);
  const [bigSearch, setBigSearch] = useState(false);
  const [openBurger, setOpenBurger] = useState(false);
  const inputRef = useRef(null);

  useEffect(() => {
    if (showSearchInput) {
      inputRef.current.focus();
    }
  }, [showSearchInput]);

  useEffect(() => {
    if (userDetail?.UserID) {
      dispatch(fetchAllCartsAsync(userDetail?.UserID));
    }
  }, [dispatch, userDetail?.UserID]);

  const handleCartNavigate = () => {
    navigate("/carts");
  };

  const words = ["“Frameset”", "“Cockpit”", "“Light”", "“Hydration”"];

  const [currentWordIndex, setCurrentWordIndex] = useState(0);
  const [currentWord, setCurrentWord] = useState("");
  const [forward, setForward] = useState(true);

  useEffect(() => {
    let animationTimeout;

    const animate = () => {
      if (forward) {
        animationTimeout = setTimeout(() => {
          setCurrentWord((prevWord) => {
            if (prevWord.length < words[currentWordIndex].length) {
              return prevWord + words[currentWordIndex][prevWord.length];
            } else if (prevWord === words[currentWordIndex]) {
              setTimeout(() => {
                setForward(false);
                setCurrentWordIndex((currentWordIndex + 1) % words.length);
              }, 3000);
              return prevWord;
            } else {
              return prevWord;
            }
          });
        }, 50);
      } else {
        animationTimeout = setTimeout(() => {
          setCurrentWord((prevWord) =>
            prevWord.substring(0, prevWord.length - 1)
          );
          if (currentWord === "") {
            setForward(true);
          } else {
            animate();
          }
        }, 50);
      }
    };

    animate();

    return () => clearTimeout(animationTimeout);
  }, [currentWord, currentWordIndex, forward]);

  function handleMouseOver(event) {
    const element = event.target;
    element.style.transform = "scale(1.1)";
    element.style.transition = "transform 0.3s ease";
  }

  function handleMouseOut(event) {
    const element = event.target;
    element.style.transform = "scale(1)";
  }

  return (
    <div
      className="w-full h-[58px] flex items-center justify-between py-[8px] px-[15px] bg-blue"
      style={{ boxShadow: "0 5px 5px 0 rgba(0,0,0,0.4)" }}
    >
      <div className="w-auto">
        <Link to="/" className="w-auto">
          <img
            loading="lazy"
            src={cyclecircleLogo}
            alt="website-logo"
            className="h-[40px]"
          />
        </Link>
      </div>
      <div className="w-[40%] flex items-center justify-between">
        <SearchSharpIcon
          style={{ fontSize: "30px", color: "rgba(255,255,255,0.9" }}
          onClick={() => setBigSearch(true)}
        />
        {bigSearch && (
          <MobileBigSearch setBigSearch={setBigSearch} bigSearch={bigSearch} />
        )}
        {/* {showNavigation && <MobileBigNavigation setShowNavigation={setShowNavigation}/>} */}
        <div className="flex items-center justify-start relative">
          <img
            loading="lazy"
            src={cartOutline}
            alt="cart-icon"
            className="h-[24px]"
            onClick={() =>
              Object?.keys(userDetail)?.length
                ? handleCartNavigate()
                : toggleOverlay()
            }
          />
          {carts && carts?.length > 0 && (
            <sup className="flex-center min-w-[20px] w-auto h-[20px] rounded-[50%] bg-[goldenrod] -top-[12px] right-[8px] z-1 text-12 font-bold text-center">
              {" "}
              {Object?.keys(userDetail)?.length > 0 && carts && carts?.length
                ? carts.length
                : "0"}
            </sup>
          )}
        </div>
        <div className="w-auto">
          {Object?.keys(userDetail)?.length ? (
            <>
              <img
                loading="lazy"
                src={`https://cyclecircle.blr1.cdn.digitaloceanspaces.com/${IMAGE_BASE}_USER_IMAGE/${
                  userDetail?.UserImage || ""
                }`}
                alt={userDetail?.UserImage || ""}
                className="w-[36px] h-[36px] rounded-[50px] bg-white-1 p-[2px]"
                onClick={() => setOpenBurger(true)}
              />
            </>
          ) : (
            <img
              loading="lazy"
              src={userOutline}
              alt=""
              className="w-[32px] h-[32px] rounded-[50px] text-white-1"
              onMouseOver={handleMouseOver}
              onMouseOut={handleMouseOut}
              onClick={() => {
                toggleOverlay();
                logActionClickedFromComponent(
                  "Join Us",
                  "Header",
                  userDetail?.UserID
                );
              }}
            />
          )}
        </div>
        {openBurger && (
          <MobileSideBar
            setOpenBurger={setOpenBurger}
            openBurger={openBurger}
          />
        )}
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  isOverlayVisible: selectIsOverlayVisible(state),
});
const mapDispatchToProps = {
  toggleOverlay,
};
export default connect(mapStateToProps, mapDispatchToProps)(MobileHeader);
