import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { getHomePageCollectionAsync } from "../../features/listingPage/listingPageSlice";
import { IMAGE_BASE } from "../../config";
import { ScrollMenu, VisibilityContext } from "react-horizontal-scrolling-menu";
import "react-horizontal-scrolling-menu/dist/styles.css";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { logActionClickedFromComponent, logProductView } from "../../analytics";
import { useSelector } from "react-redux";
import { selectUser } from "../../features/auth/authSlice";

const OneCollection = ({ title, collectionId, dispatch }) => {
  const [products, setProducts] = useState([]);
  const navigate = useNavigate();
  const userDetail = useSelector(selectUser);

  useEffect(() => {
    // Fetch products for the collection
    const handleFetchProducts = async () => {
      try {
        const fetchedProducts = await dispatch(
          getHomePageCollectionAsync(collectionId)
        );
        setProducts(fetchedProducts?.payload?.Data?.product);
      } catch (error) {
        console.error("Failed to fetch products", error);
      }
    };
    handleFetchProducts();
  }, [dispatch, collectionId]);

  const handleNavigate = (prod_id) => {
    navigate(`/product/${prod_id}`);
  };

  const handleFetchCollectionProduct = (coll_id, coll_name) => {
    navigate(`/listing-page/${"coll" + coll_id}/${coll_name}`);
  };

  // Custom left and right arrow buttons for the scrolling menu
  const LeftArrow = () => {
    const { scrollPrev } = React.useContext(VisibilityContext);
    return (
      <button
        onClick={() => scrollPrev()}
        className="w-10 h-10 absolute left-2 top-1/2 transform -translate-y-1/2 bg-white-1 shadow-md rounded-full z-10 hover:bg-gray-100"
      >
        <ArrowBackIosIcon
          fontSize="small"
          style={{ color: "black" }}
          className="ml-[6px]"
        />
      </button>
    );
  };

  const RightArrow = () => {
    const { scrollNext } = React.useContext(VisibilityContext);
    return (
      <button
        onClick={() => scrollNext()}
        className="w-10 h-10 absolute right-2 top-1/2 transform -translate-y-1/2 bg-white-1 shadow-md rounded-full p-2 z-10 hover:bg-gray-100"
      >
        <ArrowForwardIosIcon fontSize="small" style={{ color: "black" }} />
      </button>
    );
  };

  return (
    <div className="w-full px-4 rounded-md">
      {/* Header Section */}
      <div className="w-full flex items-center justify-between mb-6">
        <h2 className="text-gray-800 font-bold text-2xl sm:text-[12px] md:text-[14px] lg:text-3xl">
          Discover our best collection
        </h2>
        <span
          className="text-green-600 font-semibold text-sm cursor-pointer whitespace-nowrap flex items-center gap-1"
          onClick={() => {
            handleFetchCollectionProduct(collectionId, title);
            logActionClickedFromComponent(
              title,
              "Collection",
              userDetail?.UserID
            );
          }}
        >
          View All&nbsp; <ArrowForwardIcon style={{ fontSize: "15px" }} />
        </span>
      </div>

      {/* Product Collection */}
      <div className="w-full bg-white-1 flex flex-col p-3 rounded-md">
        <h3 className="text-black-1/80 font-medium text-base sm:text-sm md:text-base">
          {title}
        </h3>
        <div className="relative overflow-hidden">
          <ScrollMenu
            LeftArrow={LeftArrow}
            RightArrow={RightArrow}
            wrapperClassName="collection-scrollbar"
          >
            {products?.map((item) => (
              <div
                key={item?.prod_ID}
                className="w-48 flex-shrink-0 bg-white p-3 m-2 cursor-pointer sm:w-44 md:w-48 lg:w-56 xl:w-64"
                onClick={() => {
                  handleNavigate(item?.prod_ID);
                  logProductView(
                    item?.prod_ID,
                    item?.prod_name,
                    userDetail?.UserID
                  );
                }}
              >
                <div className="w-full h-32 bg-[#f3f3f3] rounded-md p-2">
                  <img
                    src={`https://cyclecircle.blr1.cdn.digitaloceanspaces.com/${IMAGE_BASE}_PRODUCTS_IMAGE/${
                      item?.images && item?.images[0]
                    }`}
                    alt={item.prod_name}
                    className="w-full h-full object-contain mix-blend-multiply"
                  />
                </div>

                <span className="text-green-600 font-semibold text-sm mt-2 block text-start">
                  {item.discount
                    ? `Min ${item.discount}% Discount`
                    : "Special Offer"}
                </span>
              </div>
            ))}
          </ScrollMenu>
        </div>
      </div>
    </div>
  );
};

export default OneCollection;
