import React, { useState } from "react";
import { Button, IconButton } from "@mui/material";
import { KeyboardArrowUp, KeyboardArrowDown, Close } from "@mui/icons-material";
import { FormattedNumber } from "../../utils/numberAnimation";
import RefreshIcon from "@mui/icons-material/Refresh";
import toast from "react-hot-toast";

const BrandCart = ({ brands }) => {
  const [expandedBrandIndex, setExpandedBrandIndex] = useState(null);
  const [currentQuantity, setCurrentQuantity] = useState(50);

  const toggleExpand = (index) => {
    setExpandedBrandIndex(expandedBrandIndex === index ? null : index);
  };

  const handleQuantityChange = (brandIndex, itemIndex, delta) => {
    const updatedBrands = [...brands];
    const item = updatedBrands[brandIndex].items[itemIndex];
    item.quantity = Math.max(1, item.quantity + delta);
    item.total = item.quantity * item.wholesalePrice;
    updatedBrands[brandIndex] = {
      ...updatedBrands[brandIndex],
      items: [...updatedBrands[brandIndex].items],
    };
  };

  // Handler to increase quantity in multiples
  const increaseQuantity = () => {
    if (
      currentQuantity + brands?.items?.quantity <=
      brands?.items?.Available_quantity
    ) {
      setCurrentQuantity(currentQuantity + brands?.items?.quantity);
    } else {
      toast.error(
        `Cannot exceed the maximum available quantity of ${brands?.items?.Available_quantity}`
      );
    }
  };

  // Handler to decrease quantity in multiples
  const decreaseQuantity = () => {
    if (currentQuantity - brands?.items?.quantity >= 0) {
      setCurrentQuantity(currentQuantity - brands?.items?.quantity);
    } else {
      toast.error(`Minimum quantity is ${brands?.items?.quantity}`);
    }
  };

  return (
    <div className="w-full h-auto space-y-6">
      {brands.map((brand, index) => (
        <div
          key={index}
          className="w-full border rounded-lg bg-gray-100 shadow-sm"
        >
          {/* Brand Header */}
          <div className="w-full flex justify-between items-center p-4">
            <div className="h-[35px] flex items-center">
              <img
                src={brand.logo}
                alt={`${brand.name} logo`}
                className="h-8 mb-2"
              />
              <div className="h-full flex flex-col items-start justify-between ml-5">
                <span className="text-[13px] tracking-wide text-black-1">
                  MOQ met
                </span>
                <div className="flex items-center">
                  <span className="relative h-2 w-2 mr-2 rounded-full bg-green before:absolute before:inset-0 before:rounded-full before:animate-pulse before:bg-green-400 before:opacity-50"></span>
                  <span className="text-[13px] text-green font-medium">
                    MOQ met for this order
                  </span>
                </div>
              </div>
              <div className="h-full flex flex-col items-start justify-between ml-5">
                <span className="text-[13px] tracking-wide text-black-1">
                  Qualified for Discount
                </span>
                <div className="flex items-center">
                  {/* Peach LED with glowing effect */}
                  <span className="relative h-2 w-2 mr-2 rounded-full bg-peach before:absolute before:inset-0 before:rounded-full before:animate-pulse before:bg-peach before:opacity-50"></span>

                  {/* Text */}
                  <span className="text-[13px] text-peach font-medium">
                    {brand.discountMessage}
                  </span>
                </div>
              </div>
            </div>
            <div className="flex gap-4">
              <Button
                variant="contained"
                color="primary"
                sx={{
                  height: "30px",
                  fontSize: "13px",
                  letterSpacing: "1px",
                  fontFamily: "inherit",
                  fontWeight: "600",
                  backgroundColor: "var(--color-blue)",
                  borderRadius: "25px",
                  textTransform: "none",
                  "&:hover": {
                    backgroundColor: "var(--color-blue-hover)",
                  },
                }}
              >
                Add More
              </Button>
              <Button
                variant="contained"
                color="success"
                sx={{
                  height: "30px",
                  fontSize: "13px",
                  letterSpacing: "1px",
                  fontFamily: "inherit",
                  fontWeight: "600",
                  backgroundColor: "var(--color-green)",
                  borderRadius: "25px",
                  textTransform: "none",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  "&:hover": {
                    backgroundColor: "var(--color-green-hover)",
                  },
                }}
                onClick={() => toggleExpand(index)}
              >
                {expandedBrandIndex === index ? "View Less" : "View More"}
                {expandedBrandIndex === index ? (
                  <KeyboardArrowUp sx={{ fontSize: "20px", color: "white" }} />
                ) : (
                  <KeyboardArrowDown
                    sx={{ fontSize: "20px", color: "white" }}
                  />
                )}
              </Button>
            </div>
          </div>

          {/* Cart Items */}
          {expandedBrandIndex === index ? (
            <div className="w-full bg-white-1 p-4">
              <div className="w-full flex flex-col gap-4">
                {brand.items.map((item, itemIndex) => (
                  <div
                    key={itemIndex}
                    className="w-full relative flex items-center justify-between bg-white-1 p-4 border rounded-lg shadow-md"
                  >
                    {/* Product Image */}
                    <img
                      src={item.image}
                      alt={item.name}
                      className="h-16 w-[10%] rounded-md object-cover"
                    />
                    {/* Product Details */}
                    <div className=" w-[20%] flex flex-col items-start justify-between">
                      <span className="text-[13px] font-bold text-gray-800 mb-2">
                        {item.name}
                      </span>
                      <div className="w-full flex flex-row items-center flex-wrap">
                        <span className="text-[10px] text-gray-600 border border-gray-600 rounded-full px-3 py-[3px] mr-1 mb-2">
                          Small
                        </span>
                        <span className="text-[10px] text-gray-600 border border-gray-600 rounded-full px-3 py-[3px] mr-1 mb-2">
                          Violet
                        </span>
                        <span className="text-[10px] text-gray-600 border border-gray-600 rounded-full px-3 py-[3px] mr-1 mb-2">
                          Diamond
                        </span>
                      </div>
                    </div>
                    <div className="w-[20%] flex items-center justify-center">
                      <Button
                        variant="contained"
                        size="small"
                        sx={{
                          width: "30px",
                          height: "30px",
                          minWidth: "unset",
                          fontSize: "25px",
                          display: "flex",
                          alignItems: "center",
                          borderRadius: "5px 0 0 5px",
                          justifyContent: "center",
                          backgroundColor:
                            currentQuantity > 0
                              ? "var(--color-peach)"
                              : "#e09eaa",
                          color: "#fff",
                          "&:hover": {
                            backgroundColor:
                              currentQuantity > 0 ? "#a83232" : "#e09eaa",
                          },
                          cursor:
                            currentQuantity > 0 ? "pointer" : "not-allowed",
                        }}
                        onClick={currentQuantity > 0 ? decreaseQuantity : null}
                        // disabled={currentQuantity <= 0}
                      >
                        -
                      </Button>

                      {/* Quantity Display */}
                      <div className="flex items-center bg-[#e3e3e3] h-[30px] shadow-md">
                        <span className="text-[18px] mx-[15px]">
                          {currentQuantity}
                        </span>
                      </div>

                      <Button
                        variant="contained"
                        size="small"
                        sx={{
                          width: "30px",
                          height: "30px",
                          minWidth: "unset",
                          fontSize: "25px",
                          borderRadius: "0 5px 5px 0",
                          backgroundColor:
                            currentQuantity < brands?.items?.Available_quantity
                              ? "var(--color-green)"
                              : "#a2deb1",
                          color: "#fff",
                          "&:hover": {
                            backgroundColor:
                              currentQuantity <
                              brands?.items?.Available_quantity
                                ? "darkgreen"
                                : "#a2deb1",
                          },
                          cursor:
                            currentQuantity < brands?.items?.Available_quantity
                              ? "pointer"
                              : "not-allowed",
                        }}
                        onClick={
                          currentQuantity < brands?.items?.Available_quantity
                            ? increaseQuantity
                            : null
                        }
                        // disabled={currentQuantity >= brands?.items?.Available_quantity}
                      >
                        +
                      </Button>

                      <IconButton
                        sx={{
                          color: "var(--color-peach)",
                          marginLeft: "0",

                          "&:hover": {
                            color: "#a83232",
                          },
                        }}
                        aria-label="Reset quantity"
                      >
                        <RefreshIcon style={{ fontSize: "15px" }} />
                      </IconButton>
                    </div>
                    <div className="w-[25%] flex flex-col items-start justify-center">
                      <span className="text-black-1 text-[13px]">
                        Wholesale Dealer Price-{" "}
                        <b className="text-green">
                          <FormattedNumber value={3360} />
                        </b>
                      </span>
                      <span className="text-gray-500 text-[12px]">
                        Product MRP- <FormattedNumber value={5850} />
                      </span>
                    </div>
                    <div className="w-[20%] flex items-center justify-center">
                      <span className="text-black-1 text-[13px]">
                        Total- <FormattedNumber value={6454} />
                      </span>
                    </div>
                    <div className="w-[5%] flex items-center justify-center">
                      <IconButton color="error">
                        <Close sx={{ fontSize: "18px" }} />
                      </IconButton>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          ) : (
            <div className="w-full bg-white-1 p-4">
              <h3 className="text-[12px] text-black-1 font-semibold mb-2">
                Items in your cart
              </h3>
              <div className="w-full flex items-center gap-4 p-4 overflow-x-auto scrollbar-thin scrollbar-thumb-gray-300">
                {brand.items.map((item, itemIndex) => (
                  <div
                    key={itemIndex}
                    className="relative flex items-center bg-white-1 p-4 border rounded-lg shadow-md w-80 min-w-[320px]"
                  >
                    {/* Quantity Badge */}
                    <span className="absolute -top-2 -left-2 border bg-white-1 border-green-600 text-green-600 text-xs px-2 py-1 rounded-full z-10">
                      5
                    </span>
                    {/* Product Image */}
                    <img
                      src={item.image}
                      alt={item.name}
                      className="h-16 w-16 rounded-md object-cover"
                    />
                    {/* Product Details */}
                    <div className="ml-4 flex flex-col justify-between w-[calc(100%-4rem)]">
                      <div className="text-sm font-medium text-gray-800 line-clamp-2">
                        {item.name}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          )}

          {/* Summary */}
          <div className="flex justify-between items-center p-4 text-black-1">
            <div>
              Order Quantity:{" "}
              <span className="font-bold">{brand.orderQuantity}</span>
            </div>
            <div>
              Order Value:{" "}
              <span className="font-bold">
                <FormattedNumber value={brand.orderValue} />
              </span>
            </div>
            <div>
              Taxes:{" "}
              <span className="font-bold">
                <FormattedNumber value={brand.taxes} />
              </span>
            </div>
            <div className="text-lg font-bold">
              Total Order Value:{" "}
              <FormattedNumber value={brand.totalOrderValue} />
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default BrandCart;
