import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
import {
  fetchThroughBrandAsync,
  setSubCategories,
} from "../../features/listingPage/listingPageSlice";
import { setSelectedSubcategory } from "../../store/slices/subCategorySlice";
import { setBreadcrumb } from "../../store/slices/breadCrumbsSlice";
import { useDispatch, useSelector } from "react-redux";
import garrariBikesLogo from "../../assets/garrari-bikes-icon.png";
import { selectUser } from "../../features/auth/authSlice";
import { logActionClickedFromComponent } from "../../analytics";

const ShopByBrand = () => {
  const userDetail = useSelector(selectUser);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const img = [
    "https://cyclecircle.blr1.cdn.digitaloceanspaces.com/Banners/1734263597821-Execlusive.jpg",
    "https://cyclecircle.blr1.cdn.digitaloceanspaces.com/Banners/Cratoni.png",
    "https://cyclecircle.blr1.cdn.digitaloceanspaces.com/Banners/Moon.png",
    "https://cyclecircle.blr1.cdn.digitaloceanspaces.com/Banners/Funn.png",
    "https://cyclecircle.blr1.cdn.digitaloceanspaces.com/Banners/Granite.png",
  ];

  const imgHidden = [
    "https://cyclecircle.blr1.cdn.digitaloceanspaces.com/Banners/1734263597821-Execlusive.jpg",
    "https://cyclecircle.blr1.cdn.digitaloceanspaces.com/Banners/Cratoni.png ",
    "https://cyclecircle.blr1.cdn.digitaloceanspaces.com/Banners/Moon.png",
    "https://cyclecircle.blr1.cdn.digitaloceanspaces.com/Banners/Funn.png",
    "https://cyclecircle.blr1.cdn.digitaloceanspaces.com/Banners/Granite.png",
  ];

  const [hoverBrand, setHoverBrand] = useState("garrari bikes");
  const [imgIdx, setImgIdx] = useState(0);

  const handleFetchThroughBrands = (brand) => {
    logActionClickedFromComponent(brand, "Shop By Brand", userDetail?.UserID);
    dispatch(fetchThroughBrandAsync(brand)).then(() => {
      dispatch(setSubCategories([]));
      dispatch(setSelectedSubcategory(null));
      dispatch(
        setBreadcrumb({
          categoryID: null,
          categoryName: brand,
          subCategoryID: null,
          subCategoryName: "",
          productName: "",
          ProductID: null,
        })
      );
      navigate(`/listing-page/${brand}`);
    });
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      const nextIdx = (imgIdx + 1) % img.length;
      setImgIdx(nextIdx);
      switch (nextIdx) {
        case 0:
          setHoverBrand("garrari bikes");
          break;
        case 1:
          setHoverBrand("cratoni");
          break;
        case 2:
          setHoverBrand("moon");
          break;
        case 3:
          setHoverBrand("funn");
          break;
        case 4:
          setHoverBrand("granite");
          break;
        default:
          break;
      }
    }, 10000);

    return () => clearTimeout(timer);
  }, [imgIdx, img.length]);

  const handleFetchThroughBrandsImg = (idx) => {
    switch (idx) {
      case 0:
        logActionClickedFromComponent(
          "garrari bikes",
          "Popular Search",
          userDetail?.UserID
        );
        navigate(`/listing-page/${"garrari bikes"}`);
        break;
      case 1:
        logActionClickedFromComponent(
          "cratoni",
          "Popular Search",
          userDetail?.UserID
        );
        navigate(`/listing-page/${"cratoni"}`);
        break;
      case 2:
        logActionClickedFromComponent(
          "moon sports",
          "Popular Search",
          userDetail?.UserID
        );
        navigate(`/listing-page/${"moon sports"}`);
        break;
      case 3:
        logActionClickedFromComponent(
          "funn",
          "Popular Search",
          userDetail?.UserID
        );
        navigate(`/listing-page/${"funn"}`);
        break;
      case 4:
        logActionClickedFromComponent(
          "granite",
          "Popular Search",
          userDetail?.UserID
        );
        navigate(`/listing-page/${"granite"}`);
        break;
      default:
        break;
    }
  };

  const handleHoverBrand = (brand, idx) => {
    setHoverBrand(brand);
    setImgIdx(idx);
  };

  return (
    <div className="w-full flex flex-col items-center justify-start bg-white border-b bg-white-1 border-gray-200">
      <span className="w-full pb-6 pt-6 pl-[20px] text-2xl text-start font-bold tracking-wider text-blue capitalize">
        Shop By Brands
      </span>
      <div className="w-full overflow-hidden bg-white mb-4">
        <img
          loading="lazy"
          src={
            Object.keys(userDetail).length > 0 ? img[imgIdx] : imgHidden[imgIdx]
          }
          alt="main-img"
          className="w-full h-[350px] lg:h-[400px]  cursor-pointer shadow-lg"
          onClick={() => handleFetchThroughBrandsImg(imgIdx)}
        />
      </div>
      <div className="w-full bg-white">
        <div className="flex items-center justify-between">
          <div
            className={`w-1/4 h-20 flex flex-col items-center justify-start relative cursor-pointer bg-white ${
              hoverBrand === "garrari bikes" && "z-1 shadow-md"
            }`}
            onMouseEnter={() => handleHoverBrand("garrari bikes", 0)}
            onClick={() => handleFetchThroughBrands("garrari bikes")}
          >
            <img
              loading="lazy"
              src={garrariBikesLogo}
              alt="garrari-logo"
              className={`w-auto md:w-auto h-[35px] md:h-[35px]  object-contain transition-transform duration-500 pt-1 ${
                hoverBrand === "garrari bikes" ? "scale-105" : ""
              }`}
            />
            <span className="absolute bottom-[30%] text-sm font-bold text-gray-900">
              Garrari Bikes
            </span>
            <span
              className={`absolute text-[10px] bottom-[8px] tracking-wide font-medium  flex items-center transition-colors duration-500 ${
                hoverBrand === "garrari bikes" ? "text-peach" : "text-gray-900"
              }`}
            >
              Upto{" "}
              {Object.keys(userDetail).length > 0 ? (
                "60"
              ) : (
                <VisibilityOffOutlinedIcon
                  className="mx-1"
                  style={{ fontSize: "14px" }}
                />
              )}
              % Dealer Margin
            </span>
            <hr
              className={`absolute bottom-0 left-0 w-0 border-2 transition-[width] ease-in duration-[10000ms] ${
                hoverBrand === "garrari bikes"
                  ? "w-full border-peach opacity-100"
                  : "w-0 border-transparent opacity-0"
              }`}
            />
          </div>
          <div
            className={`w-1/4 h-20 flex flex-col items-center justify-start relative cursor-pointer bg-white ${
              hoverBrand === "cratoni" && "z-1 shadow-md"
            }`}
            onMouseEnter={() => handleHoverBrand("cratoni", 1)}
            onClick={() => handleFetchThroughBrands("cratoni")}
          >
            <img
              loading="lazy"
              src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQohbUwx79PkQDbs4YO695jU0OXIAmfqRB-0jAxz05cag&s"
              alt="cratoni-logo"
              className={`w-1/2 md:w-[40%] h-[40px] md:h-[30px]  object-cover transition-transform duration-500 pt-1 ${
                hoverBrand === "cratoni" ? "scale-105" : ""
              }`}
            />
            <span className="absolute bottom-[30%] text-sm font-bold text-gray-900">
              Helmets
            </span>
            <span
              className={`absolute text-[10px] bottom-[8px] tracking-wide font-medium  flex items-center transition-colors duration-500 ${
                hoverBrand === "cratoni" ? "text-peach" : "text-gray-900"
              }`}
            >
              Upto{" "}
              {Object.keys(userDetail).length > 0 ? (
                "35"
              ) : (
                <VisibilityOffOutlinedIcon
                  className="mx-1"
                  style={{ fontSize: "14px" }}
                />
              )}
              % Dealer Margin
            </span>
            <hr
              className={`absolute bottom-0 left-0 w-0 border-2 transition-[width] ease-in duration-[10000ms] ${
                hoverBrand === "cratoni"
                  ? "w-full border-peach opacity-100"
                  : "w-0 border-transparent opacity-0"
              }`}
            />
          </div>
          <div
            className={`w-1/4 h-20 flex flex-col items-center justify-start relative cursor-pointer bg-white ${
              hoverBrand === "moon" && "z-1 shadow-md"
            }`}
            onMouseEnter={() => handleHoverBrand("moon", 2)}
            onClick={() => handleFetchThroughBrands("moon sports")}
          >
            <img
              loading="lazy"
              src="https://www.handshake.fi/wp-content/uploads/2018/06/logo-moon.png"
              alt="moon-logo"
              className={`w-1/2 md:w-[40%] h-[40px] md:h-[30px]  object-cover transition-transform duration-500 pt-1 ${
                hoverBrand === "moon" ? "scale-105" : ""
              }`}
            />
            <span className="absolute bottom-[30%] text-sm font-bold text-gray-900">
              Lights
            </span>
            <span
              className={`absolute text-[10px] bottom-[8px] tracking-wide font-medium  flex items-center transition-colors duration-500 ${
                hoverBrand === "moon" ? "text-peach" : "text-gray-900"
              }`}
            >
              Upto{" "}
              {Object.keys(userDetail).length > 0 ? (
                "35"
              ) : (
                <VisibilityOffOutlinedIcon
                  className="mx-1"
                  style={{ fontSize: "14px" }}
                />
              )}
              % Dealer Margin
            </span>
            <hr
              className={`absolute bottom-0 left-0 w-0 border-2 transition-[width] ease-in duration-[10000ms] ${
                hoverBrand === "moon"
                  ? "w-full border-peach opacity-100"
                  : "w-0 border-transparent opacity-0"
              }`}
            />
          </div>
          <div
            className={`w-1/4 h-20 flex flex-col items-center justify-start relative cursor-pointer bg-white ${
              hoverBrand === "funn" && "z-1 shadow-md"
            }`}
            onMouseEnter={() => handleHoverBrand("funn", 3)}
            onClick={() => handleFetchThroughBrands("funn")}
          >
            <img
              loading="lazy"
              src="https://images.bike24.com/i/mb/0c/67/20/logofunn-3903.jpg"
              alt="funn-logo"
              className={`w-1/2 md:w-[40%] h-[40px] md:h-[30px]  object-cover transition-transform duration-500 pt-1 ${
                hoverBrand === "funn" ? "scale-105" : ""
              }`}
            />
            <span className="absolute bottom-[30%] text-sm font-bold text-gray-900">
              Cockpit
            </span>
            <span
              className={`absolute text-[10px] bottom-[8px] tracking-wide font-medium  flex items-center transition-colors duration-500 ${
                hoverBrand === "funn" ? "text-peach" : "text-gray-900"
              }`}
            >
              Upto{" "}
              {Object.keys(userDetail).length > 0 ? (
                "35"
              ) : (
                <VisibilityOffOutlinedIcon
                  className="mx-1"
                  style={{ fontSize: "14px" }}
                />
              )}
              % Dealer Margin
            </span>
            <hr
              className={`absolute bottom-0 left-0 w-0 border-2 transition-[width] ease-in duration-[10000ms] ${
                hoverBrand === "funn"
                  ? "w-full border-peach opacity-100"
                  : "w-0 border-transparent opacity-0"
              }`}
            />
          </div>
          <div
            className={`w-1/4 h-20 flex flex-col items-center justify-start relative cursor-pointer bg-white ${
              hoverBrand === "granite" && "z-1 shadow-md"
            }`}
            onMouseEnter={() => handleHoverBrand("granite", 4)}
            onClick={() => handleFetchThroughBrands("granite")}
          >
            <img
              loading="lazy"
              src="https://www.stifmtb.com/cdn/shop/collections/Granite_logo_WH_1200x1200_13e278a3-8114-419c-94c0-0f6c6a0a86cc_1200x1200.jpg?v=1646754378"
              alt="granite-logo"
              className={`w-1/2 md:w-[40%] h-[40px] md:h-[30px]  object-cover transition-transform duration-500 pt-1 ${
                hoverBrand === "granite" ? "scale-105" : ""
              }`}
            />
            <span className="absolute bottom-[30%] text-sm font-bold text-gray-900">
              Tools
            </span>
            <span
              className={`absolute text-[10px] bottom-[8px] tracking-wide font-medium  flex items-center transition-colors duration-500 ${
                hoverBrand === "granite" ? "text-peach" : "text-gray-900"
              }`}
            >
              Upto{" "}
              {Object.keys(userDetail).length > 0 ? (
                "35"
              ) : (
                <VisibilityOffOutlinedIcon
                  className="mx-1"
                  style={{ fontSize: "14px" }}
                />
              )}
              % Dealer Margin
            </span>
            <hr
              className={`absolute bottom-0 left-0 w-0 border-2 transition-[width] ease-in duration-[10000ms] ${
                hoverBrand === "granite"
                  ? "w-full border-peach opacity-100"
                  : "w-0 border-transparent opacity-0"
              }`}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ShopByBrand;
