import React, { useState, useEffect, useRef } from "react";
import "./BrandSelect.css";
import SearchSharpIcon from "@mui/icons-material/SearchSharp";
import { useSelector } from "react-redux";
import { selectAllBrakeTypes } from "../../../../features/listingPage/listingPageSlice";
import { selectUser } from "../../../../features/auth/authSlice";
import { logActionClickedFromComponent } from "../../../../analytics";

function BrakeTypeSelect({ onSelect, closeBrakeTypeSelect, previouslySelectedBrakeType }) {
  const brakeTypes = useSelector(selectAllBrakeTypes);
  console.log(brakeTypes)
  const [selectedBrakeTypes, setSelectedBrakeTypes] = useState(
    previouslySelectedBrakeType || []
  );
  const [searchQuery, setSearchQuery] = useState("");
  const userDetail = useSelector(selectUser)

  const handleCheckboxChange = (brakeType) => {
    setSelectedBrakeTypes((prevselectedBrakeTypes) =>
      prevselectedBrakeTypes.includes(brakeType)
        ? prevselectedBrakeTypes.filter((b) => b !== brakeType)
        : [...prevselectedBrakeTypes, brakeType]
    );
  };

  const handleMobileCheckboxChange = (brakeType) => {
    setSelectedBrakeTypes((prevselectedBrakeTypes) => {
      const updatedBrakeTypes = prevselectedBrakeTypes.includes(brakeType)
        ? prevselectedBrakeTypes.filter((b) => b !== brakeType)
        : [...prevselectedBrakeTypes, brakeType];
      onSelect({ brakeTypes: updatedBrakeTypes });
      return updatedBrakeTypes;
    });
  };

  const handleApplyClick = () => {
    logActionClickedFromComponent(`${selectAllBrakeTypes}`,"Brake Types Filter",userDetail?.UserID)
    onSelect({ brakeTypes: selectedBrakeTypes });
  };

  const handleSearchInputChange = (event) => {
    setSearchQuery(event.target.value);
  };

  // Create a ref to the component
  const brakeTypeSelectRef = useRef(null);

  useEffect(() => {
    // Add event listener when the component mounts
    document.addEventListener("click", handleClickOutside);

    // Remove event listener when the component unmounts
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []); // Empty dependency array ensures this effect runs only once

  const handleClickOutside = (event) => {
    if (
      brakeTypeSelectRef.current &&
      !brakeTypeSelectRef.current.contains(event.target)
    ) {
      // Click occurred outside of the BrandSelect component
      closeBrakeTypeSelect();
    }
  };

  const filteredBrakeTypes = brakeTypes?.filter((data) =>
    data.toLowerCase().includes(searchQuery.toLowerCase())
  );

  return (
    <div ref={brakeTypeSelectRef} className="brand-select-container">
      <div className="brand-select-btn-container">
        <span>{selectedBrakeTypes.length} selected</span>
        <div className="brand-select-btn-contain">
          <button className="brand-select-btn-apply" onClick={handleApplyClick}>
            Apply
          </button>
          <button
            className="brand-select-btn-clear"
            onClick={() => setSelectedBrakeTypes([])}
          >
            Clear
          </button>
        </div>
      </div>
      <div className="brand-select-search-container">
        <input
          type="text"
          placeholder="Search..."
          value={searchQuery}
          onChange={handleSearchInputChange}
        />
        <SearchSharpIcon className="brand-select-search-icon" />
      </div>
      <div className="brand-select-option-container">
        {filteredBrakeTypes.map((data, index) => (
          <div
            className={`brand-name-checkbox${
              selectedBrakeTypes.includes(data) ? "-selected" : ""
            }`}
            key={index}
          >
            <input
              type="checkbox"
              checked={selectedBrakeTypes.includes(data)}
              onChange={() => handleCheckboxChange(data)}
              // onClick={handleApplyClick}
            />
            <span>{data}</span>
          </div>
        ))}
      </div>
      <div className="mobile-brand-select-option-container">
        {filteredBrakeTypes.map((data, index) => (
          <div
            className={`brand-name-checkbox${
              selectedBrakeTypes.includes(data) ? "-selected" : ""
            }`}
            key={index}
          >
            <input
              type="checkbox"
              checked={selectedBrakeTypes.includes(data)}
              onChange={() => handleMobileCheckboxChange(data)}
              // onClick={handleApplyClick}
            />
            <span className="brand-name-span-d">{data}</span>
          </div>
        ))}
      </div>
    </div>
  );
}

export default BrakeTypeSelect;
