import React, { useState } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import WantDeletePreorder from "../../components/Carts/WantDeletePreorder";
import {
  calculateDealerPrice,
  calculateOfferPrice,
  calculateUpdatedDiscountPercentage,
  formatWithCommasWithDecimal,
} from "../../utils/utils";
import LocalOfferOutlinedIcon from "@mui/icons-material/LocalOfferOutlined";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import { IMAGE_BASE } from "../../config";
import NoCartItem from "../../components/Carts/NoCartItem";

const PreorderCard = ({ preorderCart }) => {
  const [productToRemove, setProductToRemove] = useState(null);

  // Function to handle setting the product to be removed
  const handleSetProductToRemove = (skuId) => {
    setProductToRemove(skuId);
  };
  return (
    <>
      {preorderCart && preorderCart?.length > 0 ? (
        <div className="w-full flex flex-col bg-white shadow-md rounded-md p-6 bg-white-1">
          <div className="flex justify-between text-gray-700 font-semibold mb-4">
            <span className="w-1/4">Product</span>
            <span className="w-1/4 text-center">Qty</span>
            <span className="w-1/4 text-center">Price per unit</span>
            <span className="w-1/4 text-right">
              Total Price <b>(Incl. GST)</b>
            </span>
          </div>
          <div className="space-y-4">
            {preorderCart?.map((data, index) => (
              <div
                className="w-full h-[150px] flex items-center justify-between bg-gray-100 p-4 rounded-md shadow-sm"
                key={index}
              >
                {productToRemove === data.id ? (
                  <WantDeletePreorder
                    productData={data}
                    setProductToRemove={setProductToRemove}
                  />
                ) : (
                  <>
                    <div className="h-full flex items-center space-x-4 w-1/4">
                      <div className="w-16 h-20 rounded-md bg-white-1 overflow-hidden">
                        <LazyLoadImage
                          src={`https://cyclecircle.blr1.cdn.digitaloceanspaces.com/${IMAGE_BASE}_PRODUCTS_IMAGE/${
                            data?.image && data.image
                          }`}
                          alt="product-img"
                          effect="blur"
                          className="w-16 h-20 object-contain p-2"
                        />
                      </div>
                      <div className="flex flex-col">
                        {data?.manufacture_images ? (
                          <LazyLoadImage
                            effect="blur"
                            className="w-auto h-5 mb-1"
                            src={`https://cyclecircle.blr1.cdn.digitaloceanspaces.com/${IMAGE_BASE}_MANUFACTURE_IMAGE/${data?.manufacture_images}`}
                            alt="brand-img"
                          />
                        ) : (
                          <h3 className="text-sm text-peach italic">
                            {data?.manufacture_name}
                          </h3>
                        )}
                        <span className="text-sm text-black-1/80 ">
                          {data?.prod_name?.slice(0, 40)}
                        </span>
                        <div className="flex space-x-2 mt-2">
                          {data?.Size_Name && (
                            <span className="text-xs bg-blue/70 px-2 py-1 rounded">
                              {data?.Size_Name}
                            </span>
                          )}
                          {data?.Colour_Name && (
                            <span className="text-xs bg-blue/70 px-2 py-1 rounded">
                              {data?.Colour_Name}
                            </span>
                          )}
                          {data?.Varient_Name && (
                            <span className="text-xs bg-blue/70 px-2 py-1 rounded">
                              {data?.Varient_Name}
                            </span>
                          )}
                        </div>
                      </div>
                    </div>

                    {/* Quantity */}
                    <div className="text-center w-1/4">
                      <span className="text-lg text-gray-700 text-center">
                        {data?.quantity}
                      </span>
                    </div>

                    {/* Pricing */}
                    <div className="w-1/4 text-center">
                      <div className="text-[14px] text-black-1/80">
                        Dealer Price-&nbsp;
                        {data?.NDP_price && (
                          <span className="text-xs text-black-1/90">
                            ₹
                            {formatWithCommasWithDecimal(
                              parseInt(data?.NDP_price)
                            )}
                          </span>
                        )}
                        {data?.Offer_price && (
                          <span className="text-xs text-green-500 font-semibold">
                            ₹
                            {formatWithCommasWithDecimal(
                              parseInt(data?.Offer_price)
                            )}
                          </span>
                        )}
                      </div>
                      {data?.MRP_price && (
                        <span className="block text-xs text-black-1/60">
                          MRP: ₹
                          {formatWithCommasWithDecimal(
                            parseInt(data?.MRP_price)
                          )}
                        </span>
                      )}
                    </div>

                    {/* Total Price */}
                    <div className="w-1/4 flex items-center text-right">
                      <div className="w-full flex flex-col items-end">
                        <div className="text-lg font-semibold text-gray-800">
                          ₹
                          {formatWithCommasWithDecimal(
                            (data?.Offer_price
                              ? calculateOfferPrice(
                                  parseInt(data?.MRP_price),
                                  parseInt(data?.Offer_price),
                                  data?.GST
                                )
                              : calculateDealerPrice(
                                  parseInt(data?.MRP_price),
                                  parseInt(data?.NDP_price),
                                  data?.GST
                                )) * data?.quantity
                          )}
                        </div>
                        {calculateUpdatedDiscountPercentage(
                          data?.MRP_price,
                          data?.NDP_price,
                          data?.GST
                        ) > 0 && (
                          <div className="flex items-center text-xs text-green font-sans">
                            <LocalOfferOutlinedIcon className="mr-1 text-green" style={{fontSize:"14px"}} />
                            <span>
                              {calculateUpdatedDiscountPercentage(
                                data?.MRP_price,
                                data?.NDP_price,
                                data?.GST
                              )}
                              % Net Dealer Margin
                            </span>
                          </div>
                        )}
                      </div>
                      <div className="ml-4">
                        <CloseOutlinedIcon
                          className="text-gray-500 cursor-pointer hover:text-red-500"
                          style={{fontSize:"20px"}}
                          onClick={() => handleSetProductToRemove(data?.id)}
                        />
                      </div>
                    </div>
                  </>
                )}
              </div>
            ))}
          </div>
        </div>
      ) : (
        <NoCartItem text={"Preorder"} />
      )}
    </>
  );
};

export default PreorderCard;
