import React, { useEffect, useState } from "react";
import "./ListingPages.css";
import Breadcumb from "../../ProductDescription/Breadcumb";
import SubCategories from "./SubCategories";
import SideFilterComponent from "../Filter/SideFilterComponent";
import Product from "./Product";
import { useNavigate, useParams } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchAllCategoryProductsAsync,
  fetchAllSubCategoriesAsync,
  fetchAllSubCategoryProductsAsync,
  fetchThroughBrandAsync,
  getCollectionAsync,
  selectAllCategoryProducts,
  selectAllSubCategories,
  setSubCategories,
} from "../../../features/listingPage/listingPageSlice";
import {
  setSelectedSubcategory,
  selectedSelectedSubcategory,
} from "../../../store/slices/subCategorySlice";
import { IMAGE_BASE } from "../../../config";
import ListingHeader from "../Filter/FilterHeader/ListingHeader";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { setBreadcrumb } from "../../../store/slices/breadCrumbsSlice";

function ListingPage() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { CategoryID, categoryName } = useParams();
  const subCategories = useSelector(selectAllSubCategories);
  const selectedSubcategory = useSelector(selectedSelectedSubcategory);
  const [open, setOpen] = React.useState(false);
  const handleClose = () => {
    setOpen(false);
  };
  const handleOpen = () => {
    setOpen(true);
  };
  const searchCnt = useSelector(selectAllCategoryProducts);
  const searchedContent = searchCnt.Data;
  const [cat, setCat] = useState([
    (searchedContent && searchedContent?.Categories) || [],
  ]);
  const [subCat, setSubCat] = useState([
    (searchedContent && searchedContent?.SubCategories) || [],
  ]);
  const brandname = useParams(); // Fetch brand name from URL
  // Convert Category name to Uppercase
  const formattedName = (name) => {
    return name;
  };

  useEffect(() => {
    setCat((searchedContent && searchedContent?.Categories) || []);
    setSubCat((searchedContent && searchedContent?.SubCategories) || []);
  }, [searchedContent]);

  useEffect(() => {
    const fetchData = async () => {
      if (brandname?.brand) {
        handleOpen();
        await dispatch(fetchThroughBrandAsync(brandname.brand));
        await dispatch(setSubCategories([]));
        await dispatch(setSelectedSubcategory(null));
        await dispatch(
          setBreadcrumb({
            categoryID: null,
            categoryName: brandname.brand,
            subCategoryID: null,
            subCategoryName: "",
            productName: "",
            ProductID: null,
          })
        );
      }else if(CategoryID?.startsWith("coll")){
        handleOpen();
        const numericCategoryId = parseInt(CategoryID.replace("coll", ""), 10);
        await dispatch(getCollectionAsync(numericCategoryId));
      } else if (
        selectedSubcategory ||
        (subCategories && subCategories?.length > 0)
      ) {
        handleOpen();
        const isSubCategorySelected =
          subCategories &&
          subCategories?.some((item) => item.sc_id === selectedSubcategory);

        if (isSubCategorySelected) {
          await dispatch(fetchAllSubCategoryProductsAsync(selectedSubcategory));
        } else {
          await dispatch(fetchAllCategoryProductsAsync(CategoryID));
        }
      }
       else {
        handleOpen();
        await dispatch(fetchAllCategoryProductsAsync(CategoryID));
      }
      handleClose();
    };
    fetchData();
    return () => clearTimeout(2000);
  }, [
    dispatch,
    CategoryID,
    categoryName,
    brandname.brand,
    selectedSubcategory,
    // subCategories,
  ]);

  const [currentPage, setCurrentPage] = useState(1); // SETUP FOR PAGINATION

  return (
    <div className="listingpages-container">
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
        onClick={handleClose}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <div className="listingpages-breadcrumb-container">
        <Breadcumb />
      </div>
      <div className="listingpages-cat-heading-container">
        <span className="listingpages-cat-heading-span">
          {categoryName ? (
            formattedName(categoryName)
          ) : brandname.brand ? null : ( // formattedName(brandname.brand)
            <div className="listingpages-search-container">
              <span className="search-result-for">
                Search result for <b>{formattedName(brandname.text)}:</b>
              </span>
            </div>
          )}
        </span>
      </div>
      <div className="listingpages-sub-categories-container">
        <SubCategories
          categoryName={categoryName}
          CategoryID={CategoryID}
          setCurrentPage={setCurrentPage}
        />
      </div>
      <div className="products-headers-filter-container">
        <ListingHeader categoryName={categoryName}/>
      </div>
      <div className="listingpages-filter-and-product-container">
        <div className="listingpages-side-filter-container">
          <SideFilterComponent />
        </div>
        <div className="listingpages-product-container">
          <Product currentPage={currentPage} setCurrentPage={setCurrentPage} />
        </div>
      </div>
    </div>
  );
}

export default ListingPage;
