import React, { useEffect, useState } from "react";
import CloseSharpIcon from "@mui/icons-material/CloseSharp";
import { IMAGE_BASE } from "../../config";
import cyclecircleLogo from "../../img/parts/Cycle-Circle-Logo-White-1.png";
import { useNavigate, useParams } from "react-router";
import { connect, useDispatch, useSelector } from "react-redux";
import {
  fetchAllRecommendSearchAsync,
  fetchAllTopSearchAsync,
  selectAllRecommendSearch,
  selectAllTopSearch,
} from "../../features/search/searchSlice";
import {
  selectSearchTerm,
  setSearchTerm,
} from "../../store/slices/headerSlice";
import { setBreadcrumb } from "../../store/slices/breadCrumbsSlice";
import WhatshotOutlinedIcon from "@mui/icons-material/WhatshotOutlined";
import ScheduleOutlinedIcon from "@mui/icons-material/ScheduleOutlined";
import SearchSharpIcon from "@mui/icons-material/SearchSharp";
import { setSelectedSubcategory } from "../../store/slices/subCategorySlice";
import {
  fetchAllSubCategoriesAsync,
  fetchThroughSearchAsync,
  setSubCategories,
} from "../../features/listingPage/listingPageSlice";
import debounce from "lodash.debounce";

import { Slide } from "react-reveal";
import toast from "react-hot-toast";
import { logActionClickedFromComponent } from "../../analytics";
import { selectUser } from "../../features/auth/authSlice";

function MobileBigSearch({
  searchTerm,
  setSearchTerm,
  setBigSearch,
  bigSearch,
}) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userDetail = useSelector(selectUser);
  const recommendSearch = useSelector(selectAllRecommendSearch);
  const [recommendProduct, setRecommentProduct] = useState(
    (recommendSearch && recommendSearch?.product) || []
  );
  const [recommendCategories, setRecommentCategories] = useState(
    (recommendSearch && recommendSearch?.categories) || []
  );
  const [recommendSubCategories, setRecommentSubCategories] = useState(
    (recommendSearch && recommendSearch?.subcategories) || []
  );
  const [recommendManufacture, setRecommentManufacture] = useState(
    (recommendSearch && recommendSearch?.manufacture) || []
  );

  const [allAddTerms, setAllAddTerms] = useState([
    ...recommendCategories,
    ...recommendProduct,
    ...recommendSubCategories,
    ...recommendManufacture,
  ]);

  const [recentSearches, setRecentSearches] = useState([]);

  // Function to store recent searches in local storage
  const storeRecentSearches = (searches) => {
    const encryptedSearches = btoa(JSON.stringify(searches)); // Convert to non-readable form
    localStorage.setItem("recentSearches", encryptedSearches);
  };

  // Function to fetch recent searches from local storage
  const fetchRecentSearches = () => {
    const encryptedSearches = localStorage.getItem("recentSearches");
    if (encryptedSearches) {
      const decryptedSearches = JSON.parse(atob(encryptedSearches)); // Convert back to readable form
      setRecentSearches(decryptedSearches);
    }
  };

  useEffect(() => {
    fetchRecentSearches();
  }, []);

  const handleSearchDebounced = debounce(() => {
    if (searchTerm.trim().length > 0) {
      dispatch(fetchThroughSearchAsync(searchTerm.trim()));
      dispatch(setSubCategories(null));
      dispatch(setSelectedSubcategory(null));
      dispatch(
        setBreadcrumb({
          categoryID: 0,
          categoryName: "",
          subCategoryID: 0,
          subCategoryName: "",
          ProductID: 0,
          productName: "",
        })
      );
      navigate(`/search/${searchTerm}`);
      if (!recentSearches.includes(searchTerm)) {
        const updatedSearches = [searchTerm, ...recentSearches.slice(0, 4)]; // Limit to maximum of 4 items
        setRecentSearches(updatedSearches);
        storeRecentSearches(updatedSearches);
      }
      setSearchTerm("");
      setBigSearch(false);
    }
  }, 300); // Adjust the delay as needed

  const sTerm = useParams();
  useEffect(() => {
    if (sTerm.text && sTerm.text.trim().length > 0) {
      dispatch(fetchThroughSearchAsync(sTerm.text.trim()));
    }
  }, [dispatch, sTerm]);

  const handleSearch = () => {
    if (searchTerm.trim().length === 0) {
      // Show SweetAlert for empty search term
      toast.error("Empty Search Term");
      return; // Exit the function early
    }
    handleSearchDebounced();
    document.getElementById("searchInput").blur();
  };
  const handleKeyInput = (e) => {
    e.preventDefault();
    setSearchTerm(e.target.value);
    if (e.target.value === "") {
      dispatch(fetchAllRecommendSearchAsync("a"));
    } else {
      dispatch(fetchAllRecommendSearchAsync(e.target.value));
    }
  };
  useEffect(() => {
    dispatch(fetchAllRecommendSearchAsync("a"));
  }, [dispatch]);

  const handleSearchs = (searchTerm) => {
    dispatch(fetchThroughSearchAsync(searchTerm.trim()));
    dispatch(
      setBreadcrumb({
        categoryID: 0,
        categoryName: "",
        subCategoryID: 0,
        subCategoryName: "",
        ProductID: 0,
        productName: "",
      })
    );
    navigate(`/search/${searchTerm}`);
    setSearchTerm("");
    setBigSearch(false);
  };
  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      if (e.target.value.trim().length === 0) {
        // Show SweetAlert for empty search term
        toast.error("Empty Search Term");
        return; // Exit the function early
      }

      // Proceed with the search
      handleSearch();
      navigate(`/search/${e.target.value.trim()}`);
      e.target.value = "";
    }
  };

  useEffect(() => {
    if (recommendSearch) {
      setRecommentProduct(recommendSearch.product || []);
      setRecommentCategories(recommendSearch.categories || []);
      setRecommentSubCategories(recommendSearch.subcategories || []);
      setRecommentManufacture(recommendSearch.manufacture || []);

      setAllAddTerms([
        ...recommendCategories,
        ...recommendProduct,
        ...recommendSubCategories,
        ...recommendManufacture,
      ]);
    }
  }, [
    recommendSearch,
    recommendCategories,
    recommendManufacture,
    recommendProduct,
    recommendSubCategories,
  ]);

  const topSearch = useSelector(selectAllTopSearch);

  useEffect(() => {
    dispatch(fetchAllTopSearchAsync());
  }, [dispatch]);

  const handleTopSearch = (search) => {
    logActionClickedFromComponent(
      `${search}`,
      "Big Search",
      userDetail?.UserID
    );
    dispatch(fetchAllSubCategoriesAsync(search?.CategoryID)).then(() => {
      navigate(`/listing-page/${search?.CategoryID}/${search?.category_name}`);
      dispatch(setSelectedSubcategory(search?.sc_id));
      setBigSearch(false);
    });
  };

  const handleNavigate = (recommend) => {
    if (recommend?.type === "product") {
      logActionClickedFromComponent(
        `Product:${recommend?.name} From Big Search`,
        "Product View",
        userDetail?.UserID
      );
      navigate(`/product/${recommend?.id}`);
      setSearchTerm("");
      setBigSearch(false);
    } else if (recommend?.type === "category") {
      logActionClickedFromComponent(
        `${recommend?.name} From Big Search`,
        "Big Search",
        userDetail?.UserID
      );
      navigate(`/listing-page/${recommend?.id}/${recommend?.name}`);
      // dispatch(fetchAllCategoryProductsAsync(recommend.id));
      dispatch(setSelectedSubcategory(null));
      setSearchTerm("");
      setBigSearch(false);
    } else if (recommend?.type === "manufacturer") {
      logActionClickedFromComponent(
        `${recommend?.name} From Big Search`,
        "Big Search",
        userDetail?.UserID
      );
      navigate(`/listing-page/${recommend?.name}`);
      setSearchTerm("");
      setBigSearch(false);
    } else if (recommend?.type === "subcategory") {
      logActionClickedFromComponent(
        `${recommend?.category_name} From Big Search`,
        "Big Search",
        userDetail?.UserID
      );
      dispatch(fetchAllSubCategoriesAsync(recommend?.CategoryID)).then(() => {
        navigate(
          `/listing-page/${recommend?.CategoryID}/${recommend?.category_name}`
        );
        dispatch(setSelectedSubcategory(recommend?.id));
        // dispatch(fetchAllSubCategoryProductsAsync(recommend.id));
        setBigSearch(false);
      });
    }
  };

  const handleClearHistory = () => {
    logActionClickedFromComponent(
      `Clear History`,
      "Big Search",
      userDetail?.UserID
    );
    localStorage.removeItem("recentSearches");
    setRecentSearches([]);
  };

  const handleDeleteTerm = (termToDelete) => {
    const updatedSearches = recentSearches.filter(
      (term) => term !== termToDelete
    );
    storeRecentSearches(updatedSearches);
    setRecentSearches(updatedSearches);
  };

  const renderHighlightedText = (name, type) => {
    if (!searchTerm || !name) {
      return name?.length > 10 ? name.slice(0, 22) + "..." : name;
    }

    let truncatedName = name;
    if (name?.length > 10) {
      truncatedName = name.slice(0, 25) + "...";
    }

    const parts = truncatedName.split(new RegExp(`(${searchTerm})`, "gi"));
    return parts.map((part, index) => (
      <span
        key={index}
        style={
          part.toLowerCase() === searchTerm.toLowerCase()
            ? { color: "black" }
            : { color: "gray" }
        }
      >
        {part}
      </span>
    ));
  };

  // Function to determine image link based on term category
  const getImageLink = (term) => {
    if (term.type === "product") {
      return `https://cyclecircle.blr1.cdn.digitaloceanspaces.com/${IMAGE_BASE}_PRODUCTS_IMAGE/${term.image}`;
    } else if (term.type === "category") {
      return `https://cyclecircle.blr1.cdn.digitaloceanspaces.com/${IMAGE_BASE}_CATEGORY_IMAGE/${term.image}`;
    } else if (term.type === "subcategory") {
      return `https://cyclecircle.blr1.cdn.digitaloceanspaces.com/${IMAGE_BASE}_SUBCATEGORY_IMAGE/${term.image}`;
    } else if (term.type === "manufacturer") {
      return `https://cyclecircle.blr1.cdn.digitaloceanspaces.com/${IMAGE_BASE}_MANUFACTURE_IMAGE/${term.image}`;
    } else {
      return ""; // Default image link if category is not recognized
    }
  };

  const renderItemLimitedWithImage = (item, index) => {
    return (
      <div
        className="flex items-center justify-start my-[8px]"
        key={index}
        onClick={() => handleNavigate(item)}
      >
        <img
          loading="lazy"
          src={getImageLink(item)}
          alt="item-logo"
          className="w-[32px] h-[32px] rounded-md mr-3 p-[1px] border-[1px] border-gray-200"
        />
        <span className="text-14 text-black-1/80 font-semibold">
          {renderHighlightedText(item?.name, item?.type)}
        </span>
      </div>
    );
  };

  return (
    <Slide right>
      <div className="w-full h-screen fixed top-0 left-0 right-0 bottom-0 bg-white-1 flex flex-col items-center justify-start z-999">
        <div className="w-full flex items-center justify-between py-2 px-4 bg-blue">
          <img
            loading="lazy"
            src={cyclecircleLogo}
            alt="cc-logo"
            className="h-[40px]"
          />
          <CloseSharpIcon
            onClick={() => setBigSearch(false)}
            style={{ fontSize: "20px" }}
          />
        </div>
        <div
          className="w-[90vw] my-[15px] pt-1 pr-1 pb-1 pl-4 rounded-[25px] flex items-center justify-between"
          style={{ boxShadow: "0 0 8px 0 rgba(0, 0, 0, 0.15)" }}
        >
          <input
            id="searchInput"
            type="text"
            placeholder="Search over 3000+ products"
            className="w-[85%] h-[40px] text-[16px] text-black-1/80 tracking-wider outline-none border-none flex items-center placeholder:text-black-1/40 placeholder:text-[16px]"
            value={searchTerm}
            onChange={(e) => handleKeyInput(e)}
            onKeyPress={handleKeyPress}
            onClick={(e) => e.stopPropagation()}
          />
          <SearchSharpIcon
            className=" rounded-[50%] text-white-1 p-2 bg-green"
            style={{ fontSize: "40px" }}
            onClick={handleSearch}
          />
        </div>
        <div className="w-full min-h-[240px] flex flex-col items-start justify-start px-5">
          {allAddTerms &&
            allAddTerms
              .slice(0, 5)
              .map((term, index) => renderItemLimitedWithImage(term, index))}
        </div>
        {recommendManufacture.length > 0 && (
          <div className="w-full overflow-x-scroll flex items-center flex-wrap border-t-[1px] border-b-[1px] border-black-1/10 py-2">
            {recommendManufacture &&
              recommendManufacture.map((brand, index) => (
                <img
                  loading="lazy"
                  src={`https://cyclecircle.blr1.cdn.digitaloceanspaces.com/${IMAGE_BASE}_MANUFACTURE_IMAGE/${brand.image}`}
                  alt="brand-logo"
                  className="w-[25vw] px-3 object-cover "
                  onClick={() => handleNavigate(brand)}
                />
              ))}
          </div>
        )}
        <div className="w-full flex flex-col items-start justify-start px-4 py-3">
          <div className="flex items-center justify-start">
            <WhatshotOutlinedIcon
              className="mr-2 text-black-1/80"
              style={{ fontSize: "22px" }}
            />
            <span className="text-16 text-black-1/80 font-bold tracking-wider">
              Trending Searches
            </span>
          </div>
          <div className="w-full flex flex-row items-center justify-start flex-wrap mt-2">
            {topSearch &&
              topSearch.map((search, index) => (
                <span
                  className="text-12 text-green border-2 border-green rounded-md py-1 px-2 mx-2 my-1"
                  onClick={() => handleTopSearch(search)}
                >
                  {search?.SubcategorieName && search?.SubcategorieName}
                </span>
              ))}
          </div>
        </div>
        {recentSearches.length > 0 && (
          <div className="w-full flex flex-col items-start justify-start py-1 px-2">
            <div className="w-full flex items-center justify-between">
              <div className="flex items-center justify-start">
                <ScheduleOutlinedIcon
                  style={{ fontSize: "22px" }}
                  className="text-black-1/80 mr-2"
                />
                <span className="text-16 text-black-1/80 font-bold tracking-wider">
                  Recent Searches
                </span>
              </div>
              <span
                className="text-peach text-12 mr-3"
                onClick={handleClearHistory}
              >
                Clear All
              </span>
            </div>
            <div className="w-full flex flex-row items-center justify-start flex-wrap mt-2">
              {recentSearches &&
                recentSearches.map((term, index) => (
                  <div
                    className="flex-center py-1 px-2 bg-blue my-1 mx-2 rounded-md"
                    key={index}
                    onClick={() => handleSearchs(term)}
                  >
                    <span className="text-12 text-white-1 ml-2 tracking-wider">
                      {term}
                    </span>
                    <CloseSharpIcon
                      onClick={() => handleDeleteTerm(term)}
                      style={{ fontSize: "18px" }}
                      className="ml-1"
                    />
                  </div>
                ))}
            </div>
          </div>
        )}
      </div>
    </Slide>
  );
}

const mapStateToProps = (state) => ({
  searchTerm: selectSearchTerm(state),
});
const mapDispatchToProps = {
  setSearchTerm,
};
export default connect(mapStateToProps, mapDispatchToProps)(MobileBigSearch);
