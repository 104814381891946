import React, { useState, useRef, useEffect } from "react";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import { setSelectedAddress } from "../../features/cartPage/addressSlice";
import { useDispatch } from "react-redux";
import "react-lazy-load-image-component/src/effects/blur.css";
import { getStateName } from "../../utils/utils";
import { Button } from "@mui/material";
import AddressCard from "../../dComponent/Address/AddressCard";
import NewAddressForm from "../../dComponent/Address/NewAddressForm";
import { logActionClickedFromComponent } from "../../analytics";

function DefaultContact({
  addresses,
  userDetail,
  selectedAddress,
  handleOpen,
  handleClose,
}) {
  const dispatch = useDispatch();

  const [address, setAddress] = useState(false);
  const [addAddress, setAddAddress] = useState(false);
  const [openedAddressIndex, setOpenedAddressIndex] = useState(null);
  const [openClick, setOpenClick] = useState(false);
  const openClickRef = useRef(null);

  useEffect(() => {
    if (addresses && addresses.length > 0) {
      const defaultAddress = addresses.find((addr) => addr.is_default === 1);
      dispatch(
        setSelectedAddress(defaultAddress ? defaultAddress.shippingID : null)
      );
    }
  }, [dispatch, addresses]);

  const handleAddAddress = () => {
    logActionClickedFromComponent(
      `Open Add Address`,
      "Carts",
      userDetail?.UserID
    );
    setAddAddress(true);
  };

  const handleAddress = () => {
    setAddress(true);
    setAddAddress(true);
  };
  const handleOutsideClick = (event) => {
    // console.log(event.target,openClickRef);
    if (openClickRef.current && !openClickRef.current.contains(event.target)) {
      setOpenClick(false);
      if (openedAddressIndex !== null) {
        handleOpenThreeDots(null);
      }
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleOutsideClick);
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);

  const handleOpenThreeDots = (index) => {
    if (openedAddressIndex === index) {
      setOpenedAddressIndex(null); // Close the opened container if clicked again
    } else {
      setOpenedAddressIndex(index); // Open the clicked container
    }
  };

  return (
    <div className="w-full min-h-[500px] flex flex-col bg-white-1 sm:shadow-md rounded-t-[20px] sm:rounded-md">
      {(!addresses || addresses?.length <= 0) && !address ? (
        <div className="add-new-address-container" onClick={handleAddress}>
          <AddOutlinedIcon />
          <span>Add new address</span>
        </div>
      ) : (
        <div className="w-full flex flex-col">
          {addresses &&
            addresses?.length > 0 &&
            addresses
              ?.filter((address) => address?.shippingID === selectedAddress)
              ?.map((address) => (
                <div className="w-full bg-[#fdedf8] p-4 flex rounded-t-[20px] sm:rounded-none">
                  <span className="text-[14px] text-black-1/90 font-bold tracking-wide mr-2 sm:mr-0">
                    Deliver To:
                  </span>
                  <div className="flex flex-col ml-1">
                    <span className="text-[12px] sm:text-[13px] text-black-1/70 font-semibold tracking-wide">
                      {address.FirstName} {address.LastName},{" "}
                      {address.address_1} {address.address_2},
                    </span>
                    <span className="text-[12px] sm:text-[13px] text-black-1/70 font-semibold tracking-wide mt-[4px]">
                      {address.city} {address.district} {address.pin_code},
                      {getStateName(address.state_id)}
                      +91-{address.PhoneNumber}
                    </span>
                  </div>
                </div>
              ))}

          <div className="w-full p-4 flex items-center justify-between">
            <span className="text-[16px] text-black-1 font-bold tracking-wide">
              Saved Address{" "}
              <b className="bg-blue text-white-1 text-[12px] shadow-md rounded-sm ml-1 py-[2px] px-[6px] font-sans font-medium">
                {addresses && addresses.length}
              </b>
            </span>
            <Button
              variant="contained"
              sx={{
                height: "30px",
                background: "#283248",
                color: "white",
                padding: "5px 10px",
                borderRadius: "4px",
                textTransform: "none",
                fontSize: "12px",
                fontWeight: "600",
                display: "flex",
                alignItems: "center",
                letterSpacing: "0.5px",
                "&:hover": {
                  background: "#283248",
                },
              }}
              onClick={handleAddAddress}
            >
              <AddOutlinedIcon
                className="text-white-1 mr-1"
                style={{ fontSize: "18px" }}
              />
              Add Address
            </Button>
          </div>
          <div className="w-full flex flex-row justify-between flex-wrap p-4">
            {addresses &&
              addresses.length > 0 &&
              addresses?.map((address) => (
                <AddressCard
                  address={address}
                  selectedAddress={selectedAddress}
                  setSelectedAddress={setSelectedAddress}
                  handleOpen={handleOpen}
                  handleClose={handleClose}
                  userDetail={userDetail}
                />
              ))}
          </div>
        </div>
      )}
      {addAddress && (
        <NewAddressForm addAddress={addAddress} setAddAddress={setAddAddress} />
      )}
    </div>
  );
}

export default DefaultContact;
